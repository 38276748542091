import {
  mapQueryClassificationsGrouped,
  mapQueryDocumentList,
  mapQueryTopClassification,
  queryClassificationsGrouped,
  queryDocumentList,
  queryTopClassification,
  queryObjectsByDataSource,
  mapQueryObjectsByDataSource,
  queryClassificationsList,
  mapQueryClassificationsList,
  queryObjectById,
  mapQueryObjectById,
  queryClassificationsPrint,
  mapQueryClassificationsPrint,
  parseAnalyticsFile,
  queryFilesGroupedBuLabelSet,
  mapQueryFilesGroupedBuLabelSet,
  queryDocumentCount,
  mapQueryDocumentCount,
  queryDocumentListByBlob,
  mapQueryDocumentListByBlob,
  queryRescanDocuments,
  queryAccessUserByObjectId,
  queryAccessGroupByObjectId,
  mapQueryAccessUserByObjectId,
  mapQueryAccessGroupByObjectId
} from './queries'
import { AnalyticsItem } from './classificationsAnalytics'
import service, {
  CreateCustomClassification,
  PatchCustomClassificationParams
} from '../../services/api/apiService'
import {
  Document,
  AnnotationsWidgetData,
  WidgetAnnotationsQueryParams,
  DocumentApiQueryParams,
  DocumentClassCards,
  Classification
} from '../../services/api/apiTypes'
import { defaultSortParams, getSortDirection, SortParams } from '../../utils/sortUtil'
import { getDocumentListWithTimestamps } from '../../utils/documentUtil'
import {
  DownloadListParams,
  FilterParams,
  IGetDocumentsParams,
  ObjectsWidget
} from '../../interfaces'
import {
  CLASSICATION_TYPE,
  DATASOURCE_STATUS,
  DATA_SOURCE_ID,
  DATA_SOURCE_TYPE,
  DATA_SOURCE_TYPES,
  PAGE,
  SEVERITY_LEVEL,
  TEMPLATE_IDS
} from '../../constants'
import graphqlService from '../../services/graphqlService'
import { IGetAttributesParams, IGetAttributesTableListParams } from '../attributes/attributesSlice'
import { DocumentLabel } from '../documentLabels/documentLabelsSlice'
import { RootState } from '../../rootReducer'
import { getGlobalParams } from '../../utils/urlUtil'
import type { GetAccessControlUserByObjectIdFilterParams } from '../accessControl/types'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

interface DocumentsList {
  list?: Document[]
  total?: number
  sort: SortParams
}

export type ObjectsListSettings = {
  list?: Document[]
  total?: number
  sort: SortParams
}
export type FileListResponseType = {
  list?: ClassificationObject[]
  total?: number
  sort?: SortParams
}
export type ClassificationObject = {
  id: string
  name: string
  fileType: string
  skipReason?: string
  lastModifiedTime: string
  isDeleted?: boolean
  accessType?: string
  owner: string
  link: string
  metadataId?: string
  messageId?: string
  classification?: string
  subclass?: string
  datasourceId?: string
  datasourceName?: string
  datasourceType?: string
  retainUntilTimestamp?: string
  drive: {
    id: string
    name: string
  }[]
  buckets?: {
    name: string
    owner: string
  }[]
  entitiesCount: number
  entities: {
    id: string
    names: string[]
  }[]
  attributesCount: string
  attributes: {
    internalName: string
    name: string
  }[]
  alertsCount: number
  alerts: {
    id: string
    name: string
    severity: SEVERITY_LEVEL
  }[]
  reporter?: string
  assignee?: string
  ticketName?: string
  projectName?: string
  ticketLink?: string
  ticketLastModifiedTime?: string
  labels: DocumentLabel[]
  rowId?: string
}

export type ObjectAlertCompact = {
  list: { id: string; name: string; severity: SEVERITY_LEVEL }[]
  count: number
  isViolatingRetentionPolicy: boolean
}
export interface ObjectSummary {
  sharedWhen: string
  lastModifiedTime: string
  retainUntilTimestamp?: string | null
  owner?: string | null
  highSensitivityAttributes?: number
  mediumSensitivityAttributes?: number
  lowSensitivityAttributes?: number
  link: string
  accessType?: string
  incidentNumber?: string
  messageId?: string
  driveName?: string
  bucketName?: string
  alerts?: ObjectAlertCompact
  userAccess?: {
    count: number
  }
  groupAccess?: {
    count: number
  }
  entityCount?: number
  dataSourceStatus?: DATASOURCE_STATUS
  idpId?: string
}

export type AccessUsersByObjectId = {
  totalCount: number
  users: Array<{
    name: string
    id: string
    departmentName: string
    employeeType: string
    dataPrivilegeLevel: string
    memberOfGroups: Array<{
      name: string
      id: string
    }>
  }>
}

export type AccessGroup = {
  name: string
  id: string
  memberUsers: {
    count: number
  }
}

export type AccessGroupsByObjectId = {
  totalCount: number
  groups: AccessGroup[]
}

type IObjects = {
  all: ObjectsListSettings
  risky: ObjectsListSettings
  sensitive: ObjectsListSettings
}
export type ClassificationTopWidget = {
  documentClass: string
  attributeInstanceCount: number
}

export const fetchChart = createAsyncThunk(
  'classifications/chart',
  async (filters?: WidgetAnnotationsQueryParams) =>
    await service.getAnnotationsWidgetData(filters || {})
)

export const fetchDocuments = createAsyncThunk(
  'classifications/listAll',
  async (filters: IGetDocumentsParams) => {
    return await service.getDocuments({ ...filters })
  }
)

const ACTION_OBJECTS_BY_DATA_SOURCE = 'classifications/objectsByDataSource'
export type ObjectsByDataSourceParams = {
  filters?: {
    [TEMPLATE_IDS]?: string
  }
}
export const fetchObjectsByDataSource = createAsyncThunk(
  ACTION_OBJECTS_BY_DATA_SOURCE,
  async (params?: ObjectsByDataSourceParams) => {
    const raw = await graphqlService.execute(queryObjectsByDataSource(params))
    return mapQueryObjectsByDataSource(raw)
  }
)

export const fetchDocumentsClassified = createAsyncThunk(
  'classifications/listClassified',
  async (filters: IGetDocumentsParams) => {
    return await service.getDocuments({ ...filters, classificationType: 'classified' })
  }
)
/**Temporary fix of passing category:file filter to documents API https://lightbeamai.atlassian.net/browse/LA-3375 */
export const ACTION_ClASSIFICATION_DOCUMENTS_FETCH = 'classifications/documents'
export const fetchClassificationDocuments = createAsyncThunk(
  ACTION_ClASSIFICATION_DOCUMENTS_FETCH,
  async (filters: IGetDocumentsParams) => {
    return await service.getDocuments({ ...filters, category: 'file' })
  }
)

export const ACTION_DOCUMENTS_UNCLASSIFIED_FETCH = 'classifications/listUnClassified'
export const fetchDocumentsUnclassified = createAsyncThunk(
  ACTION_DOCUMENTS_UNCLASSIFIED_FETCH,
  async (filters: IGetDocumentsParams) => {
    return await service.getDocuments({
      ...filters,
      classificationType: 'unclassified'
    })
  }
)

export const ACTION_DOCUMENTS_BY_CLASS_FETCH = 'classifications/documentsByClasses'
export const fetchDocumentsByClasses = createAsyncThunk(
  ACTION_DOCUMENTS_BY_CLASS_FETCH,
  async (filters: DocumentApiQueryParams) => await service.getDocumentsByClasses(filters)
)

export const ACTION_DOCUMENTS_BY_ACCESS_FETCH = 'classifications/documentsByAccess'
export const fetchClassificationsGroupedByLabelSets = createAsyncThunk(
  ACTION_DOCUMENTS_BY_ACCESS_FETCH,
  async (datasourceId?: string) => {
    const resultRaw = await graphqlService.execute(queryFilesGroupedBuLabelSet(datasourceId))
    return mapQueryFilesGroupedBuLabelSet(resultRaw)
  }
)

export const fetchDocumentsUnClassifiedWithEntities = createAsyncThunk(
  'classifications/listUnclassifiedWithEntities',
  async (filters: IGetDocumentsParams) => {
    return await service.getDocuments({ ...filters, classificationType: 'unclassifiedWithEntity' })
  }
)
export const fetchDocumentsUnClassifiedWithoutEntities = createAsyncThunk(
  'classifications/listUnclassifiedWithoutEntities',
  async (filters: IGetDocumentsParams) => {
    return await service.getDocuments({
      ...filters,
      classificationType: 'unclassifiedWithoutEntity'
    })
  }
)
export const fetchObjectsWidgetData = createAsyncThunk(
  'classifications/objectsWidget',
  async (filters: IGetDocumentsParams): Promise<ObjectsWidget> => {
    const results = await Promise.all([
      service.getDocuments({ ...filters }),
      service.getDocuments({ isAtRisk: true, ...filters }),
      service.getDocuments({ isSensitive: true, ...filters })
    ]).catch((error) => {
      throw error
    })

    return {
      totalCount: results[0].total || 0,
      riskCount: results[1].total || 0,
      sensitiveCount: results[2].total || 0
    }
  }
)

export type ClassificationGroupedItem = {
  name: string
  count: number
  subClass: string[]
}

export type ClassificationsGroupedParams = {
  [DATA_SOURCE_ID]?: string
  datasourceType?: DATA_SOURCE_TYPES
  filters?: FilterParams
  fetchAlerts?: boolean
  retainUntil?: string
  documentLabelIds?: string[]
  lastModifiedTime?: string
}

export const ACTION_CLASSIFICATIONS_GROUPED = 'classifications/grouped'
export const fetchClassificationsGrouped = createAsyncThunk(
  ACTION_CLASSIFICATIONS_GROUPED,
  async (params: ClassificationsGroupedParams) => {
    const resultRaw = await graphqlService.execute(queryClassificationsGrouped(params))
    const list = mapQueryClassificationsGrouped(resultRaw)
    return { list, datasourceId: params.datasourceId || '' }
  }
)
export const ACTION_CLASSIFICATIONS_LIST = 'classifications/list'
export const fetchClassificationsList = createAsyncThunk(
  ACTION_CLASSIFICATIONS_LIST,
  async (params: IGetAttributesParams & IGetAttributesTableListParams, { getState }) => {
    const queryParams = { ...params, ...getGlobalParams(getState() as RootState) }
    const resultRaw = await graphqlService.execute(queryDocumentList(queryParams))
    return mapQueryDocumentList(resultRaw)
  }
)
export const ACTION_CLASSIFICATIONS_COUNT = 'classifications/count'
export const fetchClassificationsCount = createAsyncThunk(
  ACTION_CLASSIFICATIONS_COUNT,
  async (params: IGetAttributesParams & IGetAttributesTableListParams) => {
    const resultRaw = await graphqlService.execute(queryDocumentCount(params))
    return mapQueryDocumentCount(resultRaw)
  }
)
export const ACTION_BLOB_CLASSIFICATIONS_LIST = 'blob/classifications/list'
export const fetchClassificationsListByBlob = createAsyncThunk(
  ACTION_BLOB_CLASSIFICATIONS_LIST,
  async (params: IGetAttributesParams & IGetAttributesTableListParams) => {
    const resultRaw = await graphqlService.execute(queryDocumentListByBlob(params))
    return mapQueryDocumentListByBlob(resultRaw)
  }
)

export type ClassifiedObjectsParams = {
  [DATA_SOURCE_ID]?: string
  [PAGE]: number
  [DATA_SOURCE_TYPE]?: string
  filters?: FilterParams
}

export const ACTION_CLASSIFICATIONS_CLASSIFIED = 'classifications/classifiedList'
export const fetchClassifiedObjects = createAsyncThunk(
  ACTION_CLASSIFICATIONS_CLASSIFIED,
  async (params: IGetAttributesParams & IGetAttributesTableListParams) => {
    const resultRaw = await graphqlService.execute(queryClassificationsList(params))
    return mapQueryClassificationsList(resultRaw)
  }
)

export const ACTION_CLASSIFICATION_TOP_WIDGET_FETCH = 'classification/widget'
export const fetchTopClassificationWidgetData = createAsyncThunk(
  ACTION_CLASSIFICATION_TOP_WIDGET_FETCH,
  async (params: IGetAttributesParams) => {
    const resultRaw = await graphqlService.execute(queryTopClassification(params))
    return mapQueryTopClassification(resultRaw)
  }
)

export const ACTION_GET_DATA_ANALYTICS_BY_ID = 'getAnalyticDocData'
export const fetchDataAnalyticsByIdInfo = createAsyncThunk(
  ACTION_GET_DATA_ANALYTICS_BY_ID,
  async (dataSourceId: string) => await service.getDataAnalyticsById(dataSourceId)
)

export type ClassificationsPrint = {
  classification: CLASSICATION_TYPE
  objectsCount: number
  subclasses: string[]
}
export const ACTION_CLASSIFICATION_PRINT = 'classification/print'
export const fetchClassificationsPrint = createAsyncThunk(ACTION_CLASSIFICATION_PRINT, async () => {
  const resultRaw = await graphqlService.execute(queryClassificationsPrint())
  return mapQueryClassificationsPrint(resultRaw)
})

export type ClassificationsTopListByDataSource = {
  datasourceId: string
  list: ClassificationGroupedItem[]
}

export const ACTION_ANALYTICS_FILE_FETCH = 'classification/analytics'
export const fetchAnalyticsFile = createAsyncThunk(ACTION_ANALYTICS_FILE_FETCH, async () => {
  const response = await fetch('../../assets/classifications_analytics.json')
  const data = await response.json()
  const dataArr = Array.isArray(data) ? data : [data]
  return parseAnalyticsFile(dataArr)
})

export const ACTION_FETCH_FILES_TO_EXPORT = 'classification/filesToExport'
export const fetchFilesToExport = createAsyncThunk(
  ACTION_FETCH_FILES_TO_EXPORT,
  async (params: DownloadListParams): Promise<{ data: string; fileName: string }> => {
    const { data, headers } = await service.downloadFile(params)
    const disposition = headers['content-disposition']
    let fileName = ''
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRegex.exec(disposition)
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '')
      }
    }

    return { data, fileName }
  }
)

export const ACTION_SEND_FILES_EXPORT_REQUEST = 'classification/sendFilesExportRequest'
export const sendFilesExportRequest = createAsyncThunk(
  ACTION_SEND_FILES_EXPORT_REQUEST,
  async (params: DownloadListParams): Promise<{ data: string }> => {
    const { data } = await service.downloadFile(params)

    return { data }
  }
)

export const ACTION_CREATE_CUSTOM_CLASSIFICATION = 'classification/createCustomClassification'
export const createCustomClassification = createAsyncThunk(
  ACTION_CREATE_CUSTOM_CLASSIFICATION,
  async (params: CreateCustomClassification) => {
    await service.createCustomClassification(params)
  }
)

export const ACTION_PATCH_CUSTOM_CLASSIFICATION = 'classification/patchCustomClassification'
export const patchCustomClassification = createAsyncThunk(
  ACTION_CREATE_CUSTOM_CLASSIFICATION,
  async (params: PatchCustomClassificationParams) => {
    await service.patchCustomClassification(params)
  }
)

export const ACTION_FETCH_CLASSIFICATION = 'classification/categories/get'
export const fetchClassifications = createAsyncThunk(
  ACTION_FETCH_CLASSIFICATION,
  async () => await service.getClassificationsList()
)

interface ClassificationsState {
  chart: AnnotationsWidgetData | null
  classified: DocumentsList
  files: FileListResponseType
  unClassifiedWithEntities: DocumentsList
  unClassifiedWithoutEntities: DocumentsList
  objects: IObjects
  objectsWidget?: ObjectsWidget
  classifiedByClasses?: DocumentClassCards[]
  widgetGrouped: ClassificationsTopListByDataSource[]
  topClassifiedWidget?: ClassificationTopWidget[]
  objectSummary?: ObjectSummary
  accessUsers?: AccessUsersByObjectId
  accessGroups?: AccessGroupsByObjectId
  classificationsPrint?: ClassificationsPrint[]
  analyticsData?: AnalyticsItem[]
  filesToExport?: { data: string; fileName: string }
  docAnalyticData?: any
  classifications?: Classification[]
  refreshDocuments?: boolean
}

export const initialState: ClassificationsState = {
  chart: null,
  classified: { sort: defaultSortParams },
  files: { sort: defaultSortParams },
  unClassifiedWithEntities: { sort: defaultSortParams },
  unClassifiedWithoutEntities: { sort: defaultSortParams },
  objects: {
    all: { sort: defaultSortParams },
    risky: { sort: defaultSortParams },
    sensitive: { sort: defaultSortParams }
  },
  widgetGrouped: [],
  docAnalyticData: null,
  refreshDocuments: false
}
export const ACTION_FETCH_OBJECT_BY_ID = 'object/by-id'
export const fetchObjectSummaryById = createAsyncThunk(
  ACTION_FETCH_OBJECT_BY_ID,
  async (id: string) => {
    const resultRaw = await graphqlService.execute(queryObjectById(id))
    return mapQueryObjectById(resultRaw)
  }
)

export const ACTION_FETCH_ACCESS_USERS_BY_OBJECT_ID = 'access-users/by-object-id'
export const fetchAccessUsersByObjectId = createAsyncThunk(
  ACTION_FETCH_ACCESS_USERS_BY_OBJECT_ID,
  async (params: GetAccessControlUserByObjectIdFilterParams) => {
    const resultRaw = await graphqlService.execute(queryAccessUserByObjectId(params))

    return mapQueryAccessUserByObjectId(resultRaw)
  }
)

export const ACTION_FETCH_ACCESS_GROUPS_BY_OBJECT_ID = 'access-groups/by-object-id'
export const fetchAccessGroupsByObjectId = createAsyncThunk(
  ACTION_FETCH_ACCESS_GROUPS_BY_OBJECT_ID,
  async ({ id, pageSize, after }: { id: string; pageSize: number; after?: string }) => {
    const resultRaw = await graphqlService.execute(queryAccessGroupByObjectId(id, pageSize, after))

    return mapQueryAccessGroupByObjectId(resultRaw)
  }
)

export const ACTION_RESCAN_DOCUMENTS = 'documents/rescan'
export const rescanDocuments = createAsyncThunk(
  ACTION_RESCAN_DOCUMENTS,
  async (params: { datasourceId: string; documentIds: string[] }) => {
    await graphqlService.execute(queryRescanDocuments(params))
  }
)

const classificationsSlice = createSlice({
  name: 'classifications',
  initialState,
  reducers: {
    setSortForClassified: (state, action) => {
      state.classified.sort = getSortDirection(state.classified.sort, action.payload.column)
    },
    setSortForUnClassifiedWithEntities: (state, action) => {
      state.unClassifiedWithEntities.sort = getSortDirection(
        state.unClassifiedWithEntities.sort,
        action.payload.column
      )
    },
    setSortForUnClassifiedWithoutEntities: (state, action) => {
      state.unClassifiedWithoutEntities.sort = getSortDirection(
        state.unClassifiedWithoutEntities.sort,
        action.payload.column
      )
    },
    setSortForObjects: (state, { payload }) => {
      state[payload.list].sort = getSortDirection(state[payload.list].sort, payload.column)
    },
    resetChart: (state) => {
      state.chart = initialState.chart
    },
    resetObjects: (state) => {
      state.objects = initialState.objects
    },
    resetObjectsWidget: (state) => {
      state.objectsWidget = initialState.objectsWidget
    },
    resetGroupedWidget: (state) => {
      state.widgetGrouped = initialState.widgetGrouped
    },
    resetClassified: (state) => {
      state.classified = initialState.classified
      state.classifiedByClasses = initialState.classifiedByClasses
    },
    resetUnClassifiedWithEntities: (state) => {
      state.unClassifiedWithEntities = initialState.unClassifiedWithEntities
    },
    resetUnClassifiedWithoutEntities: (state) => {
      state.unClassifiedWithoutEntities = initialState.unClassifiedWithoutEntities
    },
    resetFilesToExport: (state) => {
      state.filesToExport = initialState.filesToExport
    },
    resetDocAnalyticData: (state) => {
      state.docAnalyticData = initialState.docAnalyticData
    },
    setRefreshDocuments: (state) => {
      state.refreshDocuments = true
    },
    resetRefreshDocuments: (state) => {
      state.refreshDocuments = initialState.refreshDocuments
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchChart.fulfilled, (state, action) => {
      state.chart = action.payload
    })
    builder.addCase(fetchChart.rejected, (state) => {
      state.chart = {}
    })
    builder.addCase(fetchClassificationDocuments.fulfilled, (state, action) => {
      state.classified.list = getDocumentListWithTimestamps(action.payload.list)
      state.classified.total = action.payload.total
    })
    builder.addCase(fetchDocumentsUnclassified.fulfilled, (state, action) => {
      state.classified.list = getDocumentListWithTimestamps(action.payload.list)
      state.classified.total = action.payload.total
    })
    builder.addCase(fetchDocumentsUnClassifiedWithEntities.fulfilled, (state, action) => {
      state.unClassifiedWithEntities.list = getDocumentListWithTimestamps(action.payload.list)
      state.unClassifiedWithEntities.total = action.payload.total
    })
    builder.addCase(fetchDocumentsUnClassifiedWithoutEntities.fulfilled, (state, action) => {
      state.unClassifiedWithoutEntities.list = getDocumentListWithTimestamps(action.payload.list)
      state.unClassifiedWithoutEntities.total = action.payload.total
    })
    builder.addCase(fetchClassificationsList.fulfilled, (state, action) => {
      state.files.list = action.payload.list
    })
    builder.addCase(fetchClassificationsCount.fulfilled, (state, action) => {
      state.files.total = action.payload.total
    })
    builder.addCase(fetchClassificationsListByBlob.fulfilled, (state, action) => {
      state.files.list = action.payload.list
      state.files.total = action.payload.total
    })
    builder.addCase(fetchClassifiedObjects.fulfilled, (state, action) => {
      state.files.list = action.payload.list
      state.files.total = action.payload.total
    })
    builder.addCase(fetchDocuments.fulfilled, (state, { payload }) => {
      /**Logic to prefetch all the filtered documents to have consistent & suitable counts in tabs */
      if (payload.risky) {
        state.objects.risky.list = getDocumentListWithTimestamps(payload.list)
        state.objects.risky.total = payload.total
      } else if (payload.sensitive) {
        state.objects.sensitive.list = getDocumentListWithTimestamps(payload.list)
        state.objects.sensitive.total = payload.total
      } else {
        state.objects.all.list = getDocumentListWithTimestamps(payload.list)
        state.objects.all.total = payload.total
      }
    })
    builder.addCase(fetchObjectsByDataSource.fulfilled, (state, { payload }) => {
      state.objects.all.list = payload
    })
    builder.addCase(fetchObjectsWidgetData.fulfilled, (state, { payload }) => {
      state.objectsWidget = payload
    })
    builder.addCase(fetchDocumentsByClasses.fulfilled, (state, { payload }) => {
      state.classifiedByClasses = payload
    })
    builder.addCase(fetchClassificationsGroupedByLabelSets.fulfilled, (state, { payload }) => {
      state.classifiedByClasses = payload
    })
    builder.addCase(fetchClassificationsGrouped.fulfilled, (state, { payload }) => {
      state.widgetGrouped.push(payload)
    })
    builder.addCase(fetchTopClassificationWidgetData.fulfilled, (state, { payload }) => {
      state.topClassifiedWidget = payload
    })
    builder.addCase(fetchObjectSummaryById.fulfilled, (state, { payload }) => {
      state.objectSummary = payload
    })
    builder.addCase(fetchAccessUsersByObjectId.fulfilled, (state, { payload }) => {
      state.accessUsers = payload
    })
    builder.addCase(fetchAccessGroupsByObjectId.fulfilled, (state, { payload }) => {
      state.accessGroups = payload
    })
    builder.addCase(fetchClassificationsPrint.fulfilled, (state, { payload }) => {
      state.classificationsPrint = payload
    })
    builder.addCase(fetchAnalyticsFile.fulfilled, (state, { payload }) => {
      state.analyticsData = payload
    })
    builder.addCase(fetchFilesToExport.fulfilled, (state, { payload }) => {
      state.filesToExport = payload
    })
    builder.addCase(fetchDataAnalyticsByIdInfo.fulfilled, (state, { payload }) => {
      state.docAnalyticData = payload
    })
    builder.addCase(fetchClassifications.fulfilled, (state, action) => {
      state.classifications = action.payload
    })
  }
})

export const {
  setSortForClassified,
  setSortForUnClassifiedWithEntities,
  setSortForUnClassifiedWithoutEntities,
  setSortForObjects,
  resetChart,
  resetGroupedWidget,
  resetObjects,
  resetClassified,
  resetUnClassifiedWithEntities,
  resetUnClassifiedWithoutEntities,
  resetObjectsWidget,
  resetFilesToExport,
  resetDocAnalyticData,
  setRefreshDocuments,
  resetRefreshDocuments
} = classificationsSlice.actions

export default classificationsSlice.reducer
