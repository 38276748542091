import {
  deleteCollaborationRequest,
  mapBulkCreateOrUpdateDatasourceDataProperty,
  mapQueryCollaboratorTicketDetailsById,
  mapQueryProcessOrTemplateById,
  mapQueryProcesses,
  mapQueryPiaListValues,
  mapQueryPiaProcessReviewSchedules,
  mapQueryTotalPiaProcesses,
  mapRetentionPeriod,
  mutationReassignTicket,
  mutationRejectTicket,
  mutationRevokeAssignment,
  mutationSendReminder,
  mutationUpdateProcessSettings,
  mutationUpdateTicketsStatus,
  queryAddCollaborators,
  queryBulkCreateOrUpdateDatasourceDataProperty,
  queryCollaboratorTicketDetailsById,
  queryCreateRetentionPeriod,
  queryCreatePiaProcess,
  queryCreatePiaTemplate,
  queryDeleteProcesOrTemplate,
  queryProcesses,
  queryProcessOrTemplateById,
  queryPiaListValues,
  queryPiaProcessReviewSchedules,
  queryTicketsForProcessOrTicket,
  queryTotalPiaProcesses,
  queryUpdateCollaborationTicket,
  queryUpdateCollaborator,
  queryUpdatePiaTemplate,
  sendPiaNewCollaborationRequest,
  mapQueryPiaAttributesSets,
  queryPiaAttributesSets,
  queryPiaTickets,
  mapQueryPiaTickets,
  queryProcessOverview,
  mapQueryProcessOverview,
  queryReportById,
  mapQueryReportById,
  mutationDeletePiaCollaboration,
  queryDeletePiaReport,
  queryGeneratePiaReport,
  queryProcessDetails,
  queryCollaborationDetails,
  mapQueryCollaborationDetails,
  queryPiaRiskValues,
  mapQueryPiaRiskOptions,
  mutationSubmitProcessForReview,
  queryPiaLikelinessValues,
  mapQueryPiaLikelinessValues,
  mutationUpdatePiaRiskOrLikeliness,
  mutationDeletePiaRiskOrLikeliness,
  mapQueryGeneratePiaReport,
  mutationReassignPiaReviewer,
  mutationPiaRevokeReview,
  queryProcessCards,
  mapQueryProcessCards,
  queryPiaProcessOwners,
  mapQueryPiaProcessOwners,
  mapQueryPiaOverviewAttributesInstanceCount,
  queryPiaOverviewAttributeInstanceCounts
} from './queries'
import type { PiaAttributeSet, PiaAttributeSetAttribute } from './queries'
import { calculateProcessRisk, updateQuestionStatsAndReturnProcess } from './piaUtil'
import { collateCollaboratorResponses, getCompactCollaboratorDetails } from './collaboratorUtils'
import { SelectOption } from './piaProcesses/components/popupSelectOptions/popupSelectOptions'
import {
  DATASOURCE_STATUS,
  DATA_SOURCE_TYPES,
  DataSourceProcessingStages,
  PAGE,
  PAGE_SIZE
} from '../../constants'
import graphqlService from '../../services/graphqlService'
import { SortParams, defaultSortParams } from '../../utils/sortUtil'
import { DownloadListParams, FilterParams } from '../../interfaces'
import apiService from '../../services/api/apiService'
import { RopaProcessSettingsParams } from '../ropa/ropaSliceV2'
import { RootState } from '../../rootReducer'
import { getGlobalParams } from '../../utils/urlUtil'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { uniq } from 'underscore'

export interface PiaProcessCollaboration {
  emailId?: string
  questionnaireInput: PiaQuestionnaireOptionalTopics
}

export interface PiaFieldCustomInfo {
  label?: string
  options: SelectOption[]
  optional?: boolean
  isRiskConfigured?: boolean
}

export interface PiaProcessCollaborationScope {
  totalTopics?: number
  topics?: PiaQuestionnaireOptionalTopics
}

export interface PiaCollaboration {
  ropaProcessId?: string
  emailBody?: string
  dueDate?: string
  parentCollaboratorId?: string
  collaboratorQuestionnaire?: PiaProcessCollaboration[]
}

export enum DataSourceDataPropertyFieldTypeEnum {
  purpose = 'PURPOSE',
  lawfulBasis = 'LAWFUL_BASIS',
  dataSubjects = 'DATA_SUBJECT',
  specialDataSubjects = 'SPECIAL_DATA_SUBJECT',
  processGroups = 'PROCESS_GROUPS',
  safeguards = 'SAFEGUARDS',
  durationType = 'DURATION_TYPE'
}
export const CUSTOM_TOPIC: BasePiaQuestionnaire = {
  name: 'Custom topic',
  description: '',
  optional: true,
  questions: []
}

export enum PiaDefineProcessSteps {
  processDetails = 1,
  dataElements,
  dataSubjects,
  dataRetention,
  safeguards,
  transfers,
  customTopics
}

export enum PiaSystemTopics {
  processDetails = 'processDetails',
  dataElements = 'dataElements',
  dataSubjects = 'dataSubjects',
  dataRetention = 'dataRetention',
  safeguards = 'safeguards',
  transfer = 'transfers'
}

export const CustomTopicKey = 'customTopics'

export enum PiaConfigAction {
  createProcess = 'createProcess',
  updateProcess = 'updateProcess',
  cloneProcess = 'cloneProcess',
  updateProcessWithActions = 'updateProcessWithActions',
  viewProcess = 'viewProcess',
  createTemplate = 'createTemplate',
  updateTemplate = 'updateTemplate',
  cloneTemplate = 'cloneTemplate',
  viewTemplate = 'viewTemplate',
  viewCollaborators = 'viewCollaborators'
}

export enum PiaInputType {
  MultipleChoice = 'MULTIPLE',
  SingleChoice = 'SINGLE_CHOICE',
  Text = 'SINGLE',
  TextArea = 'DESCRIPTIVE'
}

export enum ProcessorEnum {
  Controller = 'CONTROLLER',
  JointController = 'JOINT_CONTROLLER',
  Processor = 'PROCESSOR',
  SubProcessor = 'SUB_PROCESSOR'
}

export enum ProcessingActivityRoleTypes {
  organization = 'ORGANISATION',
  dpo = 'DPO',
  representative = 'REPRESENTATIVE'
}

export enum TicketStatus {
  NotStarted = 'NOT_STARTED',
  InProgress = 'IN_PROGRESS',
  Rejected = 'REJECTED',
  Revoked = 'REVOKED',
  Resent = 'RESENT',
  Submitted = 'SUBMITTED',
  Complete = 'COMPLETE'
}

export type PiaQuestion = {
  question: string
  options?: string[]
  responseType: PiaInputType
  questionResponse?: string[]
  riskOptions?: any[]
  overallRiskValue?: string
  systemRiskValue?: string
  riskAssociated?: boolean
  questionId?: string
  riskRemediation?: string
  isMandatory: boolean
}

export interface NameIdSummary {
  id: string
  name: string
  type?: string
  value?: string
}

export enum FieldPropertyRisk {
  NA = 'NA',
  LOW = 'LOW_RISK',
  MEDIUM = 'MEDIUM_RISK',
  HIGH = 'HIGH_RISK'
}
export enum FieldPropertyOccurrence {
  NA = 'NA',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH'
}
export interface FieldOptions {
  id: string
  value: string
}

export type FieldProperty = {
  key: string
  value?: string[]
  riskOptions?: FieldOptions[] | null
}

export type BaseCollaboratorDetails = {
  email: string
  status: string
}
export interface BasePiaQuestionnaire {
  questions?: PiaQuestion[]
  questionIds?: string[]
  id?: string
  optional: boolean
  name?: string
  description?: string
  processQuestionId?: string
  totalQuestions?: number
  answeredQuestions?: number
  collaboratorDetails?: BaseCollaboratorDetails[]
}

export interface ProcessDetailsQuestionnaire extends BasePiaQuestionnaire {
  nameProperties: FieldProperty[]
  descriptionProperties: FieldProperty[]
  purposeProperties: FieldProperty[]
  processGroupsProperties: FieldProperty[]
  lawfulBasisProperties: FieldProperty[]
  automatedProcessingProperties: FieldProperty[]
  automatedProcessingDescriptionProperties: FieldProperty[]
  processDetailsProperties: FieldProperty[]
  processDetailsQuestionProperties: {
    nameProperties: FieldProperty[]
    emailProperties: FieldProperty[]
    contactProperties: FieldProperty[]
    addressProperties: FieldProperty[]
  }
  processQuestions?: PiaQuestion[]
  controllerQuestions?: PiaQuestion[]
}

export interface DataSubjectsQuestionnaire extends BasePiaQuestionnaire {
  categories?: string[]
  dataSubjectProperties?: FieldProperty[]
  specialDataSubject?: boolean
  specialDataSubjectProperties?: FieldProperty[]
  specialCategories?: string[]
  specialDataSubjectsProperties?: FieldProperty[]
  lawfulBasis?: string[]
  legalBasisProperties?: FieldProperty[]
}

export type SpecialDataElement = { attributeName: string; sensitivity: string }
export interface DataElementsQuestionnaire extends BasePiaQuestionnaire {
  attributes?: string[]
  specialElements?: SpecialDataElement[]
  lawfulBasisProperties?: FieldProperty[]
  attributeProperties?: FieldProperty[]
  specialElementProperties?: FieldProperty[]
  lawfulBases?: string[]
  associatedDatasources?: string[]
}

export type RetentionPeriod = {
  id?: string
  durationCount?: number
  durationType: string
  triggerEvent: string
  attributeSets: string[]
}
export interface DataRetentionQuestionnaire extends BasePiaQuestionnaire {
  details?: RetentionPeriod[]
  dataRetentionProperties?: FieldProperty[]
  durationTypeProperties?: FieldProperty[]
  triggerEventProperties?: FieldProperty[]
  attributeSetProperties?: FieldProperty[]
}

export interface SafeguardsQuestionnaire extends BasePiaQuestionnaire {
  safeguardProperties?: FieldProperty[]
  safeguards?: string[]
}

interface BaseTransferDetails {
  email?: string
  emailProperties?: FieldProperty[]
  safeguards?: string[]
  safeguardsProperties?: FieldProperty[]
}

export interface CrossBorderTransferDetails extends BaseTransferDetails {
  organisation?: string
  organisationProperties?: FieldProperty[]
  country?: string
  countryProperties?: FieldProperty[]
}
export interface ThirdPartyTransferDetails extends BaseTransferDetails {
  name?: string
  nameProperties?: FieldProperty[]
  address?: string
  addressProperties?: FieldProperty[]
}

export enum PiaTransferDetailsTypeEnum {
  CROSS_BORDER = 'crossBorder',
  THIRD_PARTY = 'thirdParty'
}
export interface TransfersQuestionnaire extends BasePiaQuestionnaire {
  crossBorder: {
    isCrossBorder?: boolean
    crossBorderProperties?: FieldProperty[]
    details: CrossBorderTransferDetails[]
  }
  thirdParty: {
    isThirdParty?: boolean
    thirdPartyProperties?: FieldProperty[]
    details: ThirdPartyTransferDetails[]
  }
}

export interface PiaQuestionnaireOptionalTopics {
  dataElements?: DataElementsQuestionnaire
  dataSubjects?: DataSubjectsQuestionnaire
  dataRetention?: DataRetentionQuestionnaire
  safeguards?: SafeguardsQuestionnaire
  transfers?: TransfersQuestionnaire
  customTopics?: BasePiaQuestionnaire[]
}

export interface PiaCollaboratorQuestion {
  id: string
  isMandatory: boolean
  question: string
}

export interface PiaQuestionnaireV2 extends PiaQuestionnaireOptionalTopics {
  processDetails?: ProcessDetailsQuestionnaire
}

export type ProcessingActivityManagerDetails = {
  name: string
  email: string
  contact: string
  address: string
  roleType: ProcessingActivityRoleTypes
}

export type PiaCollaborator = {
  id: string
  email: string
  status: string
  name: string
  createdAt: string
  updateTimestamp: string
  totalSections?: number
  totalQuestions?: number
  dataElementsQuestionIds: string[]
  dataSubjectsQuestionIds: string[]
  dataRetentionQuestionIds: string[]
  safeguardsQuestionIds: string[]
  transfersQuestionIds: string[]
  customQuestionIds: string[]
  topics?: PiaQuestionnaireOptionalTopics
  parentCollaboratorId?: string
}

export enum PiaProcessReviewStatuses {
  reviewDue = 'REVIEW_DUE',
  reviewPastDue = 'REVIEW_PAST_DUE',
  nowReviewNeeded = 'NO_REVIEW_NEEDED',
  reviewCompleted = 'REVIEW_COMPLETED'
}
export enum PiaProcessReviewDurations {
  year = 'YEAR',
  month = 'MONTH',
  week = 'WEEK'
}
export type PiaProcessSettings = {
  id?: string
  reviewDuration?: PiaProcessReviewDurations
  reviewDueDate?: string
  reviewStatus?: PiaProcessReviewStatuses
  reviewFrequency?: string
  isDueDatePast?: boolean
  isDueDateWarning?: boolean
}

export type PiaRiskDetails = {
  overallRiskValue?: string
  overallOccurrenceValue?: string
  additionalComments?: string
  riskRemediation?: string
}
export enum PiaAssessmentReviews {
  pending = 'REVIEW_PENDING',
  reviewed = 'REVIEWED',
  notSubmiitedForReview = 'NOT_SUBMIT_FOR_REVIEW',
  inProgress = 'REVIEW_IN_PROGRESS'
}

export enum PiaNatureOfProcess {
  existing = 'EXISTING_PROCESS',
  new = 'NEW_PROCESS',
  change = 'CHANGE_TO_EXISTING_PROCESS'
}

export type PiaRiskOption = {
  id: string
  displayName: string
  colour: string
  value?: number
  processUsageCount?: number
  templateUsageCount?: number
}

export type PiaProcess = {
  id: string
  ticketId?: string
  parentCollaboratorId?: string
  owner?: string
  systemTemplate?: boolean
  isUpdatedAfterReview?: boolean
  dpoEmail?: string
  assignedTo?: string
  templateId?: string
  usageCount?: number
  name?: string
  description?: string
  purpose?: string[]
  purposeIds?: string[]
  processGroups?: string[]
  processGroupIds?: string[]
  lawfulBasis?: string[]
  lawfulBasisIds?: string[]
  automatedProcessing?: boolean
  automatedProcessingDescription?: string
  status?: TicketStatus
  processDetails?: {
    creatorRole?: string
    processingActivityDetails?: {
      order?: number
      managerType: string
      details: ProcessingActivityManagerDetails[]
    }[]
  }
  subjectCategories?: string[]
  specialSubjectCategories?: string[]
  specialSubjectLegalBasis?: string[]
  customPurpose?: string
  customLawfulBasis?: string
  createdAt?: string
  updatedAt?: string
  reviewedOn?: string
  systemDefined?: boolean
  reportsCount?: number
  _isNew?: boolean
  departments?: string[]
  reportGeneratedOn?: number
  questionnaire?: PiaQuestionnaireV2
  processSettings?: PiaProcessSettings
  riskDetails?: PiaRiskDetails
  reviewers?: string[]
  assessmentReview?: PiaAssessmentReviews
  natureOfProcess?: PiaNatureOfProcess
}

export type PiaProcessGroupCard = {
  processesTotal?: number
  processesInReview?: number
  id: string
  name: string
}

//collaborators and tickets
export interface PiaTicket {
  id: string
  ticketId: string
  processName: string
  processGroups: string[]
  processOwner: string
  updatedAt?: string
  assignee?: string
  createdAt?: string
  dueDate: string
  status: string
}
export type PiaTicketsParams = {
  page: number
  pageSize?: number
  emailId?: string
  statuses?: string[]
  filters?: FilterParams
}
export const ACTION_PIA_TICKETS = 'pia/tickets'
export const fetchPiaTickets = createAsyncThunk(
  ACTION_PIA_TICKETS,
  async (params: PiaTicketsParams, { getState }) => {
    const queryParams = {
      ...params,
      ...getGlobalParams(getState() as RootState)
    }
    const raw = await graphqlService.execute(queryPiaTickets(queryParams))
    return mapQueryPiaTickets(raw)
  }
)

export const PIA_COLLABORATION_DELETE = 'pia/delete-collaboration'
export const deletePiaCollaboration = createAsyncThunk(
  PIA_COLLABORATION_DELETE,
  async (id: string) => {
    await graphqlService.execute(mutationDeletePiaCollaboration(id))
  }
)

export interface SubmitProcessForReviewParams {
  processId: string
  emailId: string
  emailBody?: string
  dueDate?: string
}
export const PIA_SUBMIT_PROCESS_FOR_REVIEW = 'pia/send-review-request'
export const submitProcessForReview = createAsyncThunk(
  PIA_SUBMIT_PROCESS_FOR_REVIEW,
  async (params: SubmitProcessForReviewParams) => {
    return await graphqlService.execute(mutationSubmitProcessForReview(params))
  }
)

export const ACTION_COLLABORATION_SCOPE_FETCH = 'pia/collaboration-scope'
export const fetchCollaborationDetails = createAsyncThunk(
  ACTION_COLLABORATION_SCOPE_FETCH,
  async (params: { id: string; isProcess?: boolean }) => {
    const queryFn = params.isProcess ? queryProcessDetails : queryCollaborationDetails
    const raw = await graphqlService.execute(queryFn(params.id))
    return mapQueryCollaborationDetails(raw)
  }
)

// process overview
export interface PiaProcessOverview {
  processUpdatedAt: string
  totalDataElements: number
  totalSpecialDataElements: number
  totalDataCategories: number
  totalDataSubjects: number
  isSpecialDataSubjectsEnabled?: boolean
  totalSpecialDataSubjects: number
  oldestReportDate: string
  newestReportDate: string
  totalReports: number
  completionPercentage: number
  collaborators: PiaCollaborator[]
  attributes: {
    name: string
    internalName: string
    sensitivity: string
    attributeInstanceCount: number
  }[]
  dataSources: { name: string; totalAttributes: number; datasourceType: DATA_SOURCE_TYPES }[]
}

export interface PiaProcessOverviewParams {
  processId: string
}
export const ACTION_PROCESS_OVERVIEW = 'pia/process-overview'
export const fetchProcessOverview = createAsyncThunk(
  ACTION_PROCESS_OVERVIEW,
  async (params: PiaProcessOverviewParams) => {
    const raw = await graphqlService.execute(queryProcessOverview(params))
    return mapQueryProcessOverview(raw)
  }
)

// Main State
export interface PiaReviewSchedule {
  reviewFrequency: number
  reviewDuration: string
}

type PiaState = {
  processes: {
    sort: SortParams
    list?: PiaProcess[]
    total?: number
    groups?: PiaProcessGroupCard[]
    groupsTotal?: number
  }
  templates: {
    sort: SortParams
    list?: PiaProcess[]
    total?: number
  }
  riskOptions?: PiaRiskOption[]
  likelinessOptions?: PiaRiskOption[]
  totalProcesses?: number
  selectedTemplate?: PiaProcess
  selectedTemplateOrProcess?: PiaProcess
  optionLists: {
    attributeSets?: PiaAttributeSet[]
    attributes?: PiaAttributeSetAttribute[]
    purposes?: NameIdSummary[]
    processGroups?: NameIdSummary[]
    durations?: NameIdSummary[]
    lawfulBasis?: NameIdSummary[]
    subjectCategories?: NameIdSummary[]
    subjectSpecialCategories?: NameIdSummary[]
    safetyMeasures?: NameIdSummary[]
    processingStages?: DataSourceProcessingStages[]
    reviewSchedules?: PiaReviewSchedule[]
    processOwners?: string[]
    chartAttributes?: PiaAttributeSetAttribute[]
  }
  selectedDatasourceDataProperties?: NameIdSummary[]
  createdDatasourceDataProperty: {
    id?: string
    type?: string
  }
  submittedTicketIds?: string[]
  collaboration?: PiaCollaboration
  collaborators?: PiaCollaborator[]
  collaborationDetails?: PiaProcessCollaborationScope
  exportProcesses?: { data: string; fileName: string }
  tickets: {
    sort: SortParams
    list?: PiaTicket[]
    total?: number
  }
  processOverview?: PiaProcessOverview
  showErrors?: boolean
  report?: PiaReportPrint
}

const initialList = {
  sort: defaultSortParams
}
export const initialState: PiaState = {
  processes: initialList,
  templates: initialList,
  optionLists: {},
  selectedTemplateOrProcess: undefined,
  createdDatasourceDataProperty: {},
  submittedTicketIds: undefined,
  tickets: {
    sort: defaultSortParams
  }
}

export type PiaProcessesParams = {
  [PAGE]?: number
  [PAGE_SIZE]?: number
  id?: string
  filters?: FilterParams
  isSystemDefined?: boolean
}

export const ACTION_PIA_FETCH_PROCESSES = 'pia/fetch/processes'
export const fetchPiaProcesses = createAsyncThunk(
  ACTION_PIA_FETCH_PROCESSES,
  async (params: PiaProcessesParams, { getState }) => {
    const queryParams = {
      ...params,
      isSystemDefined: false,
      ...getGlobalParams(getState() as RootState)
    }
    const raw = await graphqlService.execute(queryProcesses(queryParams))
    return mapQueryProcesses(raw)
  }
)

export const ACTION_PIA_FETCH_PROCESS_CARDS = 'pia/fetch/processesCards'
export const fetchPiaProcessCards = createAsyncThunk(
  ACTION_PIA_FETCH_PROCESS_CARDS,
  async (params: PiaProcessesParams) => {
    const raw = await graphqlService.execute(
      queryProcessCards({ ...params, isSystemDefined: false })
    )
    return mapQueryProcessCards(raw)
  }
)

export enum PiaRiskScaleTypes {
  risk = 'RISK',
  likeliness = 'LIKELINESS_OF_OCCURRENCE'
}
export const ACTION_PIA_RISK_VALUES = 'pia/fetch/risk-values'
export const fetchPiaRiskOptions = createAsyncThunk(ACTION_PIA_RISK_VALUES, async () => {
  const raw = await graphqlService.execute(queryPiaRiskValues())
  return mapQueryPiaRiskOptions(raw)
})
export const ACTION_PIA_LIKELINESS_VALUES = 'pia/fetch/likeliness-values'
export const fetchPiaLikelinessOptions = createAsyncThunk(
  ACTION_PIA_LIKELINESS_VALUES,
  async () => {
    const raw = await graphqlService.execute(queryPiaLikelinessValues())
    return mapQueryPiaLikelinessValues(raw)
  }
)

export type PiaRiskOrLikelinessUpdateParams = {
  type: PiaRiskScaleTypes
  options: PiaRiskOption[]
  idsToDelete?: string[]
}
export const ACTION_PIA_RISK_VALUES_UPDATE = 'pia/fetch/risk-values-update'
export const updatePiaRiskOrLikelinessOptions = createAsyncThunk(
  ACTION_PIA_RISK_VALUES_UPDATE,
  async (params: PiaRiskOrLikelinessUpdateParams) => {
    if (params.idsToDelete?.length) {
      await graphqlService.execute(mutationDeletePiaRiskOrLikeliness(params.idsToDelete))
    }
    await graphqlService.execute(mutationUpdatePiaRiskOrLikeliness(params))
  }
)

export type PiaTotalProcessesParams = {
  owner?: string
}
export const ACTION_PIA_FETCH_PROCESSES_TOTAL = 'pia/fetch/processes-total'
export const fetchTotalPiaProcesses = createAsyncThunk(
  ACTION_PIA_FETCH_PROCESSES_TOTAL,
  async (params?: PiaTotalProcessesParams) => {
    const raw = await graphqlService.execute(queryTotalPiaProcesses(params))
    return mapQueryTotalPiaProcesses(raw)
  }
)

export const ACTION_PIA_FETCH_TEMPLATES = 'pia/fetch/templates'
export const fetchPiaTemplates = createAsyncThunk(
  ACTION_PIA_FETCH_TEMPLATES,
  async (params: PiaProcessesParams, { getState }) => {
    const queryParams = {
      ...params,
      isSystemDefined: true,
      ...getGlobalParams(getState() as RootState)
    }
    const raw = await graphqlService.execute(queryProcesses(queryParams))
    return mapQueryProcesses(raw)
  }
)

export type PiaProcessParams = {
  id: string
  details?: PiaProcess
}
export const ACTION_PIA_FETCH_TEMPLATE_OR_PROCESS = 'pia/fetch/templateOrProcessById'
export const fetchPiaTemplateOrProcessById = createAsyncThunk(
  ACTION_PIA_FETCH_TEMPLATE_OR_PROCESS,
  async ({ id, details }: PiaProcessParams) => {
    const raw = await graphqlService.execute(queryProcessOrTemplateById(id))
    return mapQueryProcessOrTemplateById(raw, details)
  }
)

export const ACTION_PIA_FETCH_COLLABORATION_DETAILS = 'pia/fetch/collaborationInfoById'
export const fetchPiaCollaborationInfoById = createAsyncThunk(
  ACTION_PIA_FETCH_COLLABORATION_DETAILS,
  async (id: string) => {
    const raw = await graphqlService.execute(queryCollaboratorTicketDetailsById(id))
    return mapQueryCollaboratorTicketDetailsById(raw)
  }
)
export type AssociatedTicketsParams = { id: string; isProcess: boolean }
export const ACTION_PIA_FETCH_ASSOCIATED_COLLABORATION_TICKETS =
  'pia/fetch/associatedCollaborationTickets'
export const fetchAssociatedCollaborationTickets = createAsyncThunk(
  ACTION_PIA_FETCH_ASSOCIATED_COLLABORATION_TICKETS,
  async (params: AssociatedTicketsParams) => {
    const raw = await graphqlService.execute(queryTicketsForProcessOrTicket(params))
    return raw?.ropaCollaboration
  }
)

export const ACTION_PIA_FETCH_TEMPLATE = 'pia/fetch/templateById'
export const fetchPiaTemplateById = createAsyncThunk(
  ACTION_PIA_FETCH_TEMPLATE,
  async (id: string) => {
    const raw = await graphqlService.execute(queryProcessOrTemplateById(id))
    return mapQueryProcessOrTemplateById(raw)
  }
)

export const ACTION_CREATE_PIA_RETENTION_PERIOD = 'pia/createRetentionPeriod'
export const createRetentionPeriod = createAsyncThunk(
  ACTION_CREATE_PIA_RETENTION_PERIOD,
  async (period: RetentionPeriod): Promise<any> => {
    const response = await graphqlService.execute(queryCreateRetentionPeriod(period))
    return mapRetentionPeriod(response)
  }
)
export type DatasourceDataProperty = {
  id?: string
  value: string
  fieldType: DataSourceDataPropertyFieldTypeEnum
}
export const ACTION_CREATE_UPDATE_DATASOURCE_DATA_PROPERTY = 'pia/bulkCreateDatasourceDataProperty'
export const bulkCreateOrUpdateDatasourceDataProperty = createAsyncThunk(
  ACTION_CREATE_UPDATE_DATASOURCE_DATA_PROPERTY,
  async (dataProperties: DatasourceDataProperty[]): Promise<any> => {
    const raw = await graphqlService.execute(
      queryBulkCreateOrUpdateDatasourceDataProperty(dataProperties)
    )
    return mapBulkCreateOrUpdateDatasourceDataProperty(raw)
  }
)

export const ACTION_CREATE_DATASOURCE_DATA_PROPERTY = 'pia/createDatasourceDataProperty'
export const createDatasourceDataProperty = createAsyncThunk(
  ACTION_CREATE_DATASOURCE_DATA_PROPERTY,
  async (property: DatasourceDataProperty): Promise<any> => {
    const raw = await graphqlService.execute(
      queryBulkCreateOrUpdateDatasourceDataProperty([property])
    )
    return mapBulkCreateOrUpdateDatasourceDataProperty(raw, property.fieldType)
  }
)

export const ACTION_PIA_CREATE_TEMPLATE = 'pia/createTemplate'
export const createPiaTemplate = createAsyncThunk(
  ACTION_PIA_CREATE_TEMPLATE,
  async (template: PiaProcess, { rejectWithValue }) => {
    try {
      return await graphqlService.execute(queryCreatePiaTemplate(template, false))
    } catch (error: any) {
      return rejectWithValue({ statusMessage: error?.errors[0]?.message || '' })
    }
  }
)

export const ACTION_PIA_CLONE_PROCESS = 'pia/cloneProcess'
export const clonePiaProcess = createAsyncThunk(
  ACTION_PIA_CLONE_PROCESS,
  async (process: PiaProcess, { rejectWithValue }) => {
    try {
      const response = await graphqlService.execute(queryCreatePiaTemplate(process, true))
      const processId = response.createProcessClone.ropaProcessId
      const raw = await graphqlService.execute(queryProcessOrTemplateById(processId))
      return mapQueryProcessOrTemplateById(raw)
    } catch (error: any) {
      return rejectWithValue({ statusMessage: error?.errors[0]?.message || '' })
    }
  }
)

export interface UpdatePiaTemplateParams {
  submitReview?: boolean
  status?: PiaAssessmentReviews
  template: PiaProcess
  notUpdatedAfterReview?: boolean
}
export const ACTION_PIA_UPDATE_TEMPLATE = 'pia/updateTemplate'
export const updatePiaTemplate = createAsyncThunk(
  ACTION_PIA_UPDATE_TEMPLATE,
  async (params: UpdatePiaTemplateParams, { rejectWithValue }) => {
    try {
      const response = await graphqlService.execute(queryUpdatePiaTemplate(params))
      return response
    } catch (error: any) {
      return rejectWithValue({ statusMessage: error?.errors[0]?.message || '' })
    }
  }
)

export const ACTION_PIA_CREATE_PROCESS = 'pia/createProcess'
export const createPiaProcess = createAsyncThunk(
  ACTION_PIA_CREATE_PROCESS,
  async (process: PiaProcess, { rejectWithValue }) => {
    try {
      const response = await graphqlService.execute(queryCreatePiaProcess(process))
      return response.createProcess
    } catch (error: any) {
      return rejectWithValue({ statusMessage: error?.errors[0]?.message || '' })
    }
  }
)
export type UpdateCollaborationTicketParams = { process: PiaProcess; status: TicketStatus }
export const ACTION_PIA_UPDATE_COLLABORATION_TICKET = 'pia/updateCollaborationTicket'
export const updateCollaborationTicket = createAsyncThunk(
  ACTION_PIA_UPDATE_COLLABORATION_TICKET,
  async (params: UpdateCollaborationTicketParams): Promise<PiaProcess> => {
    const response = await graphqlService.execute(queryUpdateCollaborationTicket(params))
    return response
  }
)

export const ACTION_PIA_DELETE_TEMPLATE_OR_PROCESS = 'pia/delete/templateOrProcessById'
export const deletePiaTemplateOrProcessById = createAsyncThunk(
  ACTION_PIA_DELETE_TEMPLATE_OR_PROCESS,
  async (id: string) => {
    await graphqlService.execute(queryDeleteProcesOrTemplate(id))
    return { id }
  }
)

export const ACTION_PIA_PURPOSES = 'pia/options/purposes'
export const fetchPurposes = createAsyncThunk(ACTION_PIA_PURPOSES, async () => {
  const raw = await graphqlService.execute(queryPiaListValues('PURPOSE'))
  return mapQueryPiaListValues(raw)
})
export const ACTION_PIA_PROCESS_GROUPS = 'pia/options/process-groups'
export const fetchPiaProcessGroups = createAsyncThunk(ACTION_PIA_PROCESS_GROUPS, async () => {
  const raw = await graphqlService.execute(queryPiaListValues('PROCESS_GROUPS'))
  return mapQueryPiaListValues(raw)
})

export const ACTION_PIA_PROCESS_DURATIONS = 'pia/options/durations'
export const fetchDurations = createAsyncThunk(ACTION_PIA_PROCESS_DURATIONS, async () => {
  const raw = await graphqlService.execute(queryPiaListValues('DURATION_TYPE'))
  return mapQueryPiaListValues(raw)
})

export type PiaAttributesParams = {
  dataSourceIds: string[]
  print?: boolean
  forDataElements?: boolean
}
export const ACTION_PIA_ATTRIBUTE_SETS = 'pia/options/attributeSets'
export const fetchPiaAttributes = createAsyncThunk(
  ACTION_PIA_ATTRIBUTE_SETS,
  async (params: PiaAttributesParams) => {
    const resultRaw = await graphqlService.execute(queryPiaAttributesSets(params))
    return mapQueryPiaAttributesSets(resultRaw, params)
  }
)
export const ACTION_PIA_ATTRIBUTE_INSTANCES = 'pia/options/attribute/instances'
export const fetchPiaOverviewAttributeInstance = createAsyncThunk(
  ACTION_PIA_ATTRIBUTE_INSTANCES,
  async (params: PiaAttributesParams) => {
    const resultRaw = await graphqlService.execute(queryPiaOverviewAttributeInstanceCounts(params))
    return mapQueryPiaOverviewAttributesInstanceCount(resultRaw, params)
  }
)
export const ACTION_PIA_LAWFUL_BASIS = 'pia/options/lawful-basis'
export const fetchLawfulBasis = createAsyncThunk(ACTION_PIA_LAWFUL_BASIS, async () => {
  const raw = await graphqlService.execute(queryPiaListValues('LAWFUL_BASIS'))
  return mapQueryPiaListValues(raw)
})

export const ACTION_PIA_SUBJECT_CATEGORIES = 'pia/options/subject-categories'
export const fetchSubjectCategories = createAsyncThunk(ACTION_PIA_SUBJECT_CATEGORIES, async () => {
  const raw = await graphqlService.execute(queryPiaListValues('DATA_SUBJECT'))
  return mapQueryPiaListValues(raw)
})

export const ACTION_PIA_SPECIAL_SUBJECT_CATEGORIES = 'pia/options/special-subject-categories'
export const fetchSpecialSubjectCategories = createAsyncThunk(
  ACTION_PIA_SPECIAL_SUBJECT_CATEGORIES,
  async () => {
    const raw = await graphqlService.execute(queryPiaListValues('SPECIAL_DATA_SUBJECT'))
    return mapQueryPiaListValues(raw)
  }
)

export const ACTION_PIA_SAFETY_MEASURES = 'pia/options/safetyMeasures'
export const fetchPiaSafetyMeasures = createAsyncThunk(ACTION_PIA_SAFETY_MEASURES, async () => {
  const raw = await graphqlService.execute(queryPiaListValues('SAFEGUARDS'))
  return mapQueryPiaListValues(raw)
})

export type SendReminderParams = {
  id: string
  emailBody: string
  dueDate: string
}
export const ACTION_PIA_SEND_REMINDER = 'pia/collaborator/sendReminder'
export const sendReminderToCollaborator = createAsyncThunk(
  ACTION_PIA_SEND_REMINDER,
  async (params: SendReminderParams) => {
    await graphqlService.execute(mutationSendReminder(params))
  }
)

export const ACTION_PIA_PROCESS_REVIEW_SCHEDULES = 'pia/options/process-review-schedules'
export const fetchPiaProcessReviewSchedules = createAsyncThunk(
  ACTION_PIA_PROCESS_REVIEW_SCHEDULES,
  async () => {
    const raw = await graphqlService.execute(queryPiaProcessReviewSchedules())
    return mapQueryPiaProcessReviewSchedules(raw)
  }
)

export const ACTION_PIA_PROCESS_OWNERS_FETCH = 'pia/process-owners'
export const fetchPiaProcessOwners = createAsyncThunk(ACTION_PIA_PROCESS_OWNERS_FETCH, async () => {
  const result = await graphqlService.execute(queryPiaProcessOwners())
  return mapQueryPiaProcessOwners(result)
})

export const ACTION_PIA_REVOKE_ASSIGNMENT = 'pia/collaborator/revokeAssignment'
export const revokeAssignment = createAsyncThunk(
  ACTION_PIA_REVOKE_ASSIGNMENT,
  async (params: { id: string }) => {
    await graphqlService.execute(mutationRevokeAssignment(params))
  }
)
export type RevokePiaReviewParams = {
  processId: string
  emailBody?: string
}
export const ACTION_PIA_REVOKE_REVIEW = 'pia/collaborator/revokeReview'
export const revokePiaReview = createAsyncThunk(
  ACTION_PIA_REVOKE_REVIEW,
  async (params: RevokePiaReviewParams) => {
    return await graphqlService.execute(mutationPiaRevokeReview(params))
  }
)

export type ReassignPiaAssignmentParams = {
  processId: string
  reviewers: string[]
  emailBody: string
  dueDate: string
}
export const ACTION_PIA_REASSIGN_REVIEWER = 'pia/collaborator/reassignReviewer'
export const reassignPiaReviewer = createAsyncThunk(
  ACTION_PIA_REASSIGN_REVIEWER,
  async (params: ReassignPiaAssignmentParams) => {
    await graphqlService.execute(mutationReassignPiaReviewer(params))
  }
)

export type RejectTicketParams = {
  id: string
  emailBody: string
}
export const ACTION_PIA_REJECT_TICKET = 'pia/collaborator/rejectTicket'
export const rejectTicket = createAsyncThunk(
  ACTION_PIA_REJECT_TICKET,
  async (params: RejectTicketParams) => {
    await graphqlService.execute(mutationRejectTicket(params))
  }
)

export type ReassignmentParams = SendReminderParams & { email: string }
export const ACTION_PIA_REASSIGN_TICKET = 'pia/collaborator/reassign'
export const reassignTicket = createAsyncThunk(
  ACTION_PIA_REASSIGN_TICKET,
  async (params: ReassignmentParams) => {
    await graphqlService.execute(mutationReassignTicket(params))
  }
)

export const ACTION_PIA_DELETE_TICKET = 'pia/collaborator/delete'
export const deleteTicket = createAsyncThunk(
  ACTION_PIA_DELETE_TICKET,
  async (params: { id: string }) => {
    await graphqlService.execute(deleteCollaborationRequest(params))
  }
)

export const ACTION_PIA_SEND_NEW_TICKET = 'pia/collaborator/sendNewTicket'
export const sendNewTicketToCollaborator = createAsyncThunk(
  ACTION_PIA_SEND_NEW_TICKET,
  async (params: SendReminderParams) => {
    await graphqlService.execute(sendPiaNewCollaborationRequest(params))
  }
)

export const ACTION_PIA_ADD_COLLABORATOR = 'pia/collaborators/add'
export const piaAddCollaborators = createAsyncThunk(
  ACTION_PIA_ADD_COLLABORATOR,
  async (params: PiaCollaboration, { rejectWithValue }) => {
    try {
      return await graphqlService.execute(queryAddCollaborators(params))
    } catch (error: any) {
      return rejectWithValue({ statusMessage: error?.errors[0]?.message || '' })
    }
  }
)

export interface UpdateCollaborationParams {
  collaboratorId: string
  ropaProcessId: string
  questionnaire: PiaQuestionnaireOptionalTopics
}
export const ACTION_PIA_UPDATE_COLLABORATOR = 'pia/collaborators/update'
export const piaUpdateCollaborator = createAsyncThunk(
  ACTION_PIA_UPDATE_COLLABORATOR,
  async (params: UpdateCollaborationParams) => {
    await graphqlService.execute(queryUpdateCollaborator(params))
  }
)

export type UpdateTicketsStatus = {
  ids: string[]
  status: TicketStatus
}
export const ACTION_PIA_UPDATE_TICKETS_STATUS = 'pia/collaborator/updateTicketsStatus'
export const updateTicketsStatus = createAsyncThunk(
  ACTION_PIA_UPDATE_TICKETS_STATUS,
  async (params: UpdateTicketsStatus) => {
    await graphqlService.execute(mutationUpdateTicketsStatus(params))
  }
)

// reminders
export const ACTION_PIA_UPDATE_PROCESS_SETTINGS = 'pia/updateProcessSettings'
export const updateProcessSettings = createAsyncThunk(
  ACTION_PIA_UPDATE_PROCESS_SETTINGS,
  async (params: RopaProcessSettingsParams) => {
    await graphqlService.execute(mutationUpdateProcessSettings(params))
  }
)

export const ACTION_PIA_PROCESSES_EXPORT = 'pia/exportProcesses'
export const fetchPiaProcessesCsv = createAsyncThunk(
  ACTION_PIA_PROCESSES_EXPORT,
  async (params: DownloadListParams) => {
    const { data, headers } = await apiService.downloadFile(params)
    const disposition = headers['content-disposition']
    let fileName = ''
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRegex.exec(disposition)
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '')
      }
    }

    return { data, fileName }
  }
)
export const ACTION_PIA_PROCESSING_STAGES = 'pia/processingStages'
export const fetchProcessingStages = createAsyncThunk(ACTION_PIA_PROCESSING_STAGES, async () => {
  const result: DataSourceProcessingStages[] = Object.values(DataSourceProcessingStages)
  return result
})

// reports
export enum ProcessingActivityManagerTypes {
  controller = 'CONTROLLER',
  jointController = 'JOINT_CONTROLLER',
  processor = 'PROCESSOR',
  jointProcessor = 'JOINT_PROCESSOR'
}
export enum ProcessingActivityManagerFilters {
  controller = 'controller',
  jointController = 'jointController',
  processor = 'processor',
  jointProcessor = 'subProcessor'
}
export type PiaProcessingActivityDetails = {
  _id?: string
  _isOpened?: boolean
  order?: number
  originalIndex?: number
  managerType: ProcessingActivityManagerTypes
  details: ProcessingActivityManagerDetails[]
}
export type PiaProcessDetails = {
  isController: boolean
  processingActivityDetails: PiaProcessingActivityDetails[]
}
export type PiaDataSourceInfo = {
  dsoEmail: string
  id?: string
  datasource?: {
    id: string
    name: string
    type: DATA_SOURCE_TYPES
    location?: string
    createdBy?: string
    lastSyncedTime?: string
    status?: DATASOURCE_STATUS
    ropaDatasourceType?: DATASOURCE_STATUS
  }
}
export type PiaDataElements = {
  attributes?: string[]
  specialAttributes?: string[]
  datasourceIds?: string[]
  purposeForSpecialAttributes?: string[]
}
export interface PiaRetentionPolicy {
  id: string
  duration: string
  durationType: string
  triggerEvent: string
  scope: string[]
}
export type PiaTransferDetails = {
  _id?: string
  _isOpened?: boolean
  details?: {
    name?: string
    email?: string
    safeguards?: string[]
    address?: string
  }
}
export type RopaTransferDetailsType =
  | PiaTransferDetailsTypeEnum.CROSS_BORDER
  | PiaTransferDetailsTypeEnum.THIRD_PARTY
export type PiaTransferInfo = {
  thirdParty?: boolean
  thirdPartyList?: PiaTransferDetails[]
  crossBorder?: boolean
  crossBorderList?: PiaTransferDetails[]
}
export enum PiaElementTypes {
  textarea = 'SINGLE',
  dropdown = 'MULTIPLE'
}
export type PiaQuestionnaireTopicQuestion = {
  questionId: string
  statement: string
  responseType: PiaElementTypes
  responses?: string[]
  isOptional?: boolean
  value?: string | boolean | number
  _isDeleted?: boolean
}
export enum QuestionnaireTopicTypes {
  attributes = 'attributes',
  classificationObjects = 'classificationObjects',
  entityTypes = 'entityTypes',
  purpose = 'purpose',
  retentionPeriod = 'retentionPeriod',
  dataLink = 'dataLink',
  dataTransferDetails = 'dataTransferDetails',
  safetyMeasures = 'safetyMeasures',
  custom = 'custom',
  role = 'role',
  processDetails = 'processDetails',
  dataSubjects = 'dataSubjects'
}
export type PiaQuestionnaireTopic = {
  id?: string
  topicId: string
  description?: string
  descriptionDeleted?: boolean
  questions?: PiaQuestionnaireTopicQuestion[]
  optional?: boolean
  name?: string
  _type?: QuestionnaireTopicTypes
  _isComplete?: boolean
  _isUpdated?: boolean
  _isCreated?: boolean
  _isDeleted?: boolean
  customValue?: string
  dropdownField?: string
  hasDefaultValues?: boolean
}
export type PiaAttribute = {
  id: string
  name: string
  instanceCount: number
  sensitivityLabel?: string
  totalDataSources?: number
  dataSources?: Array<{ id: string; name: string }>
}
export interface PiaAttributes extends PiaQuestionnaireTopic {
  attributeIds: string[]
  attributes: PiaAttribute[]
  allowModification?: boolean
}
export type RopaClassification = NameIdSummary
export interface RopaClassifications extends PiaQuestionnaireTopic {
  classificationIds: string[]
  classifications: RopaClassification[]
  allowModification?: boolean
}
export interface PiaEntityTypes extends PiaQuestionnaireTopic {
  entityIds: string[]
  entityTypes: []
  allowModification?: boolean
}
export interface RopaEntityTypes extends PiaQuestionnaireTopic {
  entityIds: string[]
  entityTypes: NameIdSummary[]
  allowModification?: boolean
}
export interface PiaPurpose extends PiaQuestionnaireTopic {
  type: string
  options?: string[]
  label: string
  selection?: string
}
export interface PiaProcessGroup extends PiaQuestionnaireTopic {
  type: string
  options?: string[]
  label: string
  selection?: string
}
export interface PiaLawfulBasis extends PiaQuestionnaireTopic {
  type: string
  options?: string[]
  label: string
  selection?: string
}
export interface PiaRetentionPeriod extends PiaQuestionnaireTopic {
  retentionPolicy: string
  retentionPolicyOptions: string[]
  label: string
}
export interface PiaDataTransferDetails {
  name?: string
  email?: string
  address?: string
  contact?: string
  org?: string
  country?: string
  safeguards?: string[]
  safeguardIds?: string[]
}
export interface PiaDataTransfer extends PiaQuestionnaireTopic {
  topicId: string
  details: PiaDataTransferDetails[]
}
export interface PiaSafetyMeasures extends PiaQuestionnaireTopic {
  safetyMeasures: string
  safetyMeasuresOptions: string[]
  label: string
}
export type PiaCustomTopic = PiaQuestionnaireTopic
export interface PiaProcessDetailsTopic extends PiaQuestionnaireTopic {
  processGroup: { label: string; value?: string; options?: string[] }
  processName: { label: string; value?: string }
  processDescription: { label: string; value?: string }
  purpose: { label: string; value?: string[]; options?: string[] }
  lawfulBasis: { label: string; value?: string[]; options?: string[] }
  isAutomatedProcessingEnabled: { label: string; value?: boolean }
  automatedProcessingDescription: { label: string; value?: string }
}

export interface PiaQuestionnaire {
  name?: string
  description?: string
  attributes?: PiaAttributes
  classificationObjects?: RopaClassifications
  entityTypes?: RopaEntityTypes
  purpose?: PiaPurpose
  processGroup?: PiaProcessGroup
  lawfulBasis?: PiaLawfulBasis
  retentionPeriod?: PiaRetentionPeriod
  dataTransferDetails?: PiaDataTransfer
  safetyMeasures?: PiaSafetyMeasures
  topics?: PiaCustomTopic[]
  processDetails?: PiaProcessDetailsTopic
}

export interface PiaReportProcessorInfo {
  managerType: string
  email: string
  name: string
  address: string
  contact: string
  roleType: string
  emailProperties: FieldProperty[]
  nameProperties: FieldProperty[]
  addressProperties: FieldProperty[]
  contactProperties: FieldProperty[]
}

export type PiaListOption = { id: string; value: string; isDeleted: boolean }

export interface PiaReportPrint {
  id?: string
  name: string
  process: {
    id: string
    owner?: string
    dpoEmail?: string
    templateId?: string
    name?: string
    nameProperties?: FieldProperty[]
    description?: string
    descriptionProperties?: FieldProperty[]
    purpose?: PiaListOption[]
    purposeProperties?: FieldProperty[]
    processGroups?: PiaListOption[]
    processGroupsProperties?: FieldProperty[]
    lawfulBasis?: PiaListOption[]
    lawfulBasisProperties?: FieldProperty[]
    automatedProcessing?: boolean
    automatedProcessingProperties?: FieldProperty[]
    automatedProcessingDescription?: string
    automatedProcessingDescriptionProperties?: FieldProperty[]
    subjectCategories?: string[]
    specialSubjectCategories?: string[]
    specialSubjectLegalBasis?: string[]
    customPurpose?: string
    customLawfulBasis?: string
    createdAt?: string
    updatedAt?: string
    systemDefined?: boolean
    reportsCount?: number
    processes?: PiaProcess[]
    _isNew?: boolean
    departments?: string[]
    processDetails?: PiaProcessDetails
    datasourcesInfo?: PiaDataSourceInfo[]
    dataElements?: PiaDataElements
    dataRetentionPolicies?: PiaRetentionPolicy[]
    safeguards?: string[]
    transfers?: PiaTransferInfo
    safetyMeasures?: PiaSafetyMeasures
    isDraft?: boolean
    questionnaire?: PiaQuestionnaire
    reportGeneratedOn?: number
    nameLabel: string
    descriptionLabel: string
    purposeLabel: string
    lawfulBasisLabel: string
    automatedProcessingDescriptionLabel: string
    automatedProcessingLabel: string
    processGroupsLabel: string
    questions: PiaQuestion[]
    controllerQuestions: PiaQuestion[]
    risk?: FieldPropertyRisk
    likeliness?: FieldPropertyOccurrence
    riskDetails?: PiaRiskDetails
    assessmentReview?: PiaAssessmentReviews
  }
  topics: {
    dataElements?: {
      name?: string
      attributes?: {
        id: string
        attributeName: string
        sensitivity: string
        attributeSets: string[]
      }[]
      attributeProperties?: FieldProperty[]
      specialElements?: SpecialDataElement[]
      specialElementProperties?: FieldProperty[]
      lawfulBases?: PiaListOption[]
      lawfulBasisProperties?: FieldProperty[]
      associatedDatasources?: string[]
      questions?: PiaQuestion[]
    }
    dataSubjects: {
      name?: string
      dataSubjects?: PiaListOption[]
      dataSubjectLabel?: string
      dataSubjectProperties?: FieldProperty[]
      specialDataSubject?: boolean
      specialDataSubjectProperties?: FieldProperty[]
      specialDataSubjectsProperties?: FieldProperty[]
      specialCategories?: PiaListOption[]
      specialCategoriesLabel?: string
      legalBases?: PiaListOption[]
      legalBasesProperties?: FieldProperty[]
      legalBasesLabel?: string
      questions?: PiaQuestion[]
    }
    dataRetention: {
      name?: string
      details?: RetentionPeriod[]
      dataRetentionProperties?: FieldProperty[]
      durationTypeProperties?: FieldProperty[]
      attributeSetProperties?: FieldProperty[]
      questions?: PiaQuestion[]
    }
    safeguards: {
      name?: string
      safeguards?: PiaListOption[]
      safeguardProperties?: FieldProperty[]
      safeguardsLabel?: string
      questions?: PiaQuestion[]
    }
    transfers: {
      name?: string
      isCrossBorder: boolean
      crossBorderProperties: FieldProperty[]
      crossBorder?: {
        safeguards: PiaListOption[]
        safeguardsProperties: FieldProperty[]
        organisation: string
        organisationProperties: FieldProperty[]
        email: string
        emailProperties: FieldProperty[]
        country: string
        countryProperties: FieldProperty[]
      }[]
      isThirdParty: boolean
      thirdPartyProperties: FieldProperty[]
      thirdParty?: {
        safeguards: PiaListOption[]
        safeguardsProperties: FieldProperty[]
        name: string
        nameProperties: FieldProperty[]
        email: string
        emailProperties: FieldProperty[]
        address: string
        addressProperties: FieldProperty[]
      }[]
    }
    customTopics?: { name: string; questions: PiaQuestion[] }[]
  }
  processors: PiaReportProcessorInfo[][]
  controllers: PiaReportProcessorInfo[][]
  riskOptions?: PiaRiskOption[]
  likeLinessOptions?: PiaRiskOption[]
}
export const ACTION_FETCH_REPORT_INFO = 'pia/fetch/report-info'
export const fetchPiaReportById = createAsyncThunk(ACTION_FETCH_REPORT_INFO, async (id: string) => {
  const raw = await graphqlService.execute(queryReportById(id))
  return mapQueryReportById(raw)
})

export const ACTION_PIA_DELETE_REPORT = 'pia/deleteReport'
export const deletePiaReport = createAsyncThunk(
  ACTION_PIA_DELETE_REPORT,
  async ({ id }: { id: string }): Promise<{ id: string }> => {
    await graphqlService.execute(queryDeletePiaReport(id))
    return { id }
  }
)
export const ACTION_PIA_GENERATE_REPORT = 'pia/generateReport'
export const generatePiaReport = createAsyncThunk(
  ACTION_PIA_GENERATE_REPORT,
  async (processId: string): Promise<PiaReportPrint> => {
    const raw = await graphqlService.execute(queryGeneratePiaReport(processId))
    return mapQueryGeneratePiaReport(raw)
  }
)

const piaSlice = createSlice({
  name: 'pia',
  initialState,
  reducers: {
    setSelectedProcessOrTemplate: (state, { payload }: { payload: PiaProcess }) => {
      state.selectedTemplateOrProcess = payload
      const process = updateQuestionStatsAndReturnProcess(state.selectedTemplateOrProcess)
      state.selectedTemplateOrProcess = {
        ...process,
        riskDetails: {
          ...payload.riskDetails,
          overallRiskValue: calculateProcessRisk(payload, state.riskOptions as PiaRiskOption[])
        }
      }
    },
    updateProcess: (state, { payload }) => {
      const { key, payload: data } = payload
      if (state.selectedTemplateOrProcess?.[key]) {
        state.selectedTemplateOrProcess[key] = data
        const process = updateQuestionStatsAndReturnProcess(state.selectedTemplateOrProcess)
        state.selectedTemplateOrProcess = {
          ...process,
          riskDetails: {
            ...process.riskDetails,
            overallRiskValue: calculateProcessRisk(process, state.riskOptions as PiaRiskOption[])
          }
        }
      }
    },
    resetSelectedProcess: (state) => {
      state.selectedTemplateOrProcess = initialState.selectedTemplateOrProcess
      state.submittedTicketIds = initialState.submittedTicketIds
    },
    resetSubmittedTicket: (state) => {
      state.submittedTicketIds = initialState.submittedTicketIds
    },
    addTopic: (state, { payload }) => {
      if (state.selectedTemplateOrProcess?.questionnaire) {
        state.selectedTemplateOrProcess.questionnaire.customTopics = [
          ...(state.selectedTemplateOrProcess.questionnaire?.customTopics?.length
            ? state.selectedTemplateOrProcess.questionnaire?.customTopics
            : []),
          payload
        ]
      }
    },
    updateTopic: (state, { payload }: { payload: { key: string; payload: any } }) => {
      if (state.selectedTemplateOrProcess?.questionnaire) {
        const { key, payload: data } = payload
        state.selectedTemplateOrProcess.questionnaire = {
          ...state.selectedTemplateOrProcess.questionnaire,
          [key]: {
            ...state.selectedTemplateOrProcess.questionnaire[key],
            ...data
          }
        }
        const process = updateQuestionStatsAndReturnProcess(state.selectedTemplateOrProcess)
        state.selectedTemplateOrProcess = {
          ...process,
          riskDetails: {
            ...process.riskDetails,
            overallRiskValue: calculateProcessRisk(process, state.riskOptions as PiaRiskOption[])
          }
        }
      }
    },

    updateCustomTopic: (state, { payload }: { payload: { key: string; payload: any } }) => {
      if (state.selectedTemplateOrProcess?.questionnaire) {
        const { key, payload: data } = payload
        const newCustomTopics = state.selectedTemplateOrProcess.questionnaire.customTopics?.map(
          (topic) => {
            if (topic.id === key) {
              return { ...topic, ...data }
            }
            return topic
          }
        )
        state.selectedTemplateOrProcess.questionnaire.customTopics = newCustomTopics
        const process = updateQuestionStatsAndReturnProcess(state.selectedTemplateOrProcess)
        state.selectedTemplateOrProcess = {
          ...process,
          riskDetails: {
            ...process.riskDetails,
            overallRiskValue: calculateProcessRisk(process, state.riskOptions as PiaRiskOption[])
          }
        }
      }
    },

    addProcessor: (state, { payload }) => {
      if (state.selectedTemplateOrProcess?.processDetails?.processingActivityDetails) {
        state.selectedTemplateOrProcess.processDetails.processingActivityDetails = [
          ...state.selectedTemplateOrProcess.processDetails.processingActivityDetails,
          payload
        ]
      }
      state.selectedTemplateOrProcess = updateQuestionStatsAndReturnProcess(
        state.selectedTemplateOrProcess
      )
    },
    deleteProcessor: (state, { payload }) => {
      if (state.selectedTemplateOrProcess?.processDetails?.processingActivityDetails) {
        const newDetails = [
          ...state.selectedTemplateOrProcess.processDetails.processingActivityDetails
        ]
        newDetails.splice(payload, 1)
        state.selectedTemplateOrProcess.processDetails.processingActivityDetails = newDetails
      }
      state.selectedTemplateOrProcess = updateQuestionStatsAndReturnProcess(
        state.selectedTemplateOrProcess
      )
    },

    updateManagerType: (
      state,
      { payload }: { payload: { order: number; managerType: string } }
    ) => {
      if (state.selectedTemplateOrProcess?.processDetails?.processingActivityDetails) {
        const { order, managerType } = payload
        state.selectedTemplateOrProcess.processDetails.processingActivityDetails[
          order
        ].managerType = managerType
      }
    },

    updateProcessorDetailsByOrder: (
      state,
      { payload }: { payload: { order: number; payload: ProcessingActivityManagerDetails[] } }
    ) => {
      if (state.selectedTemplateOrProcess?.processDetails?.processingActivityDetails) {
        const { order, payload: data } = payload
        state.selectedTemplateOrProcess.processDetails.processingActivityDetails[order] = state
          .selectedTemplateOrProcess.processDetails.processingActivityDetails[order] || {
          details: []
        }
        state.selectedTemplateOrProcess.processDetails.processingActivityDetails[
          order
        ].details = data
      }
      state.selectedTemplateOrProcess = updateQuestionStatsAndReturnProcess(
        state.selectedTemplateOrProcess
      )
    },

    addTransferDetails: (state, { payload }) => {
      const { payload: data, type } = payload
      if (state.selectedTemplateOrProcess?.questionnaire?.transfers?.[type]) {
        state.selectedTemplateOrProcess.questionnaire.transfers[type].details = [
          ...state.selectedTemplateOrProcess.questionnaire.transfers[type].details,
          data
        ]
      }
      const process = updateQuestionStatsAndReturnProcess(state.selectedTemplateOrProcess)
      state.selectedTemplateOrProcess = {
        ...process,
        riskDetails: {
          ...process.riskDetails,
          overallRiskValue: calculateProcessRisk(process, state.riskOptions as PiaRiskOption[])
        }
      }
    },

    deleteTransferDetails: (state, { payload }) => {
      const { index, type } = payload
      if (state.selectedTemplateOrProcess?.questionnaire?.transfers?.[type]) {
        const newDetails = [
          ...state.selectedTemplateOrProcess.questionnaire.transfers[type].details
        ]
        newDetails.splice(index, 1)
        state.selectedTemplateOrProcess.questionnaire.transfers[type].details = newDetails
      }
      const process = updateQuestionStatsAndReturnProcess(state.selectedTemplateOrProcess)
      state.selectedTemplateOrProcess = {
        ...process,
        riskDetails: {
          ...process.riskDetails,
          overallRiskValue: calculateProcessRisk(process, state.riskOptions as PiaRiskOption[])
        }
      }
    },

    updateTrasferDetailsByOrder: (
      state,
      {
        payload
      }: {
        payload: {
          order: number
          type: PiaTransferDetailsTypeEnum
          payload: CrossBorderTransferDetails | ThirdPartyTransferDetails
        }
      }
    ) => {
      const { order, payload: data, type } = payload
      if (state.selectedTemplateOrProcess?.questionnaire?.transfers?.[type]) {
        state.selectedTemplateOrProcess.questionnaire.transfers[type].details[order] = {
          ...state.selectedTemplateOrProcess.questionnaire.transfers[type].details[order],
          ...data
        }
      }
      const process = updateQuestionStatsAndReturnProcess(state.selectedTemplateOrProcess)
      state.selectedTemplateOrProcess = {
        ...process,
        riskDetails: {
          ...process.riskDetails,
          overallRiskValue: calculateProcessRisk(process, state.riskOptions as PiaRiskOption[])
        }
      }
    },

    deleteCustomTopic: (state, { payload }) => {
      if (state.selectedTemplateOrProcess?.questionnaire) {
        state.selectedTemplateOrProcess.questionnaire.customTopics = state.selectedTemplateOrProcess.questionnaire.customTopics?.filter(
          (topic) => topic.id !== payload.topicId
        )
      }
      const process = updateQuestionStatsAndReturnProcess(state.selectedTemplateOrProcess)
      state.selectedTemplateOrProcess = {
        ...process,
        riskDetails: {
          ...process.riskDetails,
          overallRiskValue: calculateProcessRisk(process, state.riskOptions as PiaRiskOption[])
        }
      }
    },
    setSelectedDatasourceDataProperties: (state, { payload }) => {
      state.selectedDatasourceDataProperties = payload
    },
    resetSelectedDatasourceDataProperties: (state) => {
      state.selectedDatasourceDataProperties = []
    },
    resetListProcesses: (state) => {
      state.processes = initialState.processes
    },
    resetListTemplates: (state) => {
      state.templates = initialState.templates
    },
    resetCreatedDatasourceDataPropertyId: (state) => {
      state.createdDatasourceDataProperty = initialState.createdDatasourceDataProperty
    },
    resetSelectedTemplate: (state) => {
      state.selectedTemplate = initialState.selectedTemplate
    },
    setPiaCollaboration: (state, { payload }) => {
      state.collaboration = payload
    },
    resetPiaCollaboration: (state) => {
      state.collaboration = initialState.collaboration
    },
    resetPiaTickets: (state) => {
      state.tickets = initialState.tickets
    },
    resetProcessOverview: (state) => {
      state.processOverview = initialState.processOverview
    },
    resetPiaFieldOptions: (state) => {
      state.optionLists = initialState.optionLists
    },
    setShowErrors: (state, { payload }) => {
      state.showErrors = payload
    },
    resetPiaCollaborationDetails: (state) => {
      state.collaborationDetails = initialState.collaborationDetails
    },
    resetPiaReport: (state) => {
      state.report = initialState.report
    },
    updateProcessRiskDetails: (state, { payload }) => {
      state.selectedTemplateOrProcess = {
        ...state.selectedTemplateOrProcess,
        riskDetails: {
          ...state.selectedTemplateOrProcess?.riskDetails,
          [payload.key]: payload.value
        }
      } as PiaProcess
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchPiaTemplates.fulfilled, (state, { payload }) => {
      state.templates.list = payload.list as PiaProcess[]
      state.templates.total = payload.total
    })
    builder.addCase(fetchPiaProcesses.fulfilled, (state, { payload }) => {
      state.processes.list = payload.list as PiaProcess[]
      state.processes.total = payload.total
    })
    builder.addCase(fetchPiaProcessCards.fulfilled, (state, { payload }) => {
      state.processes.groups = payload.list
      state.processes.groupsTotal = payload.total
    })
    builder.addCase(fetchPiaRiskOptions.fulfilled, (state, { payload }) => {
      state.riskOptions = payload
    })
    builder.addCase(fetchPiaLikelinessOptions.fulfilled, (state, { payload }) => {
      state.likelinessOptions = payload
    })
    builder.addCase(fetchTotalPiaProcesses.fulfilled, (state, { payload }) => {
      state.totalProcesses = payload
    })
    builder.addCase(fetchPiaTemplateOrProcessById.fulfilled, (state, { payload }) => {
      state.selectedTemplateOrProcess = updateQuestionStatsAndReturnProcess(payload)
    })
    builder.addCase(fetchPiaCollaborationInfoById.fulfilled, (state, { payload }) => {
      state.selectedTemplateOrProcess = updateQuestionStatsAndReturnProcess(payload)
    })
    builder.addCase(fetchAssociatedCollaborationTickets.fulfilled, (state, { payload }) => {
      if (state.selectedTemplateOrProcess) {
        state.collaborators = getCompactCollaboratorDetails(payload)
        state.selectedTemplateOrProcess = collateCollaboratorResponses(
          state.selectedTemplateOrProcess,
          payload,
          state.riskOptions
        )
        state.submittedTicketIds = payload?.edges
          ?.filter(({ node }) => node.status === TicketStatus.Submitted)
          ?.map(({ node }) => node?.id)
        state.selectedTemplateOrProcess = updateQuestionStatsAndReturnProcess(
          state.selectedTemplateOrProcess
        )
      }
    })
    builder.addCase(fetchPiaTemplateById.fulfilled, (state, { payload }) => {
      state.selectedTemplate = payload
    })
    builder.addCase(createPiaProcess.fulfilled, (state, { payload }) => {
      if (state.selectedTemplateOrProcess) {
        state.selectedTemplateOrProcess = {
          ...state.selectedTemplateOrProcess,
          id: payload.processId
        }
      }
    })
    builder.addCase(clonePiaProcess.fulfilled, (state, { payload }) => {
      state.selectedTemplateOrProcess = updateQuestionStatsAndReturnProcess(payload)
    })
    builder.addCase(fetchPiaProcessReviewSchedules.fulfilled, (state, { payload }) => {
      state.optionLists.reviewSchedules = payload
    })
    builder.addCase(fetchPiaProcessOwners.fulfilled, (state, { payload }) => {
      state.optionLists.processOwners = payload
    })
    builder.addCase(fetchPurposes.fulfilled, (state, { payload }) => {
      state.optionLists.purposes = payload
    })
    builder.addCase(fetchPiaProcessGroups.fulfilled, (state, { payload }) => {
      state.optionLists.processGroups = payload
    })
    builder.addCase(fetchDurations.fulfilled, (state, { payload }) => {
      state.optionLists.durations = payload
    })
    builder.addCase(fetchLawfulBasis.fulfilled, (state, { payload }) => {
      state.optionLists.lawfulBasis = payload
    })
    builder.addCase(fetchSubjectCategories.fulfilled, (state, { payload }) => {
      state.optionLists.subjectCategories = payload
    })
    builder.addCase(fetchSpecialSubjectCategories.fulfilled, (state, { payload }) => {
      state.optionLists.subjectSpecialCategories = payload
    })
    builder.addCase(fetchPiaSafetyMeasures.fulfilled, (state, { payload }) => {
      state.optionLists.safetyMeasures = payload
    })
    builder.addCase(fetchPiaAttributes.fulfilled, (state, { payload }) => {
      state.optionLists.attributeSets = payload.attributeSets
      state.optionLists.attributes = payload.attributes
    })
    builder.addCase(fetchPiaOverviewAttributeInstance.fulfilled, (state, { payload }) => {
      state.optionLists.chartAttributes = payload
    })
    builder.addCase(createRetentionPeriod.fulfilled, (state, { payload }) => {
      if (state.selectedTemplateOrProcess?.questionnaire?.dataRetention?.details?.length) {
        state.selectedTemplateOrProcess.questionnaire.dataRetention = {
          ...state.selectedTemplateOrProcess.questionnaire.dataRetention,
          details: [
            ...state.selectedTemplateOrProcess.questionnaire.dataRetention.details,
            payload
          ],
          dataRetentionProperties:
            state.selectedTemplateOrProcess.questionnaire.dataRetention.dataRetentionProperties?.filter(
              ({ key }) => key !== 'overallRiskValue'
            ) || []
        }
      } else if (state.selectedTemplateOrProcess?.questionnaire?.dataRetention) {
        state.selectedTemplateOrProcess.questionnaire.dataRetention.details = [payload]
      }
      const process = updateQuestionStatsAndReturnProcess(state.selectedTemplateOrProcess)
      state.selectedTemplateOrProcess = {
        ...process,
        riskDetails: {
          ...process.riskDetails,
          overallRiskValue: calculateProcessRisk(process, state.riskOptions || [])
        }
      }
    })
    builder.addCase(bulkCreateOrUpdateDatasourceDataProperty.fulfilled, (state, { payload }) => {
      if (state?.selectedDatasourceDataProperties) {
        const uniqValues = uniq(
          state.selectedDatasourceDataProperties
            .filter((prop) => !!prop.name)
            .map((prop) => prop.name)
        )
        state.selectedDatasourceDataProperties = uniqValues.map((val, index) => ({
          id: payload?.ids?.[index],
          name: val
        }))
      }
    })
    builder.addCase(createDatasourceDataProperty.fulfilled, (state, { payload }) => {
      state.createdDatasourceDataProperty = { id: payload.ids?.[0], type: payload.fieldType }
    })
    builder.addCase(updateTicketsStatus.fulfilled, (state) => {
      state.submittedTicketIds = initialState.submittedTicketIds
    })
    builder.addCase(fetchPiaProcessesCsv.fulfilled, (state, { payload }) => {
      state.exportProcesses = payload
    })
    builder.addCase(fetchProcessingStages.fulfilled, (state, { payload }) => {
      state.optionLists.processingStages = payload
    })
    builder.addCase(fetchProcessOverview.fulfilled, (state, { payload }) => {
      state.processOverview = payload
    })
    builder.addCase(fetchCollaborationDetails.fulfilled, (state, { payload }) => {
      state.collaborationDetails = payload
    })
    builder.addCase(fetchPiaTickets.fulfilled, (state, { payload }) => {
      state.tickets.list = payload.list
      state.tickets.total = payload.total
    })
    builder.addCase(fetchPiaReportById.fulfilled, (state, { payload }) => {
      state.report = payload
    })
    builder.addCase(generatePiaReport.fulfilled, (state, { payload }) => {
      state.report = payload
    })
  }
})

export const {
  resetListProcesses,
  resetListTemplates,
  setSelectedProcessOrTemplate,
  updateProcess,
  resetSelectedProcess,
  addTopic,
  updateTopic,
  updateCustomTopic,
  addProcessor,
  deleteProcessor,
  updateManagerType,
  updateProcessorDetailsByOrder,
  addTransferDetails,
  deleteTransferDetails,
  updateTrasferDetailsByOrder,
  deleteCustomTopic,
  setSelectedDatasourceDataProperties,
  resetSelectedDatasourceDataProperties,
  resetCreatedDatasourceDataPropertyId,
  resetSelectedTemplate,
  resetSubmittedTicket,
  setPiaCollaboration,
  resetPiaCollaboration,
  resetPiaTickets,
  resetProcessOverview,
  resetPiaFieldOptions,
  setShowErrors,
  resetPiaCollaborationDetails,
  resetPiaReport,
  updateProcessRiskDetails
} = piaSlice.actions

export default piaSlice.reducer
