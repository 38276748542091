import { Cookie, ProblematicDomainGroup, ProblematicCategoryGroup } from './domainsSlice'

export function checkForCookieCollision(
  cookies: Cookie[]
): {
  cookieCollision: boolean
  problematicCategories: string[]
  problematicDomains: string[]
  problematicGroupsByDomains: ProblematicDomainGroup[]
} {
  if (!cookies.length) {
    return {
      cookieCollision: false,
      problematicCategories: [],
      problematicDomains: [],
      problematicGroupsByDomains: []
    }
  }
  const domainCategoryMap: {
    [key: string]: Set<Cookie['cookieCategoryId']>
  } = {}

  const problematicCategories = new Set<string>()
  const problematicDomains = new Set<string>()

  for (const cookie of cookies) {
    if (cookie.domain) {
      if (!domainCategoryMap[cookie.domain]) {
        domainCategoryMap[cookie.domain] = new Set<string>()
      }
      if (cookie.cookieCategoryId) {
        domainCategoryMap[cookie.domain].add(cookie.cookieCategoryId)
      }
      if (domainCategoryMap[cookie.domain].size > 1) {
        if (cookie.cookieCategoryId) {
          domainCategoryMap[cookie.domain].forEach((categoryId) => {
            if (categoryId) {
              problematicCategories.add(categoryId)
            }
          })
        }
        problematicDomains.add(cookie.domain)
      }
    }
  }

  const cookieCollision = problematicCategories.size > 0 || problematicDomains.size > 0

  const problematicGroupsByDomains: ProblematicDomainGroup[] = []

  for (const domain of problematicDomains) {
    const cookiesByDomain = cookies.filter((cookie) => cookie.domain === domain)
    const domainItem: ProblematicDomainGroup = {
      domain,
      categories: []
    }

    const uniqCategories = cookiesByDomain
      .map((cookie) => cookie.cookieCategoryId)
      .filter((categoryId) => categoryId !== undefined)

    for (const categoryId of new Set(uniqCategories as string[])) {
      const categoryItem: ProblematicCategoryGroup = {
        categoryId,
        cookies: cookiesByDomain.filter((cookie) => cookie.cookieCategoryId === categoryId)
      }
      domainItem.categories.push(categoryItem as ProblematicCategoryGroup)
    }

    problematicGroupsByDomains.push(domainItem)
  }

  return {
    cookieCollision,
    problematicCategories: [...problematicCategories],
    problematicDomains: [...problematicDomains],
    problematicGroupsByDomains
  }
}

export const cookieConsentCountries = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo, Democratic Republic of the',
  'Congo, Republic of the',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Korea, North',
  'Korea, South',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'North Macedonia',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe'
]
