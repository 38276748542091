import {
  DocumentLabelSet,
  DocumentLabelProvider,
  DOCUMENT_LABEL_PROVIDERS,
  DocumentLabelProviderConfigurations,
  DocumentLabel,
  DocumentLabelsParams,
  DocumentLabelSaveParams,
  DocumentLabelDetectionTypes,
  Label,
  UpdateLabelSetParams,
  UpdateLabelParams,
  CreateLabelParams,
  DocumentLabelOperatorTypes,
  DocumentLabelConditionalGroup,
  DeleteLabelParams,
  LabelsWidgetParams
} from './documentLabelsSlice'
import { AttributeSet, AttributeSetAttribute } from '../attributes/attributesSlice'
import { ATTRIBUTE_SET_TYPES } from '../../constants'
import { randHex } from '../../utils/mathUtils'
import gqlast from '../../utils/filtersUtil'
import { gql } from 'graphql-request'

// utils
const getFragmentDefinition = (params: {
  lightBeamLabelOperator?: DocumentLabelOperatorTypes
  lightBeamLabelGroups?: DocumentLabelConditionalGroup[]
}) => {
  const { lightBeamLabelOperator, lightBeamLabelGroups } = params
  if (!lightBeamLabelOperator || !lightBeamLabelGroups?.length) {
    return ''
  }

  const groupsFragment = lightBeamLabelGroups
    .map((group) => {
      if (group.type === DocumentLabelDetectionTypes.attributeSet) {
        return `
          {
            type: ${DocumentLabelDetectionTypes.attributeSet},
            operator: ${group.operator},
            attributeSetId: "${group.attributeSetId}"
          }`
      } else {
        return `
      {
        type: ${DocumentLabelDetectionTypes.fileClassificationSet},
        operator: ${group.operator},
        fileClassificationSet: [${group.fileClassificationSet?.map(
          (set) => `{ fileClass: "${set.fileClass}", fileSubClass: "${set.fileSubClass}" }`
        )}]
      }`
      }
    })
    .join(',')

  return groupsFragment
    ? `definition: {
        operator: ${lightBeamLabelOperator},
        groups: [${groupsFragment}]
      }`
    : ''
}

// queries
export const queryLabelSets = (): string => {
  return gql`
    {
      documentLabelSet {
        edges {
          node {
            id
            name
            id
            type
            createdAt
            updateTimestamp
            mappedLabelSet {
              edges {
                node {
                  id
                  name
                }
              }
            }
            labels {
              edges {
                node {
                  id
                  name
                  description
                  mappedLabel {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}
export const mapQueryLabelSets = (raw: any): DocumentLabelSet[] => {
  return raw.documentLabelSet?.edges?.map(({ node: labelSet }) => ({
    id: labelSet?.id || '',
    name: labelSet?.name || '',
    description: labelSet?.description || '',
    type: labelSet?.type || '',
    mappedLabelSetId: labelSet?.mappedLabelSet?.edges?.[0]?.node?.id || '',
    mappedLabelSetName: labelSet?.mappedLabelSet?.edges?.[0]?.node?.name || '',
    labelsCount: labelSet?.labels?.edges?.length,
    labels: labelSet?.labels?.edges?.map(({ node }) => ({
      name: node.name,
      id: node.id,
      mappedId: node?.mappedLabel?.edges?.[0]?.node?.id
    })),
    createdAt: labelSet.createdAt,
    updatedAt: labelSet.updateTimestamp
  }))
}

// TODO: MIP. Not working: suffix, mappedLabelName
export const queryLabels = (params?: DocumentLabelsParams): string => {
  const idFragment = params?.labelSetId ? `labelSetIds: "${params.labelSetId}"` : ''
  return gql`
    {
      documentLabelSet(first: 999, ${idFragment}) {
        edges {
          node {
            labels {
              edges {
                node {
                  id
                  name
                  type
                  priority
                  description
                  active
                  uuid
                  labelSetId
                  color
                  objectCount
                  datasourceCounts {
                    count
                    datasource {
                      edges {
                        node {
                          id
                          type
                          name
                        }
                      }
                    }
                  }
                  mappedLabel {
                    edges {
                      node {
                        id
                        name
                        labelSetId
                        labelSetName
                      }
                    }
                  }
                  labelSetName
                  labelDefinition {
                    operator
                    groups {
                      type
                      operator
                      attributeSetId
                      fileClassificationSet {
                        fileClass
                        fileSubClass
                      }
                    }
                  }
                  createdAt
                  updateTimestamp
                }
              }
            }
          }
        }
      }
    }
  `
}
export const mapQueryLabels = (raw: any): DocumentLabel[] => {
  return raw.documentLabelSet.edges
    .map(({ node: set }) => {
      return set?.labels?.edges?.map(({ node: label }) => {
        return {
          id: label?.id || '',
          name: label?.name || '',
          type: label?.type || '',
          priority: label?.priority,
          suffix: label?.suffix || '',
          description: label?.description || '',
          active: label?.active,
          uuid: label?.uuid || '',
          labelSetId: label?.labelSetId || '',
          labelSetName: label?.labelSetName || '',
          mappedLabelId: label.mappedLabel.edges?.[0]?.node?.id || '',
          mappedLabelName: label.mappedLabel.edges?.[0]?.node?.name || '',
          mappedLabelSetId: label.mappedLabel.edges?.[0]?.node?.labelSetId || '',
          mappedLabelSetName: label.mappedLabel.edges?.[0]?.node?.labelSetName || '',
          labelDefinition: label?.labelDefinition || null,
          objectCount: label?.objectCount || 0,
          datasourceCount: label?.datasourceCounts?.length,
          datasources:
            label?.datasourceCounts?.map((item) => {
              const { count, datasource } = item
              return {
                objectCount: count,
                type: datasource?.edges?.[0]?.node?.type,
                name: datasource?.edges?.[0]?.node?.name,
                id: datasource?.edges?.[0]?.node?.id
              }
            }) || [],
          color: label?.color,
          createdAt: label.createdAt,
          updatedAt: label.updateTimestamp
        }
      })
    })
    .flat()
}

export const mutationSaveDocumentLabelProvider = (params: DocumentLabelProvider) => {
  let thirdPartyConfigurationFragment = ''
  if (params.type === DOCUMENT_LABEL_PROVIDERS.mip) {
    const { tenantId = '', clientId = '', clientSecret = '' } =
      params?.[DocumentLabelProviderConfigurations.mip] || {}

    thirdPartyConfigurationFragment = `${DocumentLabelProviderConfigurations.mip}: {
          tenantId: "${tenantId}"
          clientId: "${clientId}"
          clientSecret:  "${clientSecret}"
        }`
  } else if (params.type === DOCUMENT_LABEL_PROVIDERS.google) {
    const { accountJson = '', delegatedCredential = '', accountType = '' } =
      params?.[DocumentLabelProviderConfigurations.google] || {}

    thirdPartyConfigurationFragment = `${DocumentLabelProviderConfigurations.google}: {
          accountJson: "${accountJson}"
          delegatedCredential: "${delegatedCredential}"
          accountType: ${accountType.toUpperCase()}
        }`
  }

  return gql`
    mutation {
      createDocumentLabelSet(
        clientMutationId: "1"
        documentLabelSetData: {
          name: "${params.name}"
          type: ${params.type}
          thirdPartyConfiguration: {
            ${thirdPartyConfigurationFragment}
          }
        }
      ) {
        clientMutationId
        documentLabelSetId
      }
    }
  `
}

export const queryDocumentLabelAttributes = (id?: string): string => {
  const idFragment = id ? `id: "${id}"` : ''

  return gql`
    {
      attributeSet(first: 999, ${idFragment}) {
        edges {
          node {
            id
            name
            type
            enabled
            systemDefined
            attributes {
              count
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `
}
export const mapQueryDocumentLabelAttributes = (
  raw: any
): { attributeSets: AttributeSet[]; attributes: AttributeSetAttribute[] } => {
  try {
    const distinctAttributes = {}
    const attributeSets = raw.attributeSet.edges.map(({ node: attrSet }) => {
      const attributes =
        attrSet.attributes?.edges?.map(({ node: attr }) => {
          const attribute = {
            id: attr.id,
            name: attr.name,
            setId: attrSet.id
          }
          distinctAttributes[attr.id] = attribute
          return attribute
        }) || []

      return {
        id: attrSet.id,
        name: attrSet.name,
        enabled: attrSet.enabled,
        description: attrSet.description || '',
        type: attrSet.type || '',
        attributesCount: attrSet.attributes?.count || 0,
        attributes
      }
    })

    return { attributeSets, attributes: Object.values(distinctAttributes) }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const mutationSaveCustomAttributeSet = (attributeIds: string[]) => {
  return gql`
    mutation {
      createAttributeSet(
        clientMutationId: "1"
        attributeSetData: {
          name: "Attributes-Custom-Set-${randHex(6)}"
          type: ${ATTRIBUTE_SET_TYPES.CUSTOM}
          attributes: ${JSON.stringify(attributeIds)}
        }
      ) {
        clientMutationId
        attributeSet {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  `
}

export const mutationSaveLightbeamLabel = (params: CreateLabelParams) => {
  const {
    name,
    setId,
    priority,
    color,
    description,
    mappedLabelId,
    lightBeamLabelOperator,
    lightBeamLabelGroups
  } = params

  const definitionFragment = getFragmentDefinition({ lightBeamLabelOperator, lightBeamLabelGroups })
  const mappedLabelIdFragment = mappedLabelId ? `mappedLabelId: "${mappedLabelId}"` : ''

  return `mutation {
    createDocumentLabel(
        clientMutationId: "1",
        documentLabelData: {
          type: ${DOCUMENT_LABEL_PROVIDERS.lightBeam}
          name: "${name}"
          description: "${description}"
          documentLabelSetId: "${setId}"
          priority: ${priority}
          color: "${color ?? ''}"
          ${mappedLabelIdFragment}
          ${definitionFragment}
        }
      ){ clientMutationId, documentLabelSetId }
    }`
}

export const mutationUpdateLabel = (params: UpdateLabelParams) => {
  const { lightBeamLabelOperator, lightBeamLabelGroups } = params

  const definition = getFragmentDefinition({ lightBeamLabelOperator, lightBeamLabelGroups })

  return `mutation {
    updateDocumentLabel(
        clientMutationId: "1",
        documentLabelData: {
          name: "${params.name}"
          description: "${params.description}"
          labelSetId: "${params.id}"
          labelId: "${params.labelId}"
          color: "${params.color}"
          priority: ${params.priority}
          ${definition}
        }
      ){ documentLabelSetId }
    }`
}

export const mutationSaveLabelDefinition = (params: UpdateLabelParams) => {
  const { lightBeamLabelOperator, lightBeamLabelGroups } = params

  const definition = getFragmentDefinition({ lightBeamLabelOperator, lightBeamLabelGroups })

  return `mutation {
    defineThirdPartyDocumentLabel(
        clientMutationId: "1",
        defineDocumentLabelData: {
          documentLabelSetId: "${params.id}"
          documentLabelId: "${params.labelId}"
          ${definition}
        }
      ){ documentLabelSetId }
    }`
}

export const mutationAttachLabel = (params: { labels: Label[]; docId: string }) => {
  const queryTransformed = gqlast`mutation {
    attachDocumentLabel(clientMutationId: "1",
    documentId: ${params.docId},
      objectLabelInput: ${params.labels}
    )
    {
      clientMutationId
    }
  }`
  return gql`
    ${queryTransformed}
  `
}

export const mutationDeleteLabel = (params: DeleteLabelParams) => {
  return `mutation x {
    deleteDocumentLabel (clientMutationId: 1, documentLabelData: {labelSetId: "${params.labelSetId}", labelId: "${params.labelId}"  }) {
      documentLabelSetId
    }
  }`
}

export const mutationSaveLightbeamLabelSet = (params: { name: string }) => {
  return `mutation {
    createDocumentLabelSet(
      clientMutationId: "1",
      documentLabelSetData: {
        name: "${params.name}"
        type: ${DOCUMENT_LABEL_PROVIDERS.lightBeam}
        labels: [
        ]
      }
    ) {
      documentLabelSetId
    }
  }`
}

export const mutationUpdateLabelSet = (params: UpdateLabelSetParams) => {
  return ` mutation {
    updateDocumentLabelSet (
      clientMutationId: "1",
			documentLabelSetData: {
        labelSetId:"${params.setId}"
        name: "${params.name}"

      }
    ) {
      documentLabelSetId
    }
  }`
}

export const mutationSaveCustomLightBeamLabelSet = (params: DocumentLabelSaveParams) => {
  const { lightBeamLabelName, lightBeamLabelOperator, lightBeamLabelGroups } = params

  const definition = getFragmentDefinition({ lightBeamLabelOperator, lightBeamLabelGroups })

  return `mutation {
    createDocumentLabelSet(
      clientMutationId: "1",
      documentLabelSetData: {
        name: "LightBeam-Document-Labels-Set-${randHex(6)}"
        type: ${DOCUMENT_LABEL_PROVIDERS.lightBeam}
        labels: [
          {
            name: "${lightBeamLabelName}"
            type: ${DOCUMENT_LABEL_PROVIDERS.lightBeam}
            priority: 1
            active: true,
            ${definition}
          }
        ]
      }
    ) {
      clientMutationId
      documentLabelSetId
    }
  }`
}

export const mutationMapDocumentLabels = (params: { labelId: string; mappedLabelId: string }) => {
  return `mutation {
    mapDocumentLabel (
      clientMutationId: "1",
      documentLabelData: {
        labelMappings: [
          {
            labelId: "${params.labelId}",
            mappedLabelId: "${params.mappedLabelId}"
          }
        ]
      }
    ) { clientMutationId  }
  }`
}

export const queryLabelsWidget = ({ datasourceIds }: LabelsWidgetParams): string => {
  const dsIdParam = datasourceIds ? `(datasourceIds: "${datasourceIds}")` : ''

  return gql`
    {
      documentLabelSet${dsIdParam} {
        edges {
          node {
            labels${dsIdParam} {
              count
              edges {
                node {
                  id
                  name
                  type
                  objectCount
                }
              }
            }
          }
        }
      }
    }
  `
}
export const mapQueryLabelsWidget = (raw: any): DocumentLabel[] => {
  return raw.documentLabelSet.edges
    .map(({ node: set }) => {
      return set?.labels?.edges?.map(({ node: label }) => {
        return {
          id: label?.id || '',
          name: label?.name || '',
          type: label?.type || '',
          objectCount: label?.objectCount || 0
        }
      })
    })
    .flat()
}
