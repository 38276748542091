import {
  mapQueryPolicyFilters,
  queryApplyFilterCondtion,
  mapApplyFilterCondtion,
  queryProfileMapData,
  mapQueryProfileMapData,
  queryCreateFilter,
  queryPolicyTypes,
  queryPolicyById,
  mapQueryPolicyTypes,
  queryPoliciesListByType,
  mapQueryPoliciesListByType,
  queryCreatePolicy,
  queryCreateDatasourceFilter,
  mapQueryCreateDatasourceFilter,
  mapQueryPolicyById,
  queryPolicyFilterByIds,
  mapQueryPolicyFilterByIds,
  queryDataSources,
  queryUpdateDatasourceFilter,
  queryUpdatePolicy,
  queryDeletePolicy,
  queryProfileFilters,
  queryUpdateProfileMap,
  queryAlertObjectsImpacted,
  mapQueryAlertObjectsImpacted,
  queryPolicyAlerts,
  mapQueryPolicyAlerts,
  mapQueryPolicyAlertCards,
  queryPolicyAlertCards,
  queryExternalUsers,
  mapQueryExternalUsers,
  queryEntitiesImpacted,
  mapQueryEntitiesImpacted,
  queryRuleSetDetails,
  mapQueryRuleSetDetails,
  queryPolicyAlertsTotals,
  mapQueryPolicyAlertsTotals,
  queryAlertProjectsImpacted,
  mapQueryAlertProjectsImpacted,
  queryAlertEmailRecipientsWithSenders,
  mapQueryAlertEmailRecipientsWithSenders,
  queryCreateAttributeSetFilter,
  queryPoliciesTypeCards,
  mapQueryRulesets,
  queryRulesets,
  mapQueryPoliciesTypeCards,
  queryUpdateAttributeSetFilter,
  queryPolicyDetailById,
  mapQueryPolicyDetailById,
  queryAlertAwsBuckets,
  mapQueryAlertAwsBuckets,
  queryAlertDriveFolders,
  mapQueryAlertDriveFolders,
  mapQueryCreateAutomationAction,
  queryCreateAutomationAction,
  queryRulesetsForPrint,
  mapQueryRulesetsForPrint,
  queryCreateDocumentLabelSetsFilter,
  queryUpdateDocumentLabelSetFilter,
  queryAlertAuditLogs,
  mapQueryAlertAuditLogs,
  queryAlertObjectsGroups,
  mapQueryAlertObjectsGroups,
  queryReassignAlert,
  queryResolveAlert,
  queryNoScanAlert,
  queryPermitAlert,
  queryMuteAlert,
  queryUnmuteAlert,
  queryRuleSetFilterList,
  mapQueryRuleSetFilterList,
  queryDataSourcesbyFilterIds,
  mapQueryDataSourcesbyFilterIds,
  mutationCreateDurationFilter,
  queryUpdateDurationFilter,
  queryDeleteAlertObject,
  mutationCreateDocumentLabelFilter,
  mutationUpdateDocumentLabelFilter,
  mutationUpdateAlertAssignment,
  queryMailboxFilterValues,
  mapQueryMailboxFilterValues,
  queryAlertAuditLogDetails,
  mapQueryAlertAuditLogDetails,
  queryRulesetAttributes,
  mapQueryRulesetAttributes,
  mutationCreateDocumentFilter,
  mutationCreateAccessFilter,
  mutationUpdateDocumentFilter,
  mutationUpdateAccessFilter,
  queryAlertActionHistory,
  mapQueryAlertActionHistory,
  mutationRevokeObjectAccess,
  mutationSaveArchivalLocation,
  mutationArchiveSubAlerts,
  mutationApplySubAlertLegalHold,
  queryAuditLogActionHistory,
  mapAuditLogActionHistory
} from './queries'
import { CriteriaOperatorTypes, Ruleset } from './v2/constants'
import { defaultSortParams, getSortDirection, SortParams } from '../../utils/sortUtil'
import service from '../../services/api/apiService'
import {
  IPolicyType,
  IGetPolicyAlertsParams,
  IPolicyAlert,
  IPolicyFilter,
  IFilteredFileInfo,
  IProfileMapData,
  ICreateFilterParams,
  IProfileMapColumn,
  IApplyFilterParams,
  IPageInfo,
  ICreatePolicyParams,
  ICreateDatasourceFilterParams,
  IPolicy,
  IPolicyFilterState,
  IUpdateFilter,
  IUpdatePolicyParams,
  IDeletePolicyParams,
  IPolicyListByTypeParam,
  IAlertObjectsImpacted,
  SORT_ORDER,
  RulsetInfo,
  FilterParams,
  IPolicyTypeForPrint,
  ICreateDocumentLabelSetFilterParams,
  PromiseResult,
  DownloadListParams,
  DocumentLabelGroup,
  UnstructuredScanConditions
} from '../../interfaces'
import graphqlService from '../../services/graphqlService'
import { FolderInfo } from '../../services/graphqlSchemaTypes'
import { COLUMN_CHANGES } from '../columns/columnsSlice'
import {
  ACCESS_CONTROL_TYPES,
  ALERT_ACTIONS,
  ALERT_ASSIGMENT_OPTIONS,
  ALERT_ID,
  ALERT_STATUS,
  DATA_SOURCE_ID,
  DATA_SOURCE_TYPE,
  DATA_SOURCE_TYPES,
  EXCESSIVE_ACCESS_TYPES,
  MAIL_FOLDER_TYPE,
  OPEN_ACCESS_TYPES,
  OBJECT_REVOKE_ACCESS_TYPES,
  PAGE,
  PAGE_SIZE,
  POLICY_TYPES,
  REGULATIONS,
  SEVERITY_LEVEL,
  RETENTION_ATTRIBUTE_TYPES
} from '../../constants'
import { NameIdSummary } from '../ropa/ropaSliceV2'
import { RootState } from '../../rootReducer'
import { getGlobalParams } from '../../utils/urlUtil'
import { Classification } from '../../services/api/apiTypes'
import { AttributeSet, AttributeSetAttribute } from '../attributes/attributesSlice'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export interface IProfileMapColumnsData {
  list?: IProfileMapColumn[]
  total?: number
  sort: SortParams
}

interface IAlertListTotals {
  totalAll?: number
  totalPending?: number
  totalResolved?: number
}

interface IAlertGroups extends IAlertListTotals {
  list?: IPolicyAlert[]
  sort: SortParams
  type?: ALERT_STATUS[]
}

export interface IPolicyFilters {
  list?: IPolicyFilter[]
  total?: number
  sort: SortParams
  pageInfo: IPageInfo
  dataSourceTypes: DATA_SOURCE_TYPES[]
  statuses: ALERT_STATUS[]
  severity: SEVERITY_LEVEL[]
  policyTypes: POLICY_TYPES[]
}

export interface IRulsetList {
  list?: RulsetInfo[]
  total?: number
  sort: SortParams
}

export type ATTRIBUTE_CONDITION_SENSITIVITY = 'HIGH' | 'MEDIUM'

export interface AlertAttributeCondition {
  sensitivity?: ATTRIBUTE_CONDITION_SENSITIVITY
  attributeCount?: number
  conditionOperator: string | null
}

export enum AlertFilterByValues {
  all = 'all',
  user = 'user'
}

export enum ItemType {
  object = 'object',
  channel = 'channel',
  mail = 'mail',
  project = 'project',
  ticket = 'ticket',
  bucket = 'bucket',
  file = 'file'
}

interface PoliciesState {
  total: number
  atRisk: number
  vector: string
  sensitive: number
  policyTypes?: IPolicyType[]
  policyTypeForPrint?: IPolicyTypeForPrint[]
  alertGroups: IAlertGroups
  filters: IPolicyFilters
  profileMapId?: string
  profileMapColumns?: string[]
  filteredFileInfo?: IFilteredFileInfo
  profileMapData?: IProfileMapData[]
  profileMapColumnsData: IProfileMapColumnsData
  filterCreateSuccess: boolean
  rulesets: IRulsetList
  attributeSetFilterCreateId?: string
  datasourceFilterCreateId?: string
  documentLabelSetFilterCreateId?: string
  durationFilterCreateId?: string
  documentLabelFilterCreateId?: string
  documentFilterCreateId?: string
  accessFilterCreateId?: string
  datasourceActionCreateId?: string
  rulesetsDataSources?: { [key: string]: NameIdSummary[] }
  policyCreateSuccess?: boolean
  policyUpdateSuccess?: boolean
  policyDeleteSuccess: boolean
  isProfileMapDataUpdating: boolean
  dataSourceUpdateSuccess?: boolean
  attributeSetsUpdateSuccess?: boolean
  documentLabelSetUpdateSuccess?: boolean
  documentFilterUpdateSuccess?: boolean
  accessFilterUpdateSuccess?: boolean
  durationUpdateSuccess?: boolean
  documentLabelUpdateSuccess?: boolean
  policy: IPolicy | null
  policyFilters: IPolicyFilterState
  policyMapSelectedColumn?: string
  alertObjectsImpacted: {
    list: IAlertObjectsImpacted[]
    total: number
    groups?: AlertObjectsGroup[]
    filters: {
      mailboxes?: string[]
    }
  }
  alertMailRecipients?: string[]
  alertMailSenders?: string[]
  alertAwsBuckets?: string[]
  alertDriveFolders?: FolderInfo[]
  alertsStatusUpdated: boolean
  cards?: PolicyAlertCard[]
  ruleSetFilterList?: RuleSetFilterList[]
  externalUsers: {
    list?: IPolicyAlertExternalUser[]
    cards?: IPolicyAlertExternalUserGrouped[]
    total?: number
    externalUserCount?: number
    sort: SortParams
    direction: SORT_ORDER
  }
  entitiesImpacted: {
    list?: IPolicyAlertEntitiesImpacted[]
    total?: number
    sort: SortParams
    direction: SORT_ORDER
  }
  ruleSetDetails?: AlertRuleSetDetails
  alertAudiLogs?: AlertAuditLog[]
  alertAuditLogDetails?: AlertAuditLog
  auditLogActionHistory?: AuditLogActionHistory
  filesToExport?: { data: string; fileName: string }
  classifications?: Classification[]
  attributeSets?: AttributeSet[]
  attributesBySensitivity?: AttributesBySensitivity
  objectAlertActionHistory?: ObjectAlertActionHistory
}

export type FetchRuleSetParams = {
  [DATA_SOURCE_ID]: string
  first?: number
  after?: string
}

export const initialState: PoliciesState = {
  total: 0,
  atRisk: 0,
  sensitive: 0,
  vector: '',
  alertGroups: {
    sort: defaultSortParams
  },
  filters: {
    sort: defaultSortParams,
    pageInfo: {
      page: 0
    },
    dataSourceTypes: Object.values(DATA_SOURCE_TYPES),
    statuses: Object.values(ALERT_STATUS),
    severity: Object.values(SEVERITY_LEVEL),
    policyTypes: Object.values(POLICY_TYPES)
  },
  filterCreateSuccess: false,
  profileMapColumnsData: {
    sort: defaultSortParams
  },
  rulesets: {
    sort: {
      column: 'createdAt',
      direction: SORT_ORDER.DESC
    }
  },
  policyDeleteSuccess: false,
  policy: null,
  policyFilters: { filtersLoaded: false },
  profileMapId: '',
  isProfileMapDataUpdating: false,
  alertsStatusUpdated: false,
  alertObjectsImpacted: {
    list: [],
    total: 0,
    filters: {}
  },
  externalUsers: {
    sort: defaultSortParams,
    direction: SORT_ORDER.DESC
  },
  entitiesImpacted: {
    sort: defaultSortParams,
    direction: SORT_ORDER.DESC
  },
  ruleSetFilterList: []
}
export const isAlertActionPending = (status?: ALERT_STATUS) => {
  return status !== ALERT_STATUS.active
}
export const fetchPolicies = createAsyncThunk(
  'policies/fetchPolicies',
  async () => await service.getPolicies()
)
export const ACTION_FETCH_POLICY_BY_ID = 'policies/fetchPolicyById'
export const fetchPolicyById = createAsyncThunk(ACTION_FETCH_POLICY_BY_ID, async (id: string) => {
  const resultRaw = await graphqlService.execute(queryPolicyById(id))
  const { policy } = mapQueryPolicyById(resultRaw)
  const attributeCondition = getUiAttributeConditions(policy?.attributeCondition)
  return { ...policy, attributeCondition } as IPolicy
})

export const fetchPolicyDetailById = createAsyncThunk(
  'policies/fetchPolicyDetailById',
  async (id: string) => {
    const result = await graphqlService.execute(queryPolicyDetailById(id))
    return mapQueryPolicyDetailById(result)
  }
)

export const ACTION_POLICIES_RULE_SETS = 'policies/fetchAllPolicies'
export const fetchRulsets = createAsyncThunk(
  ACTION_POLICIES_RULE_SETS,
  async (params?: FetchRuleSetParams) => {
    const result = await graphqlService.execute(queryRulesets(params))
    return mapQueryRulesets(result)
  }
)
export const fetchRulsetSummaryForPrint = createAsyncThunk(
  'policies/fetchAllPoliciesForPrint',
  async () => {
    const result = await graphqlService.execute(queryRulesetsForPrint())
    return mapQueryRulesetsForPrint(result)
  }
)

export type SaveArchivalLocationParams = {
  id: string
  archiveLocation: string
}
export const ACTION_SAVE_ARCHIVE_LOCATION = 'policies/saveArchivalLocation'
export const saveArchivalLocation = createAsyncThunk(
  ACTION_SAVE_ARCHIVE_LOCATION,
  async (params: SaveArchivalLocationParams) => {
    const result = await graphqlService.execute(mutationSaveArchivalLocation(params))
    return result
  }
)

export interface FetchRulsetDataSourcesParams {
  filterIds?: string[]
  policyId?: string
}
export const fetchRulsetDataSources = createAsyncThunk(
  'policies/fetchRulsetDataSources',
  async (params: FetchRulsetDataSourcesParams[]) => {
    const dataSources = await Promise.all(
      params.map((data) => graphqlService.execute(queryDataSourcesbyFilterIds(data)))
    )
    return dataSources.map((dataSource, i) => ({
      data: mapQueryDataSourcesbyFilterIds(dataSource),
      id: params[i].policyId || ''
    }))
  }
)

export const ACTION_FETCH_CLASSIFICATIONS = 'policies/classifications'
export const fetchClassifications = createAsyncThunk(
  ACTION_FETCH_CLASSIFICATIONS,
  async () => await service.getClassificationsList()
)
export interface AttributesBySensitivity {
  [key: string]: AttributeSetAttribute[]
}
export const ACTION_FETCH_POLICY_ATTRIBUTES = 'documentLabels/attributes'
export const fetchRulesetAttributes = createAsyncThunk(ACTION_FETCH_POLICY_ATTRIBUTES, async () => {
  const resultRaw = await graphqlService.execute(queryRulesetAttributes())
  return mapQueryRulesetAttributes(resultRaw)
})

export const fetchPolicyTypes = createAsyncThunk('policy/fetchPolicyTypes', async () => {
  const result = await graphqlService.execute(queryPolicyTypes())
  return mapQueryPolicyTypes(result)
})

export const ACTION_FETCH_POLICY_FILTER_BY_IDS = 'policy/fetchPolicyTypeByIds'
export const fetchPolicyTypeByIds = createAsyncThunk(
  ACTION_FETCH_POLICY_FILTER_BY_IDS,
  async (ids: string[]) => {
    const response = await graphqlService.execute(queryPolicyFilterByIds(ids))
    return mapQueryPolicyFilterByIds(response)
  }
)

export const fetchPolicyTypeCards = createAsyncThunk('policies/fetchPolicyTypeCards', async () => {
  const result = await graphqlService.execute(queryPoliciesTypeCards())
  return mapQueryPoliciesTypeCards(result)
})

export const fetchPoliciesListByType = createAsyncThunk(
  'policies/fetchPoliciesListByType',
  async (params: IPolicyListByTypeParam) => {
    const result = await graphqlService.execute(queryPoliciesListByType(params))
    return mapQueryPoliciesListByType(result)
  }
)

export const ACTION_FETCH_POLICY_ALERTS = 'policy/alerts'
export const fetchPolicyAlerts = createAsyncThunk(
  ACTION_FETCH_POLICY_ALERTS,
  async (filters: IGetPolicyAlertsParams, { getState }) => {
    const queryParams = { ...filters, ...getGlobalParams(getState() as RootState) }
    const result = await graphqlService.execute(
      queryPolicyAlerts(queryParams),
      ACTION_FETCH_POLICY_ALERTS
    )
    const { list, total } = mapQueryPolicyAlerts(result)

    return { list, total, filters }
  }
)

export const fetchPolicyAlertsTotals = createAsyncThunk(
  'policy/alertsTotals',
  async (params: IGetPolicyAlertsParams) => {
    const result = await graphqlService.execute(queryPolicyAlertsTotals(params))
    return mapQueryPolicyAlertsTotals(result)
  }
)

export type PolicyAlertCard = {
  dataSourceType: DATA_SOURCE_TYPES
  dataSourceName: string
  policiesCount: number
  alertsCount: number
  entitiesCount: number
  documentsCount: number
}

export type RuleSetFilterList = {
  id: string
  name: string
}

export type AlertsGroupedParams = {
  filters: FilterParams
  subAlertAssignees?: string[]
}
export const ACTION_POLICY_ALERTS_GROUPED_FETCH = 'alerts/cards'
export const fetchPolicyAlertsGrouped = createAsyncThunk(
  ACTION_POLICY_ALERTS_GROUPED_FETCH,
  async (params: AlertsGroupedParams) => {
    const result = await graphqlService.execute(queryPolicyAlertCards(params))
    return mapQueryPolicyAlertCards(result)
  }
)

export const ACTION_FETCH_RULE_SET_FILTER_LIST = 'alerts/filter/ruleSet'
export const fetchRuleSetFilterList = createAsyncThunk(
  ACTION_FETCH_RULE_SET_FILTER_LIST,
  async () => {
    const result = await graphqlService.execute(queryRuleSetFilterList())
    return mapQueryRuleSetFilterList(result)
  }
)

// Alert audit logs
export type AlertAuditLog = {
  id: string
  action: ALERT_ACTIONS
  triggeredBy: string
  comment?: string
  completionTimestamp?: string
  triggeredTimestamp?: string
  numObjectsPending?: number
  numObjectsComplete?: number
  newAssignee?: string
  archivalLocation?: string
  muteTillTimestamp?: string
  totalObjects?: number
  objectsList?: any
  updateTimestamp?: string
  workflowId?: string
  subAlertId?: string
}

export type AuditLogActionHistory = {
  alertHistory: Array<{
    id: string
    triggeredTimestamp: string
    triggeredBy: string
    action: string
    comment: string
    subAlertId: string
    workflowId: string
  }>
}
export interface AlertAuditLogsParams {
  alertId: string
}
export const ACTION_ALERT_AUDIT_LOGS = 'alert/auditLogs'
export const fetchAlertAuditLogs = createAsyncThunk(
  ACTION_ALERT_AUDIT_LOGS,
  async (params: AlertAuditLogsParams) => {
    const result = await graphqlService.execute(queryAlertAuditLogs(params))
    return mapQueryAlertAuditLogs(result)
  }
)

export interface AlertAuditLogDetailsParams {
  alertId: string
  auditLogId: string
  page: number
}
export const ACTION_ALERT_AUDIT_LOG_DETAILS = 'alert/auditLog/details'
export const fetchAlertAuditLogDetails = createAsyncThunk(
  ACTION_ALERT_AUDIT_LOG_DETAILS,
  async (params: AlertAuditLogDetailsParams) => {
    const result = await graphqlService.execute(queryAlertAuditLogDetails(params))
    return mapQueryAlertAuditLogDetails(result)
  }
)

export interface AlertAuditLogActionHistoryParams {
  subAlertId: string
  anchorWorkflowId: string
  page: number
  nearestNeighbours: number
}
export const ACTION_ALERT_AUDIT_LOG_ACTION_HISTORY = 'alert/auditLog/actionHistory'
export const fetchAuditLogActionHistory = createAsyncThunk(
  ACTION_ALERT_AUDIT_LOG_ACTION_HISTORY,
  async (params: AlertAuditLogActionHistoryParams) => {
    const result = await graphqlService.execute(queryAuditLogActionHistory(params))
    return mapAuditLogActionHistory(result)
  }
)

// Alert actions
export interface AlertActionParams {
  alertIds: string[]
  triggeredBy: string
  newAssignee?: string
  subAlertIds?: string[]
  folderIds?: string[]
  comment?: string
  muteTillTimestamp?: string
  revokeAccessType?: OBJECT_REVOKE_ACCESS_TYPES
  subAlertAssignmentStrategy?: ALERT_ASSIGMENT_OPTIONS
  assignees?: string[]
}
export const ACTION_ALERT_RESOLVE = 'alert/resolveAlert'
export const resolveAlert = createAsyncThunk(
  ACTION_ALERT_RESOLVE,
  async (props: AlertActionParams, { rejectWithValue }) => {
    type ResultType = PromiseResult<{ resolveSubAlert: { status: string } }>[]
    const rawResult: ResultType = await Promise.allSettled(
      props.alertIds.map((alertId) =>
        graphqlService.execute(queryResolveAlert({ ...props, alertIds: [alertId] }))
      )
    )
    const results = rawResult.filter((res) => res.status === 'fulfilled')
    const statuses = results.map((res) => res.value)

    if (statuses[0]?.resolveSubAlert?.status) {
      return { statusMessage: 'policy.alerts.resolveAlerts.success' }
    } else {
      return rejectWithValue({ statusMessage: 'policy.alerts.resolveAlerts.error' })
    }
  }
)
export const ACTION_ALERT_REASSIGN = 'alert/reassignAlert'
export const reassignAlert = createAsyncThunk(
  ACTION_ALERT_REASSIGN,
  async (props: AlertActionParams, { rejectWithValue }) => {
    type ResultType = PromiseResult<{ reassignSubAlert: { status: string } }>[]

    const rawResult: ResultType = await Promise.allSettled(
      props.alertIds.map((alertId) =>
        graphqlService.execute(queryReassignAlert({ ...props, alertIds: [alertId] }))
      )
    )
    const results = rawResult.filter((res) => res.status === 'fulfilled')
    const statuses = results.map((res) => res.value)

    if (statuses[0]?.reassignSubAlert?.status) {
      return { statusMessage: 'policy.alerts.reassignAlerts.success' }
    } else {
      return rejectWithValue({ statusMessage: 'policy.alerts.reassignAlerts.error' })
    }
  }
)

export const ACTION_ALERT_NO_SCAN = 'alert/noScanAlert'
export const noScanAlert = createAsyncThunk(
  ACTION_ALERT_NO_SCAN,
  async (props: AlertActionParams, { rejectWithValue }) => {
    type ResultType = PromiseResult<{ noScanSubAlert: { status: string } }>[]

    const rawResult: ResultType = await Promise.allSettled(
      props.alertIds.map((alertId) =>
        graphqlService.execute(queryNoScanAlert({ ...props, alertIds: [alertId] }))
      )
    )
    const results = rawResult.filter((res) => res.status === 'fulfilled')
    const statuses = results.map((res) => res.value)

    if (statuses[0]?.noScanSubAlert?.status) {
      return { statusMessage: 'policy.alerts.addToNoScanList.success' }
    } else {
      return rejectWithValue({ statusMessage: 'policy.alerts.addToNoScanList.error' })
    }
  }
)
export const ACTION_ALERT_PERMIT = 'alert/permitAlert'
export const permitAlert = createAsyncThunk(
  ACTION_ALERT_PERMIT,
  async (props: AlertActionParams, { rejectWithValue }) => {
    type ResultType = PromiseResult<{ permitSubAlert: { status: string } }>[]
    const rawResult: ResultType = await Promise.allSettled(
      props.alertIds.map((alertId) =>
        graphqlService.execute(queryPermitAlert({ ...props, alertIds: [alertId] }))
      )
    )
    const results = rawResult.filter((res) => res.status === 'fulfilled')
    const statuses = results.map((res) => res.value)

    if (statuses[0]?.permitSubAlert?.status) {
      return { statusMessage: 'policy.alerts.addToPermitList.success' }
    } else {
      return rejectWithValue({ statusMessage: 'policy.alerts.addToPermitList.error' })
    }
  }
)
export const ACTION_ALERT_MUTE = 'alert/muteAlert'
export const muteAlert = createAsyncThunk(
  ACTION_ALERT_MUTE,
  async (props: AlertActionParams, { rejectWithValue }) => {
    type ResultType = PromiseResult<{ muteSubAlert: { status: string } }>[]
    const rawResult: ResultType = await Promise.allSettled(
      props.alertIds.map((alertId) =>
        graphqlService.execute(queryMuteAlert({ ...props, alertIds: [alertId] }))
      )
    )
    const results = rawResult.filter((res) => res.status === 'fulfilled')
    const statuses = results.map((res) => res.value)

    if (statuses[0]?.muteSubAlert?.status) {
      return { statusMessage: 'policy.alerts.muteAlerts.success' }
    } else {
      return rejectWithValue({ statusMessage: 'policy.alerts.muteALerts.error' })
    }
  }
)
export const ACTION_ALERT_UNMUTE = 'alert/unmuteAlert'
export const unmuteAlert = createAsyncThunk(
  ACTION_ALERT_UNMUTE,
  async (props: AlertActionParams, { rejectWithValue }) => {
    type ResultType = PromiseResult<{ unmuteSubAlert: { status: string } }>[]
    const rawResult: ResultType = await Promise.allSettled(
      props.alertIds.map((alertId) =>
        graphqlService.execute(queryUnmuteAlert({ ...props, alertIds: [alertId] }))
      )
    )
    const results = rawResult.filter((res) => res.status === 'fulfilled')
    const statuses = results.map((res) => res.value)

    if (statuses[0]?.unmuteSubAlert?.status) {
      return { statusMessage: 'policy.alerts.unmuteAlerts.success' }
    } else {
      return rejectWithValue({ statusMessage: 'policy.alerts.unmuteAlerts.error' })
    }
  }
)
export const ACTION_ALERT_DELETE_OBJECT = 'alert/deleteAlertObject'
export const deleteAlertObject = createAsyncThunk(
  ACTION_ALERT_DELETE_OBJECT,
  async (props: AlertActionParams, { rejectWithValue }) => {
    type ResultType = PromiseResult<{ deleteSubAlertObject: { status: string } }>[]

    const rawResult: ResultType = await Promise.allSettled(
      props.alertIds.map((alertId) =>
        graphqlService.execute(queryDeleteAlertObject({ ...props, alertIds: [alertId] }))
      )
    )
    const results = rawResult.filter((res) => res.status === 'fulfilled')
    const statuses = results.map((res) => res.value)

    if (statuses[0]?.deleteSubAlertObject?.status) {
      return { statusMessage: 'policy.alerts.deleteAlert.success' }
    } else {
      return rejectWithValue({ statusMessage: 'policy.alerts.deleteAlert.error' })
    }
  }
)

export const ACTION_FETCH_FILES_TO_EXPORT = 'alert/filesToExport'
export const fetchFilesToExportForAlert = createAsyncThunk(
  ACTION_FETCH_FILES_TO_EXPORT,
  async (params: DownloadListParams): Promise<{ data: string; fileName: string }> => {
    const { data, headers } = await service.downloadFile(params)
    const disposition = headers['content-disposition']
    let fileName = ''
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRegex.exec(disposition)
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '')
      }
    }

    return { data, fileName }
  }
)

export const ACTION_REVOKE_ACCESS = 'alert/revokeAccess'
export const revokeAccess = createAsyncThunk(
  ACTION_REVOKE_ACCESS,
  async (params: AlertActionParams, { rejectWithValue }) => {
    const result = await graphqlService.execute(mutationRevokeObjectAccess(params))

    if (result.revokeObjectAccess.status) {
      return { statusMessage: 'policy.alerts.revokeAccess.success' }
    } else {
      return rejectWithValue({ statusMessage: 'policy.alerts.revokeAccess.error' })
    }
  }
)

export type SubAlertsActionParams = {
  alertId: string
  subAlertIds: string[]
  triggeredBy: string
  comment: string
}

export const ACTION_ARCHIVE_SUB_ALERTS = 'alert/archiveSubAlerts'
export const archiveSubAlertObject = createAsyncThunk(
  ACTION_ARCHIVE_SUB_ALERTS,
  async (params: SubAlertsActionParams, { rejectWithValue }) => {
    const result = await graphqlService.execute(mutationArchiveSubAlerts(params))

    if (result.archiveSubAlertObject.status) {
      return { statusMessage: 'policy.alerts.archive.success' }
    } else {
      return rejectWithValue({ statusMessage: 'policy.alerts.archive.error' })
    }
  }
)

export type ApplySubAlertActionParams = SubAlertsActionParams & {
  muteTillTimestamp: number
}

export const ACTION_APPLY_SUB_ALERT_LEGAL_HOLD = 'alert/applySubAlertLegalHold'
export const applySubAlertLegalHold = createAsyncThunk(
  ACTION_APPLY_SUB_ALERT_LEGAL_HOLD,
  async (params: ApplySubAlertActionParams, { rejectWithValue }) => {
    const result = await graphqlService.execute(mutationApplySubAlertLegalHold(params))

    if (result.updateAlertAssignment.status) {
      return { statusMessage: 'policy.alerts.applySubAlertLegalHold.success' }
    } else {
      return rejectWithValue({ statusMessage: 'policy.alerts.applySubAlertLegalHold.error' })
    }
  }
)

// filters
export const fetchPolicyFilters = createAsyncThunk('policy/filters', async () => {
  const result = await graphqlService.execute(queryProfileFilters())
  const { list, total } = mapQueryPolicyFilters(result)

  return { list, total }
})

export const uploadFileForFilter = createAsyncThunk('filter/postFile', async (file: File) => {
  const formData = new FormData()
  formData.append('fileContent', file)
  formData.append('fileName', file.name)

  return await service.postFileForFilter(formData)
})

export const fetchProfileMapData = createAsyncThunk(
  'policy/fetchProfileMapData',
  async (profileMapId: string) => {
    const result = await graphqlService.execute(queryProfileMapData(profileMapId))
    return mapQueryProfileMapData(result)
  }
)

export const createFilter = createAsyncThunk(
  'policy/createFilter',
  async (params: ICreateFilterParams) => {
    await graphqlService.execute(queryCreateFilter(params))
  }
)

export const applyFilterCondtion = createAsyncThunk(
  'policy/applyFilterCondtion',
  async (params: IApplyFilterParams) => {
    const result = await graphqlService.execute(queryApplyFilterCondtion(params))
    return mapApplyFilterCondtion(result)
  }
)

export type IAlertObjectsImpactedParams = {
  [PAGE]: number
  [PAGE_SIZE]?: number
  [ALERT_ID]: string
  [DATA_SOURCE_TYPE]: DATA_SOURCE_TYPES
  [DATA_SOURCE_ID]?: string
  subAlertAssignees?: string[]
  mailbox?: string[]
  mailboxFolderType?: MAIL_FOLDER_TYPE
  user?: string
  policyType?: POLICY_TYPES
}
export const ACTION_ALERT_OBJECT_IMPACTED_FETCH = 'policy/alertObjectsImpactedFetch'
export const fetchAlertObjectsImpacted = createAsyncThunk(
  ACTION_ALERT_OBJECT_IMPACTED_FETCH,
  async (params: IAlertObjectsImpactedParams, { getState }) => {
    const queryParams = { ...params, ...getGlobalParams(getState() as RootState) }
    const result = await graphqlService.execute(queryAlertObjectsImpacted(queryParams))
    return mapQueryAlertObjectsImpacted(result)
  }
)

export const ACTION_ALERT_FILTERS_MAILBOXES_FETCH = 'policy/alertObjectsImpacted/filters/mailboxes'
export const fetchMailboxesFilter = createAsyncThunk(
  ACTION_ALERT_FILTERS_MAILBOXES_FETCH,
  async (datasourceId: string) => {
    const result = await graphqlService.execute(queryMailboxFilterValues(datasourceId))
    return mapQueryMailboxFilterValues(result)
  }
)

// Groups by owner
export type AlertObjectsGroup = {
  owner: string
  objectsCount: number
}
export type AlertObjectsGroupParams = {
  [ALERT_ID]: string
  subAlertAssignees?: string[]
  user?: string
}
export const ACTION_ALERT_OBJECTS_GROUPS = 'policy/alertObjectsGroups'
export const fetchAlertObjectsGroups = createAsyncThunk(
  ACTION_ALERT_OBJECTS_GROUPS,
  async (params: AlertObjectsGroupParams) => {
    const { subAlertAssignees = [] } = params
    const result = await graphqlService.execute(queryAlertObjectsGroups(params))
    const cards = mapQueryAlertObjectsGroups(result)

    return subAlertAssignees?.length > 0
      ? cards.filter((card) => card.owner === subAlertAssignees[0])
      : cards
  }
)

// buckets
export const ACTION_ALERT_PROJECTS_IMPACTED_FETCH = 'policy/projectsImpactedFetch'
export const fetchAlertProjectsImpacted = createAsyncThunk(
  ACTION_ALERT_PROJECTS_IMPACTED_FETCH,
  async (params: IAlertObjectsImpactedParams) => {
    const result = await graphqlService.execute(queryAlertProjectsImpacted(params))
    return mapQueryAlertProjectsImpacted(result)
  }
)

export const ACTION_ALERT_EMAIL_RECIPIENTS_SENDERS_FETCH =
  'policy/alertEmailsImpacted/recipients-senders'
export const fetchAlertEmailRecipientsWithSenders = createAsyncThunk(
  ACTION_ALERT_EMAIL_RECIPIENTS_SENDERS_FETCH,
  async (params: IAlertObjectsImpactedParams) => {
    const result = await graphqlService.execute(queryAlertEmailRecipientsWithSenders(params))
    return mapQueryAlertEmailRecipientsWithSenders(result)
  }
)

export const ACTION_ALERT_S3_BUCKETS_FETCH = 'policy/alertBuckets'
export const fetchAlertAwsBuckets = createAsyncThunk(
  ACTION_ALERT_S3_BUCKETS_FETCH,
  async (params: IAlertObjectsImpactedParams) => {
    const result = await graphqlService.execute(queryAlertAwsBuckets(params))
    return mapQueryAlertAwsBuckets(result)
  }
)

export const ACTION_ALERT_DRIVE_FOLDERS_FETCH = 'policy/folders'
export const fetchAlertDriveFolders = createAsyncThunk(
  ACTION_ALERT_DRIVE_FOLDERS_FETCH,
  async (params: IAlertObjectsImpactedParams) => {
    const result = await graphqlService.execute(queryAlertDriveFolders(params))
    return mapQueryAlertDriveFolders(result)
  }
)

export const createAttributeSetFilter = createAsyncThunk(
  'policy/createAttributeSetFilter',
  async (attributeSetIds: string[]) => {
    const result = await graphqlService.execute(queryCreateAttributeSetFilter(attributeSetIds))
    return mapQueryCreateDatasourceFilter(result)
  }
)

export const updateProfileMap = createAsyncThunk('policy/updateProfileMap', async (_, thunkAPI) => {
  const policies = (thunkAPI.getState() as { policies: PoliciesState }).policies
  const profileMapColumns = policies.profileMapColumnsData.list
  const profileMapId = policies.profileMapId

  const updatedColumns = profileMapColumns?.map((item) => ({
    sourceField: item.sourceField,
    sourceFieldIndex: item.sourceFieldIndex,
    isSensitive: item.isSensitive || false,
    attribute: item.attribute || '',
    confidence: item.confidence || 0
  }))

  const params = {
    columns: updatedColumns,
    profileMapId
  }

  await graphqlService.execute(queryUpdateProfileMap(params))
})

const getApiAttributeConditions = (
  conditions: AlertAttributeCondition[]
): AlertAttributeCondition[] => {
  if (!conditions) return []

  const settings: AlertAttributeCondition[] = []
  conditions.forEach((condition, i) => {
    // get conditionOperator of the next item
    const conditionOperator = conditions[i + 1]?.conditionOperator || null
    settings.push({ ...condition, conditionOperator })
  })

  return settings
}

const getUiAttributeConditions = (conditions?: AlertAttributeCondition[]) => {
  if (!conditions) return []

  const uiConditions: AlertAttributeCondition[] = []

  let conditionOperator = ''
  conditions.forEach((settings) => {
    uiConditions.push({ ...settings, conditionOperator })

    // add current condition operator for the next item
    if (settings.conditionOperator) {
      conditionOperator = settings.conditionOperator
    }
  })

  return uiConditions
}

export const ACTION_CREATE_POLICY = 'policy/createPolicy'
export const createPolicy = createAsyncThunk(
  ACTION_CREATE_POLICY,
  async (params: ICreatePolicyParams) => {
    const generateAlertSettings = params.generateAlertSettings?.length
      ? getApiAttributeConditions(params.generateAlertSettings)
      : []
    const mutationResult = await graphqlService.execute(
      queryCreatePolicy({ ...params, generateAlertSettings })
    )
    return mutationResult?.createPolicy ? { statusMessage: 'policy.create.success' } : {}
  }
)

export const ACTION_UPDATE_POLICY = 'policy/updatePolicy'
export const updatePolicy = createAsyncThunk(
  ACTION_UPDATE_POLICY,
  async (params: IUpdatePolicyParams) => {
    const generateAlertSettings = params.generateAlertSettings?.length
      ? getApiAttributeConditions(params.generateAlertSettings)
      : []
    return await graphqlService.execute(queryUpdatePolicy({ ...params, generateAlertSettings }))
  }
)
export interface DatasourceScanConditionsFilter {
  datasourceId: string
  scanConditions: UnstructuredScanConditions[]
}

export const createDatasourceFilter = createAsyncThunk(
  'policy/createDatasourceFilter',
  async (params: ICreateDatasourceFilterParams) => {
    const result = await graphqlService.execute(queryCreateDatasourceFilter(params))
    return mapQueryCreateDatasourceFilter(result)
  }
)

export const createDocumentLabelSetFilter = createAsyncThunk(
  'policy/createDocumentLabelSetFilter',
  async (params: ICreateDocumentLabelSetFilterParams) => {
    const result = await graphqlService.execute(queryCreateDocumentLabelSetsFilter(params))
    return mapQueryCreateDatasourceFilter(result)
  }
)

export interface ICreateDurationFilterParams {
  valueType: string
  value: number
  attributeType?: RETENTION_ATTRIBUTE_TYPES
}
export const createDurationFilter = createAsyncThunk(
  'policy/createDurationFilter',
  async (params: ICreateDurationFilterParams) => {
    const result = await graphqlService.execute(mutationCreateDurationFilter(params))
    return mapQueryCreateDatasourceFilter(result)
  }
)

export const createDocumentLabelFilter = createAsyncThunk(
  'policy/createDocumentLabelFilter',
  async (params: DocumentLabelGroup) => {
    const result = await graphqlService.execute(mutationCreateDocumentLabelFilter(params))
    return mapQueryCreateDatasourceFilter(result)
  }
)

export interface ICreateDocumentFiltersParams {
  operator: CriteriaOperatorTypes
  groups: Ruleset[]
}

export const createDocumentFilter = createAsyncThunk(
  'policy/createDocumentFilters',
  async (params: ICreateDocumentFiltersParams) => {
    const result = await graphqlService.execute(mutationCreateDocumentFilter(params))
    return mapQueryCreateDatasourceFilter(result)
  }
)

export interface IUpdateDocumentFiltersParams extends ICreateDocumentFiltersParams {
  id: string
}

export const updateDocumentFilter = createAsyncThunk(
  'policy/updateDocumentFilter',
  async (params: IUpdateDocumentFiltersParams) => {
    return await graphqlService.execute(mutationUpdateDocumentFilter(params))
  }
)

export interface ICreateAccessFilterParams {
  accessType: ACCESS_CONTROL_TYPES
  accessSubTypes?: OPEN_ACCESS_TYPES[] | EXCESSIVE_ACCESS_TYPES[]
  excessiveAccessUserCount?: number
}

export const createAccessFilter = createAsyncThunk(
  'policy/createAccessFilter',
  async (params: ICreateAccessFilterParams) => {
    const result = await graphqlService.execute(mutationCreateAccessFilter(params))
    return mapQueryCreateDatasourceFilter(result)
  }
)

export interface IUpdateAccessFilterParams extends ICreateAccessFilterParams {
  id: string
}
export const updateAccessFilter = createAsyncThunk(
  'policy/updateAccessFilter',
  async (params: IUpdateAccessFilterParams) => {
    return await graphqlService.execute(mutationUpdateAccessFilter(params))
  }
)

export const updateDocumentLabelSetFilter = createAsyncThunk(
  'policy/updateDocumentLabelSetFilter',
  async (params: IUpdateFilter) => {
    return await graphqlService.execute(queryUpdateDocumentLabelSetFilter(params))
  }
)

export const updateDurationFilter = createAsyncThunk(
  'policy/updateDurationFilter',
  async (params: IUpdateFilter) => {
    return await graphqlService.execute(queryUpdateDurationFilter(params))
  }
)

export const updateDocumentLabelFilter = createAsyncThunk(
  'policy/updateDocumentLabelFilter',
  async (params: { filterData: DocumentLabelGroup; id: string }) => {
    return await graphqlService.execute(mutationUpdateDocumentLabelFilter(params))
  }
)

export type UpdateAlertAssignmentParams = {
  id: string
  alertAssignment: ALERT_ASSIGMENT_OPTIONS
}
export const ACTION_UPDATE_ALERT_ASSIGNMENT = 'policy/updateDocumentLabelFilter'
export const updateAlertAssignment = createAsyncThunk(
  ACTION_UPDATE_ALERT_ASSIGNMENT,
  async (params: UpdateAlertAssignmentParams) => {
    return await graphqlService.execute(mutationUpdateAlertAssignment(params))
  }
)

export const updateDatasourceFilter = createAsyncThunk(
  'policy/updateDatasourceFilter',
  async (params: IUpdateFilter) => {
    return await graphqlService.execute(queryUpdateDatasourceFilter(params))
  }
)

export const createAutomationAction = createAsyncThunk(
  'policy/createAutomationAction',
  async () => {
    const result = await graphqlService.execute(queryCreateAutomationAction())
    return mapQueryCreateAutomationAction(result)
  }
)

export const updateAttributeSetFilter = createAsyncThunk(
  'policy/updateAttributeSetFilter',
  async (params: IUpdateFilter) => {
    return await graphqlService.execute(queryUpdateAttributeSetFilter(params))
  }
)

export const fetchDataSources = createAsyncThunk(
  'policy/fetchDataSources',
  async () => await graphqlService.execute(queryDataSources())
)

export const deletePolicy = createAsyncThunk(
  'policy/deletePolicy',
  async (params: IDeletePolicyParams) => await graphqlService.execute(queryDeletePolicy(params))
)

export type IPolicyAlertExternalUser = {
  id: string
  objectId: string
  objectName: string
  attributesCount: number
  entitiesCount: number
  owner: string
  lastModified: string | null
}

export type IPolicyAlertExternalUserGrouped = {
  user: string
  objectsCount: number
}

export type IPolicyAlertExternalUserParams = {
  [PAGE]: number
  [PAGE_SIZE]?: number
  [ALERT_ID]: string
  [DATA_SOURCE_ID]?: string
  externalUser?: string
  subAlertAssignees?: string[]
}

export const ACTION_POLICY_ALERTS_EXTERNAL_USERS = 'alerts/externalUsers'
export const fetchPolicyAlertsExternalUsers = createAsyncThunk(
  ACTION_POLICY_ALERTS_EXTERNAL_USERS,
  async (params: IPolicyAlertExternalUserParams, { getState }) => {
    const queryParams = { ...params, ...getGlobalParams(getState() as RootState) }
    const result = await graphqlService.execute(queryExternalUsers(queryParams))
    return mapQueryExternalUsers(result)
  }
)

export type IPolicyAlertEntitiesImpacted = {
  entityId: string
  entityName: string
  objectsCount: number
  attributesCount: number
}

export type IPolicyAlertEntitiesImpactedParams = {
  [ALERT_ID]: string
  [DATA_SOURCE_ID]?: string
  [PAGE]: number
  subAlertAssignees?: string[]
}

export const ACTION_POLICY_ALERTS_ENTITIES_IMPACTED = 'alerts/entitiesImpacted'
export const fetchPolicyAlertsEntitiesImpacted = createAsyncThunk(
  ACTION_POLICY_ALERTS_ENTITIES_IMPACTED,
  async (params: IPolicyAlertEntitiesImpactedParams) => {
    const result = await graphqlService.execute(queryEntitiesImpacted(params))
    return mapQueryEntitiesImpacted(result)
  }
)

export type AlertRuleSetDetailsParams = {
  [ALERT_ID]: string
  sensitivity?: string
  subAlertAssignees?: string[]
  isStructDs?: boolean
}

export type ActionTaken = {
  type: ALERT_ACTIONS
  comment: string
  updateTimestamp: string
  resolvedReason: string
  muteEndTime: string
  subAlertIds: string[]
}

export type AlertRuleSetDetails = {
  id: string
  name: string
  status: ALERT_STATUS
  severity: SEVERITY_LEVEL
  lastOccurred: string
  objectsCount: number
  entitiesCount: number
  attributesCount: number
  externalUsersCount: number
  dataSourceId: string
  dataSourceName: string
  dataSourceType: DATA_SOURCE_TYPES
  dataSourceOwner: string
  policyName: POLICY_TYPES
  policyTypeId?: string
  policyId?: string
  description: string
  regulations: REGULATIONS[]
  assignees?: string[]
  actions: ActionTaken[]
  mailCount: number
  ticketsCount: number
  channelsCount: number
  alertAssignment?: ALERT_ASSIGMENT_OPTIONS
  dataSourceArchiveLocation?: string
}

export const ACTION_ALERT_RULE_SET_DETAILS = 'alerts/ruleSetDetails'
export const fetchAlertRuleSetDetails = createAsyncThunk(
  ACTION_ALERT_RULE_SET_DETAILS,
  async (params: AlertRuleSetDetailsParams) => {
    const result = await graphqlService.execute(queryRuleSetDetails(params))
    return mapQueryRuleSetDetails(result)
  }
)

export type ActionHistoryParams = {
  alertId: string
  generatedAtStart: number
  generatedAtEnd: number
}
type ActionConfig = {
  __typename: 'MuteConfig' | 'ReassignConfig'
  newAssignee?: string
  muteTillTimestamp?: string
  subAlertAssignmentStrategy: string | null
}
export type ObjectAlertActionHistory = {
  alertHistory: Array<{
    id: string
    triggeredBy: string
    triggeredTimestamp: string
    action: string
    comment: string
    config: ActionConfig
  }>
  object?: {
    name: string
    id: string
  }
}

export const ACTION_ALERT_ACTION_HISTORY = 'alerts/actionHistory'
export const fetchAlertActionHistory = createAsyncThunk(
  ACTION_ALERT_ACTION_HISTORY,
  async (params: ActionHistoryParams) => {
    const result = await graphqlService.execute(queryAlertActionHistory(params))
    return mapQueryAlertActionHistory(result)
  }
)

const policiesSlice = createSlice({
  name: 'policies',
  initialState,
  reducers: {
    resetPolicyTypes: (state) => {
      state.policyTypes = initialState.policyTypes
    },
    setSortForProfileMapColumns: (state, { payload }) => {
      state.profileMapColumnsData.sort = getSortDirection(
        state.profileMapColumnsData.sort,
        payload.column
      )
    },
    setSortForAlerts: (state, { payload }) => {
      state.alertGroups.sort = getSortDirection(state.alertGroups.sort, payload.column)
    },
    setSortForExternalUsers: (state, { payload }) => {
      state.externalUsers.sort = getSortDirection(state.externalUsers.sort, payload.column)
    },
    setSortForEntitiesImpacted: (state, { payload }) => {
      state.entitiesImpacted.sort = getSortDirection(state.entitiesImpacted.sort, payload.column)
    },
    setSortForFilters: (state, { payload }) => {
      state.filters.sort = getSortDirection(state.filters.sort, payload.column)
    },
    resetProfileMapId: (state) => {
      state.profileMapId = undefined
    },
    resetAlertsStatusUpdated: (state) => {
      state.alertsStatusUpdated = false
    },
    setSelectedColumn: (state, { payload }) => {
      state.policyMapSelectedColumn = payload
    },
    resetAlerts: (state) => {
      state.alertGroups = initialState.alertGroups
    },
    resetFilterCreateSuccess: (state) => {
      state.filterCreateSuccess = false
    },
    setSortForRulesets: (state, { payload }) => {
      state.rulesets.sort = getSortDirection(state.rulesets.sort, payload.column)
    },
    resetPolicyCreateFlags: (state) => {
      state.datasourceFilterCreateId = initialState.datasourceFilterCreateId
      state.documentLabelSetFilterCreateId = initialState.documentLabelSetFilterCreateId
      state.durationFilterCreateId = initialState.durationFilterCreateId
      state.documentLabelFilterCreateId = initialState.documentLabelFilterCreateId
      state.documentFilterCreateId = initialState.documentFilterCreateId
      state.accessFilterCreateId = initialState.accessFilterCreateId
      state.attributeSetFilterCreateId = initialState.attributeSetFilterCreateId
      state.datasourceActionCreateId = initialState.datasourceActionCreateId
      state.policyCreateSuccess = initialState.policyCreateSuccess
      state.policyUpdateSuccess = initialState.policyUpdateSuccess
      state.attributeSetsUpdateSuccess = initialState.attributeSetsUpdateSuccess
      state.documentLabelSetUpdateSuccess = initialState.documentLabelSetUpdateSuccess
      state.dataSourceUpdateSuccess = initialState.dataSourceUpdateSuccess
      state.documentFilterUpdateSuccess = initialState.documentFilterUpdateSuccess
      state.accessFilterUpdateSuccess = initialState.accessFilterUpdateSuccess
      state.policyFilters = initialState.policyFilters
      state.policy = null
    },
    resetPolicyDeleteFlag: (state) => {
      state.policyDeleteSuccess = false
    },
    resetPolicyExternalMembers: (state) => {
      state.externalUsers = initialState.externalUsers
    },
    updateProfileMapItem: (state, action) => {
      const updatedMap = state.profileMapColumnsData.list?.map((col) => {
        return col.sourceFieldIndex === action.payload.sourceFieldIndex
          ? { ...action.payload, changesStatus: COLUMN_CHANGES.updated }
          : col
      })
      state.profileMapColumnsData.list = [...(updatedMap as IProfileMapColumn[])]
    },
    resetPolicies: (state) => {
      state.rulesets = initialState.rulesets
    },
    resetObjectsImpacted: (state) => {
      state.alertObjectsImpacted = initialState.alertObjectsImpacted
    },
    resetFilesToExport: (state) => {
      state.filesToExport = initialState.filesToExport
    },
    resetAlertActionHistory: (state) => {
      state.objectAlertActionHistory = undefined
    },
    resetAuditActionHistory: (state) => {
      state.auditLogActionHistory = undefined
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPolicies.fulfilled, (state, action) => {
      state.total = action.payload.total
      state.atRisk = action.payload.atRisk
      state.sensitive = action.payload.sensitive
      state.vector = action.payload.vector
    })
    builder.addCase(fetchPolicyTypes.fulfilled, (state, action) => {
      state.policyTypes = action.payload
    })
    builder.addCase(fetchPolicyTypeCards.fulfilled, (state, action) => {
      state.policyTypes = action.payload
    })
    builder.addCase(fetchRulsetSummaryForPrint.fulfilled, (state, action) => {
      state.policyTypeForPrint = action.payload
    })
    builder.addCase(fetchClassifications.fulfilled, (state, action) => {
      state.classifications = action.payload
    })
    builder.addCase(fetchRulesetAttributes.fulfilled, (state, action) => {
      state.attributeSets = action.payload.attributeSets
      state.attributesBySensitivity = action.payload.attributesBySensitivity
    })
    builder.addCase(fetchRulsetDataSources.fulfilled, (state, { payload }) => {
      state.rulesetsDataSources = payload.reduce((dataSources, policyData) => {
        return { ...dataSources, [policyData.id]: policyData.data }
      }, {})
    })
    builder.addCase(updateProfileMap.pending, (state) => {
      state.isProfileMapDataUpdating = true
      state.profileMapColumnsData.list = state.profileMapColumnsData.list?.map((item) =>
        item.changesStatus === COLUMN_CHANGES.updated
          ? { ...item, changesStatus: COLUMN_CHANGES.pending }
          : item
      )
    })
    builder.addCase(updateProfileMap.fulfilled, (state) => {
      state.isProfileMapDataUpdating = false
      state.profileMapColumnsData.list = state.profileMapColumnsData.list?.map((item) =>
        item.changesStatus === COLUMN_CHANGES.pending
          ? { ...item, changesStatus: COLUMN_CHANGES.saved }
          : item
      )
    })
    builder.addCase(updateProfileMap.rejected, (state) => {
      state.isProfileMapDataUpdating = false
    })
    builder.addCase(fetchPolicyAlertsTotals.fulfilled, (state, { payload }) => {
      const { allCount, resolvedCount, activeCount } = payload
      state.alertGroups.totalAll = allCount
      state.alertGroups.totalResolved = resolvedCount
      state.alertGroups.totalPending = activeCount
    })
    builder.addCase(fetchPolicyAlerts.fulfilled, (state, { payload }) => {
      state.alertGroups.list = payload.list
    })
    builder.addCase(fetchPolicyFilters.fulfilled, (state, { payload: { list, total } }) => {
      state.filters.list = list
      state.filters.total = total
      state.policyFilters.filtersLoaded = true
    })
    builder.addCase(uploadFileForFilter.fulfilled, (state, { payload }) => {
      state.profileMapId = payload.id
    })
    builder.addCase(fetchProfileMapData.fulfilled, (state, { payload }) => {
      const {
        profileMapData,
        columns,
        profileMapColumnsData: { list, total },
        filteredRecordCount,
        filteredfileName,
        totalRecordCount
      } = payload
      state.profileMapData = profileMapData
      state.profileMapColumnsData.list = list
      state.profileMapColumnsData.total = total
      state.profileMapColumns = columns
      state.filteredFileInfo = {
        name: filteredfileName,
        filteredRecordCount,
        totalRecordCount
      }
    })
    builder.addCase(createFilter.fulfilled, (state) => {
      state.filterCreateSuccess = true
    })
    builder.addCase(applyFilterCondtion.fulfilled, (state, { payload }) => {
      state.profileMapData = payload.profileMapData
    })
    builder.addCase(fetchPoliciesListByType.fulfilled, (state, { payload }) => {
      const { list, total } = payload
      state.rulesets.list = list
      state.rulesets.total = total
    })
    builder.addCase(fetchRulsets.fulfilled, (state, { payload }) => {
      const { list, total } = payload
      state.rulesets.list = list
      state.rulesets.total = total
    })
    builder.addCase(createDatasourceFilter.fulfilled, (state, { payload }) => {
      if (payload.filterId) {
        state.datasourceFilterCreateId = payload.filterId
      }
    })
    builder.addCase(createDocumentLabelSetFilter.fulfilled, (state, { payload }) => {
      if (payload.filterId) {
        state.documentLabelSetFilterCreateId = payload.filterId
      }
    })
    builder.addCase(createDurationFilter.fulfilled, (state, { payload }) => {
      if (payload.filterId) {
        state.durationFilterCreateId = payload.filterId
      }
    })
    builder.addCase(createDocumentLabelFilter.fulfilled, (state, { payload }) => {
      if (payload.filterId) {
        state.documentLabelFilterCreateId = payload.filterId
      }
    })
    builder.addCase(createDocumentFilter.fulfilled, (state, { payload }) => {
      if (payload.filterId) {
        state.documentFilterCreateId = payload.filterId
      }
    })
    builder.addCase(createAccessFilter.fulfilled, (state, { payload }) => {
      if (payload.filterId) {
        state.accessFilterCreateId = payload.filterId
      }
    })
    builder.addCase(createAutomationAction.fulfilled, (state, { payload }) => {
      if (payload.actionId) {
        state.datasourceActionCreateId = payload.actionId
      }
    })
    builder.addCase(createAttributeSetFilter.fulfilled, (state, { payload }) => {
      if (payload.filterId) {
        state.attributeSetFilterCreateId = payload.filterId
      }
    })
    builder.addCase(updateDatasourceFilter.fulfilled, (state, { payload }) => {
      if (payload) {
        const { updateFilter: { filter: { edges = [] } = {} } = {} } = payload
        if (Array.isArray(edges) && edges.length) {
          const { node: { id = null } = {} } = edges[0]
          state.datasourceFilterCreateId = id
          state.dataSourceUpdateSuccess = true
        }
      }
    })
    builder.addCase(updateDocumentLabelSetFilter.fulfilled, (state, { payload }) => {
      if (payload) {
        const { updateFilter: { filter: { edges = [] } = {} } = {} } = payload
        if (Array.isArray(edges) && edges.length) {
          const { node: { id = null } = {} } = edges[0]
          state.documentLabelSetFilterCreateId = id
          state.documentLabelSetUpdateSuccess = true
        }
      }
    })
    builder.addCase(updateDocumentFilter.fulfilled, (state, { payload }) => {
      if (payload) {
        const { updateFilter: { filter: { edges = [] } = {} } = {} } = payload
        if (Array.isArray(edges) && edges.length) {
          const { node: { id = null } = {} } = edges[0]
          state.documentFilterCreateId = id
          state.documentFilterUpdateSuccess = true
        }
      }
    })
    builder.addCase(updateAccessFilter.fulfilled, (state, { payload }) => {
      if (payload) {
        const { updateFilter: { filter: { edges = [] } = {} } = {} } = payload
        if (Array.isArray(edges) && edges.length) {
          const { node: { id = null } = {} } = edges[0]
          state.accessFilterCreateId = id
          state.accessFilterUpdateSuccess = true
        }
      }
    })
    builder.addCase(updateDurationFilter.fulfilled, (state, { payload }) => {
      if (payload) {
        const { updateFilter: { filter: { edges = [] } = {} } = {} } = payload
        if (Array.isArray(edges) && edges.length) {
          const { node: { id = null } = {} } = edges[0]
          state.durationFilterCreateId = id
          state.durationUpdateSuccess = true
        }
      }
    })
    builder.addCase(updateDocumentLabelFilter.fulfilled, (state, { payload }) => {
      if (payload) {
        const { updateFilter: { filter: { edges = [] } = {} } = {} } = payload
        if (Array.isArray(edges) && edges.length) {
          const { node: { id = null } = {} } = edges[0]
          state.documentLabelFilterCreateId = id
          state.documentLabelUpdateSuccess = true
        }
      }
    })
    builder.addCase(updateAttributeSetFilter.fulfilled, (state, { payload }) => {
      if (payload) {
        const { updateFilter: { filter: { edges = [] } = {} } = {} } = payload
        if (Array.isArray(edges) && edges.length) {
          const { node: { id = null } = {} } = edges[0]
          state.attributeSetFilterCreateId = id
          state.attributeSetsUpdateSuccess = true
        }
      }
    })
    builder.addCase(createPolicy.fulfilled, (state) => {
      state.policyCreateSuccess = true
    })
    builder.addCase(createPolicy.rejected, (state) => {
      state.policyCreateSuccess = false
      state.datasourceFilterCreateId = initialState.datasourceFilterCreateId
      state.attributeSetFilterCreateId = initialState.attributeSetFilterCreateId
      state.documentLabelSetFilterCreateId = initialState.documentLabelSetFilterCreateId
      state.durationFilterCreateId = initialState.durationFilterCreateId
      state.documentLabelFilterCreateId = initialState.documentLabelFilterCreateId
      state.documentFilterCreateId = initialState.documentFilterCreateId
      state.accessFilterCreateId = initialState.accessFilterCreateId
    })
    builder.addCase(updatePolicy.fulfilled, (state) => {
      state.policyUpdateSuccess = true
      state.dataSourceUpdateSuccess = false
    })
    builder.addCase(fetchPolicyById.fulfilled, (state, { payload }) => {
      if (payload) {
        state.policy = payload
      }
    })
    builder.addCase(fetchPolicyDetailById.fulfilled, (state, { payload }) => {
      if (payload.policy) {
        state.policy = payload.policy || {}
      }
    })
    builder.addCase(fetchPolicyTypeByIds.fulfilled, (state, { payload }) => {
      if (payload) {
        const {
          profileFilterIds,
          datasourceFilterCreateId,
          documentLabelSetFilterCreateId,
          attributeSetFilterCreateId,
          durationFilterCreateId,
          documentLabelFilterCreateId,
          documentFilterCreateId,
          accessFilterCreateId,
          documentLabelSetId,
          durationCount,
          durationType,
          attributeType,
          dataSourceIds,
          datsourceScanConditions,
          datasources,
          attributeSets,
          attributeSetIds,
          documentLabelGroups,
          rulesetGroups,
          profileIds,
          accessType,
          accessSubTypes,
          excessiveAccessUserCount,
          list
        } = payload
        state.policyFilters.profileFilterIds = profileFilterIds || []
        state.datasourceFilterCreateId = datasourceFilterCreateId
        state.durationFilterCreateId = durationFilterCreateId
        state.documentLabelFilterCreateId = documentLabelFilterCreateId
        state.documentFilterCreateId = documentFilterCreateId
        state.accessFilterCreateId = accessFilterCreateId
        state.documentLabelSetFilterCreateId = documentLabelSetFilterCreateId
        state.attributeSetFilterCreateId = attributeSetFilterCreateId
        state.policyFilters.dataSourceIds = dataSourceIds || []
        state.policyFilters.datsourceScanConditions = datsourceScanConditions || []
        state.policyFilters.datasources = datasources || []
        state.policyFilters.profileIds = profileIds || []
        state.policyFilters.attributeSetIds = attributeSetIds || []
        state.policyFilters.documentLabelSetId = documentLabelSetId || ''
        state.policyFilters.durationCount = durationCount || 0
        state.policyFilters.durationType = durationType || ''
        state.policyFilters.attributeType = attributeType
        state.policyFilters.documentLabelGroups = documentLabelGroups
        state.policyFilters.rulesetGroups = rulesetGroups
        state.policyFilters.attributeSets = attributeSets || []
        state.policyFilters.profileIds = profileIds || []
        state.policyFilters.accessType = accessType
        state.policyFilters.accessSubTypes = accessSubTypes || []
        state.policyFilters.excessiveAccessUserCount = excessiveAccessUserCount || 0
        state.policyFilters.list = list || []
      }
    })
    builder.addCase(deletePolicy.fulfilled, (state, { payload }) => {
      const { deletePolicy: { isDeleted = false } = {} } = payload || {}
      if (isDeleted) {
        state.policyDeleteSuccess = true
      }
    })
    builder.addCase(fetchAlertObjectsImpacted.fulfilled, (state, action) => {
      state.alertObjectsImpacted.total = action.payload.total
      state.alertObjectsImpacted.list = action.payload.list
    })
    builder.addCase(fetchMailboxesFilter.fulfilled, (state, action) => {
      state.alertObjectsImpacted.filters.mailboxes = action.payload.list
    })
    builder.addCase(fetchAlertProjectsImpacted.fulfilled, (state, action) => {
      state.alertObjectsImpacted.total = action.payload.total
      state.alertObjectsImpacted.list = action.payload.list
    })
    builder.addCase(fetchAlertEmailRecipientsWithSenders.fulfilled, (state, action) => {
      state.alertMailRecipients = action.payload.recipients
      state.alertMailSenders = action.payload.senders
    })
    builder.addCase(fetchPolicyAlertsGrouped.fulfilled, (state, action) => {
      state.cards = action.payload
    })
    builder.addCase(fetchRuleSetFilterList.fulfilled, (state, action) => {
      state.ruleSetFilterList = action.payload
    })
    builder.addCase(fetchPolicyAlertsExternalUsers.fulfilled, (state, action) => {
      state.externalUsers.list = action.payload.list
      state.externalUsers.total = action.payload.total
      state.externalUsers.cards = action.payload.cards
      state.externalUsers.externalUserCount = action.payload.externalUserCount
    })
    builder.addCase(fetchPolicyAlertsEntitiesImpacted.fulfilled, (state, action) => {
      state.entitiesImpacted.list = action.payload.list
      state.entitiesImpacted.total = action.payload.total
    })
    builder.addCase(fetchAlertObjectsGroups.fulfilled, (state, action) => {
      state.alertObjectsImpacted.groups = action.payload
    })
    builder.addCase(fetchAlertRuleSetDetails.fulfilled, (state, action) => {
      state.ruleSetDetails = action.payload
    })
    builder.addCase(fetchAlertAwsBuckets.fulfilled, (state, action) => {
      state.alertAwsBuckets = action.payload
    })
    builder.addCase(fetchAlertDriveFolders.fulfilled, (state, action) => {
      state.alertDriveFolders = action.payload
    })
    builder.addCase(fetchAlertAuditLogs.fulfilled, (state, action) => {
      state.alertAudiLogs = action.payload || []
    })
    builder.addCase(fetchAlertAuditLogDetails.fulfilled, (state, action) => {
      state.alertAuditLogDetails = action.payload || []
    })
    builder.addCase(fetchAuditLogActionHistory.fulfilled, (state, action) => {
      if (action.payload) {
        state.auditLogActionHistory = action.payload || []
      }
    })
    builder.addCase(resolveAlert.fulfilled, (state) => {
      state.alertsStatusUpdated = true
    })
    builder.addCase(resolveAlert.rejected, (state) => {
      state.alertsStatusUpdated = false
    })
    builder.addCase(reassignAlert.fulfilled, (state) => {
      state.alertsStatusUpdated = true
    })
    builder.addCase(reassignAlert.rejected, (state) => {
      state.alertsStatusUpdated = false
    })
    builder.addCase(noScanAlert.fulfilled, (state) => {
      state.alertsStatusUpdated = true
    })
    builder.addCase(noScanAlert.rejected, (state) => {
      state.alertsStatusUpdated = false
    })
    builder.addCase(permitAlert.fulfilled, (state) => {
      state.alertsStatusUpdated = true
    })
    builder.addCase(permitAlert.rejected, (state) => {
      state.alertsStatusUpdated = false
    })
    builder.addCase(muteAlert.fulfilled, (state) => {
      state.alertsStatusUpdated = true
    })
    builder.addCase(muteAlert.rejected, (state) => {
      state.alertsStatusUpdated = false
    })
    builder.addCase(unmuteAlert.fulfilled, (state) => {
      state.alertsStatusUpdated = true
    })
    builder.addCase(unmuteAlert.rejected, (state) => {
      state.alertsStatusUpdated = false
    })
    builder.addCase(deleteAlertObject.fulfilled, (state) => {
      state.alertsStatusUpdated = true
    })
    builder.addCase(deleteAlertObject.rejected, (state) => {
      state.alertsStatusUpdated = false
    })
    builder.addCase(revokeAccess.fulfilled, (state) => {
      state.alertsStatusUpdated = true
    })
    builder.addCase(revokeAccess.rejected, (state) => {
      state.alertsStatusUpdated = false
    })
    builder.addCase(fetchFilesToExportForAlert.fulfilled, (state, { payload }) => {
      state.filesToExport = payload
    })
    builder.addCase(fetchAlertActionHistory.fulfilled, (state, { payload }) => {
      state.objectAlertActionHistory = payload
    })
  }
})

export const {
  setSortForAlerts,
  setSortForFilters,
  setSortForExternalUsers,
  setSortForEntitiesImpacted,
  resetProfileMapId,
  resetFilterCreateSuccess,
  setSortForRulesets,
  resetPolicyCreateFlags,
  resetPolicyDeleteFlag,
  updateProfileMapItem,
  setSelectedColumn,
  setSortForProfileMapColumns,
  resetAlertsStatusUpdated,
  resetObjectsImpacted,
  resetPolicyExternalMembers,
  resetAlerts,
  resetPolicyTypes,
  resetPolicies,
  resetFilesToExport,
  resetAlertActionHistory,
  resetAuditActionHistory
} = policiesSlice.actions

export default policiesSlice.reducer
