import {
  mapQueryCloudAccountsList,
  mapQueryCloudPlatformById,
  mapQueryCloudPlatformsList,
  mapQueryCloudResourcesCount,
  mapQueryCloudResourcesList,
  mapQueryGcpProjectsList,
  mapQueryRegionsList,
  mutationDeleteCloudResource,
  mutationRefreshCloudPlatform,
  mutationRegisterCloudPlatform,
  mutationUpdateCloudPlatform,
  mutationVerifyCloudPlatformCreds,
  queryCloudAccountsList,
  queryCloudPlatformById,
  queryCloudPlatformsList,
  queryCloudResourcesCount,
  queryCloudResourcesList,
  queryGcpProjectsList,
  queryRegionsList
} from './queries'
import {
  CloudAccountsListObject,
  CloudPlatformByIdResponseType,
  CloudPlatformsListObject,
  CloudResourcesListObject,
  DeleteCloudResourceParams,
  FetchCloudAccountsParams,
  FetchCloudPlatformByIdParams,
  FetchCloudPlatformsParams,
  FetchCloudResourcesParams,
  FetchRegionsListParams,
  GcpProjectsListObject,
  QueryGcpProjectListParams,
  RefreshCloudPlatformParams,
  RegionListObject,
  RegisterCloudPlatformParams,
  RegisterCloudPlatformResponse,
  UpdateCloudPlatformParams,
  VerifyCloudPlatformCredsParams,
  VerifyCloudPlatformCredsResponse
} from './types'
import graphqlService from '../../services/graphqlService'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

//Cloud Platform Listing Types

//Initial Cloud Platform State Type
type CloudPlatformState = {
  cloudResourcesList: CloudResourcesListObject[]
  cloudResourcesCount: number
  cloudPlatformsList: CloudPlatformsListObject[]
  cloudAccountsList: CloudAccountsListObject[]
  regionList: RegionListObject[]
  cloudPlatformById?: CloudPlatformByIdResponseType
  verifyCloudPlatformCredsResponse?: VerifyCloudPlatformCredsResponse
  gcpProjectsList?: GcpProjectsListObject[]
}

//Initial State
const initialState: CloudPlatformState = {
  cloudResourcesList: [],
  cloudResourcesCount: 0,
  cloudPlatformsList: [],
  cloudAccountsList: [],
  regionList: []
}

export const ACTION_FETCH_CLOUD_RESOURCES_LIST = 'cloudResources/list'
export const fetchCloudResourcesList = createAsyncThunk(
  ACTION_FETCH_CLOUD_RESOURCES_LIST,
  async (params: FetchCloudResourcesParams) => {
    const resultRaw = await graphqlService.execute(queryCloudResourcesList(params))
    return mapQueryCloudResourcesList(resultRaw)
  }
)

export const ACTION_FETCH_CLOUD_RESOURCES_COUNT = 'cloudResources/count'
export const fetchCloudResourcesCount = createAsyncThunk(
  ACTION_FETCH_CLOUD_RESOURCES_COUNT,
  async (params: FetchCloudResourcesParams) => {
    const resultRaw = await graphqlService.execute(queryCloudResourcesCount(params))
    return mapQueryCloudResourcesCount(resultRaw)
  }
)

export const ACTION_FETCH_CLOUD_PLATFORMS_LIST = 'cloudPlatforms/list'
export const fetchCloudPlatformsList = createAsyncThunk(
  ACTION_FETCH_CLOUD_PLATFORMS_LIST,
  async (params: FetchCloudPlatformsParams) => {
    const resultRaw = await graphqlService.execute(queryCloudPlatformsList(params))
    return mapQueryCloudPlatformsList(resultRaw)
  }
)

export const ACTION_FETCH_CLOUD_PLATFORM_BY_ID = 'cloudPlatforms/by-id'
export const fetchCloudPlatformById = createAsyncThunk(
  ACTION_FETCH_CLOUD_PLATFORM_BY_ID,
  async (params: FetchCloudPlatformByIdParams) => {
    const resultRaw = await graphqlService.execute(queryCloudPlatformById(params))
    return mapQueryCloudPlatformById(resultRaw)
  }
)

export const ACTION_REGISTER_CLOUD_PLATFORM = 'cloudPlatforms/registerCloudPlatform'
export const registerCloudPlatform = createAsyncThunk(
  ACTION_REGISTER_CLOUD_PLATFORM,
  async (params: RegisterCloudPlatformParams) => {
    const resultRaw: RegisterCloudPlatformResponse = await graphqlService.execute(
      mutationRegisterCloudPlatform(params)
    )

    return resultRaw
  }
)

export const ACTION_UPDATE_CLOUD_PLATFORM = 'cloudPlatforms/updateCloudPlatform'
export const updateCloudPlatform = createAsyncThunk(
  ACTION_UPDATE_CLOUD_PLATFORM,
  async (params: UpdateCloudPlatformParams) => {
    const resultRaw: RegisterCloudPlatformResponse = await graphqlService.execute(
      mutationUpdateCloudPlatform(params)
    )

    return resultRaw
  }
)

export const ACTION_VERIFY_CLOUD_PLATFORM_CREDS = 'cloudPlatforms/verifyCloudPlatformCreds'
export const verifyCloudPlatformCreds = createAsyncThunk(
  ACTION_VERIFY_CLOUD_PLATFORM_CREDS,
  async (params: VerifyCloudPlatformCredsParams) => {
    const resultRaw = await graphqlService.execute(mutationVerifyCloudPlatformCreds(params))

    return resultRaw as VerifyCloudPlatformCredsResponse
  }
)

export const ACTION_FETCH_CLOUD_ACCOUNTS_LIST = 'cloudAccounts/list'
export const fetchCloudAccountsList = createAsyncThunk(
  ACTION_FETCH_CLOUD_ACCOUNTS_LIST,
  async (params: FetchCloudAccountsParams) => {
    const resultRaw = await graphqlService.execute(queryCloudAccountsList(params))
    return mapQueryCloudAccountsList(resultRaw)
  }
)

export const ACTION_FETCH_REGIONS_LIST = 'regions/list'
export const fetchRegionsList = createAsyncThunk(
  ACTION_FETCH_REGIONS_LIST,
  async (params: FetchRegionsListParams) => {
    const resultRaw = await graphqlService.execute(queryRegionsList(params))
    return mapQueryRegionsList(resultRaw)
  }
)

export const ACTION_DELETE_CLOUD_RESOURCE = 'cloudResource/delete'
export const deleteCloudResource = createAsyncThunk(
  ACTION_DELETE_CLOUD_RESOURCE,
  async (params: DeleteCloudResourceParams) => {
    const resultRaw = await graphqlService.execute(mutationDeleteCloudResource(params))
    return resultRaw
  }
)

export const ACTION_REFRESH_CLOUD_PLATFORM = 'cloudPlatform/refresh'
export const refreshCloudPlatform = createAsyncThunk(
  ACTION_REFRESH_CLOUD_PLATFORM,
  async (params: RefreshCloudPlatformParams) => {
    const resultRaw = await graphqlService.execute(mutationRefreshCloudPlatform(params))
    return resultRaw
  }
)

export const ACTION_FETCH_GCP_PROJECTS_LIST = 'cloudPlatform/fetchGcpProjectsList'
export const fetchGcpProjectsList = createAsyncThunk(
  ACTION_FETCH_GCP_PROJECTS_LIST,
  async (params: QueryGcpProjectListParams) => {
    const resultRaw = await graphqlService.execute(queryGcpProjectsList(params))
    return mapQueryGcpProjectsList(resultRaw)
  }
)

const cloudPlatformSlice = createSlice({
  name: 'cloudPlatform',
  initialState,
  reducers: {
    resetCloudResourcesList: (state) => {
      state.cloudResourcesList = initialState.cloudResourcesList
    },
    resetCloudPlatformsList: (state) => {
      state.cloudPlatformsList = initialState.cloudPlatformsList
    },
    resetCloudAccountsList: (state) => {
      state.cloudAccountsList = initialState.cloudAccountsList
    },
    resetRegionList: (state) => {
      state.regionList = initialState.regionList
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCloudResourcesList.fulfilled, (state, action) => {
      state.cloudResourcesList = action.payload.list
    })
    builder.addCase(fetchCloudResourcesCount.fulfilled, (state, action) => {
      state.cloudResourcesCount = action.payload.total
    })
    builder.addCase(fetchCloudPlatformsList.fulfilled, (state, action) => {
      state.cloudPlatformsList = action.payload.list
    })
    builder.addCase(fetchCloudAccountsList.fulfilled, (state, action) => {
      state.cloudAccountsList = action.payload.list
    })
    builder.addCase(fetchRegionsList.fulfilled, (state, action) => {
      state.regionList = action.payload.list
    })
    builder.addCase(fetchCloudPlatformById.fulfilled, (state, action) => {
      state.cloudPlatformById = action.payload
    })
    builder.addCase(verifyCloudPlatformCreds.fulfilled, (state, action) => {
      state.verifyCloudPlatformCredsResponse = action.payload
    })
    builder.addCase(fetchGcpProjectsList.fulfilled, (state, action) => {
      state.gcpProjectsList = action.payload
    })
  }
})

export const {
  resetCloudResourcesList,
  resetCloudPlatformsList,
  resetCloudAccountsList,
  resetRegionList
} = cloudPlatformSlice.actions
export default cloudPlatformSlice.reducer
