import {
  createRopaMessage,
  getReportById,
  getRopaCollaborateInfo,
  getRopaReports,
  queryRopaDataSourceResponse,
  getRopaRequestConversation,
  mapGetReportById,
  mapGetRopaCollaborateInfo,
  mapGetRopaReports,
  mapQueryRopaDataSourceResponse,
  mapGetRopaRequestConversation,
  mapQueryRopaAttributesSets,
  mapQueryRopaEntityTypes,
  markRopaAsComplete,
  queryDeleteRopaProcess,
  queryDeleteTopicQuestion,
  queryRopaAttributesSets,
  queryRopaEntityTypes,
  queryUpdateQuestionnaireAttributeSets,
  queryUpdateQuestionnaireClassifications,
  queryUpdateQuestionnaireCustomTopic,
  queryUpdateQuestionnaireDataTransferDetails,
  queryUpdateQuestionnaireEntityTypes,
  queryUpdateQuestionnairePurpose,
  queryUpdateQuestionnaireRetentionPeriod,
  queryUpdateQuestionnaireDataLink,
  queryUpdateQuestionnaireSafetyMeasures,
  queryUpdateRopaProcess,
  sendRopaDatasourceRequest,
  queryDeleteRopaReport,
  mapGetRopaNameById,
  getRopaNameById,
  queryRopaClassifications,
  mapQueryRopaClassifications,
  queryDeleteCustomTopic,
  querySaveCustomTopic,
  queryProcessDataSources,
  mapQueryProcessDataSources,
  queryRevokeAccess,
  getListRopaRequests,
  mapGetListRopaRequests,
  getRopaDatasourceRequests,
  mapGetRopaDatasourceRequests,
  queryRejectRequest,
  queryRemoveRequest,
  queryRopaListValues,
  mapQueryRopaListValues,
  queryProcessOverview,
  mapQueryProcessOverview,
  mapQueryRopaProcessReports,
  queryRopaProcessReports,
  queryGenerateRopaReport,
  mapQueryGenerateRopaReport,
  queryCollaborationDetails,
  mapQueryCollaborationDetails,
  queryRopaTickets,
  mapQueryRopaTickets,
  mutationDeleteCollaboration,
  queryProcessDetails
} from './queries'
import { mapRopaQuestionnaireTopicsToUI } from './ropaUtil'
import {
  RopaCollaborator,
  RopaDataFlowDataSource,
  RopaQuestionnaireOptionalTopics
} from './ropaSliceV2'
import { mockedGroupsList, mockedCompaniesList } from '../../__mocks__/ropa'
import { FilterParams } from '../../interfaces'
import { defaultSortParams, getSortDirection, SortParams } from '../../utils/sortUtil'
import { Classification, DataSource } from '../../services/api/apiTypes'
import { AttributeSet, AttributeSetAttribute } from '../attributes/attributesSlice'
import graphqlService from '../../services/graphqlService'
import apiService from '../../services/api/apiService'
import { ClassificationGroupedItem } from '../classifications/classificationsSlice'
import {
  DataSourceLocations,
  DataSourceProcessingStages,
  DATASOURCE_STATUS,
  DATA_SOURCE_TYPES,
  PAGE,
  REPORT_ID,
  SEVERITY_LEVEL,
  PAGE_SIZE
} from '../../constants'
import { RootState } from '../../rootReducer'
import { getGlobalParams } from '../../utils/urlUtil'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const SAFEGUARDS = ['Encryption', 'Access Control', 'Others']

export const ROPA_CUSTOM_TEMPATE_TOPICS = [
  {
    topicId: 'processDetails',
    type: '',
    description: '',

    optional: false,
    descriptionDeleted: true,
    _type: 'processDetails'
  },
  {
    topicId: 'dataSubjects',
    type: '',
    description: '',

    optional: false,
    descriptionDeleted: true,
    _type: 'dataSubjects'
  },
  {
    topicId: 'safetyMeasures',
    type: '',
    description: '',

    optional: false,
    descriptionDeleted: true,
    _type: 'safetyMeasures'
  },
  {
    topicId: 'dataTransferDetails',
    type: '',
    description: '',

    optional: false,
    descriptionDeleted: true,
    _type: 'dataTransferDetails'
  }
]

export interface IDataTranferInfo {
  name: string
  address: string
  safeguard: string
  categoryOfRecipients: string
  transferRetentionPeriod: number
  description: string | null
  nameLabel: string
  addressLabel: string
  safeguardLabel: number
  categoryOfRecipientsLabel: number
  transferRetentionPeriodLabel: number
  safeguardOptions: string[]
  transferRetentionPeriodOptions: string[]

  questions: any[]
}

export type EntityType = {
  id: string
  name: string
}

export enum RopaRoles {
  dpo = 'Data_Privacy_Officer',
  dso = 'Datasource_Owner'
}
export enum RopaCollaborationStatus {
  notStarted = 'notStarted',
  inProgress = 'inProgress',
  completed = 'completed'
}
// ROPA reports
export enum RopaReportStatuses {
  open = 'open',
  completed = 'completed'
}
export interface RopaReport {
  id: string
  name: string
  status?: string
  createdAt: string
  generatedOn?: string
  processName?: string
  processOwner?: string
  dataSources?: {
    dataSourceName: string
    dataSourceType: DATA_SOURCE_TYPES
    status: string
    isRemote: boolean
  }[]
  dpo?: string
}

export interface RopaRequestConversation {
  id: string
  createdBy: string
  comment: string
  createdAt: string
}

export interface ProcessDefinition {
  id: string
  name: string
  createdBy: string
  description: string
  processGroups?: string[]
  processActivities?: string[]
  definedOn?: string
  dataSources: { id: string; type: DATA_SOURCE_TYPES; name: string }[]
}

export interface NameIdSummary {
  id: string
  name: string
  type?: string
  isUpdated?: boolean
}

export interface DefinitionInfo {
  name: string
  attributesCount: number
  attributesFoundCount: number
  classificationsCount: number
  classificationsFoundCount: number
  entityTypesCount: number
  entityTypesFoundCount: number
  datasourcesCount: number
  datasourcesFoundCount: number
  processOwnerName: string
  processOwnerEmail: string
  reportStatus: string
  dueDate: string
  resposeStatus?: boolean
}

export type RopaPrintDataSource = {
  id: string
  name: string
  type: DATA_SOURCE_TYPES
  ropaDataSourceType: string
  questionnaire: RopaQuestionnaire
  datasourceInfo: RopaDataSourceInfo
}

export type RopaReportProcessPrint = Pick<
  RopaProcess,
  | 'name'
  | 'dpoEmail'
  | 'owner'
  | 'description'
  | 'purposeIds'
  | 'processGroups'
  | 'lawfulBasis'
  | 'purpose'
  | 'automatedProcessing'
  | 'automatedProcessingDescription'
>

export interface RopaReportQuestion {
  question: string
  answer: string[]
}

export interface RopaReportProcessorInfo {
  managerType: string
  email: string
  name: string
  address: string
  contact: string
  roleType: string
  emailProperties: FieldProperty[]
  nameProperties: FieldProperty[]
  addressProperties: FieldProperty[]
  contactProperties: FieldProperty[]
}

export interface RopaReportPrint {
  id?: string
  name: string
  process: RopaReportProcessPrint & {
    nameLabel: string
    descriptionLabel: string
    purposeLabel: string
    lawfulBasisLabel: string
    automatedProcessingDescriptionLabel: string
    automatedProcessingLabel: string
    processGroupsLabel: string
    questions: RopaReportQuestion[]
    controllerQuestions: RopaReportQuestion[]
  }
  topics: {
    dataElements?: {
      name?: string
      attributes?: { attributeName: string; sensitivity: string; attributeSets: string[] }[]
      specialDataElements?: { attributeName: string; sensitivity: string }[]
      lawfulBasis?: string[]
      questions?: RopaReportQuestion[]
    }
    dataSubjects: {
      name?: string
      specialCategories?: string[]
      special?: boolean
      specialCategoriesLabel?: string
      categories?: string[]
      categoriesLabel?: string
      lawfulBasis?: string[]
      lawfulBasisLabel?: string
      questions?: RopaReportQuestion[]
    }
    dataRetention: {
      name?: string
      dataRetention?: {
        durationType?: string
        durationCount?: number
        triggerEvent?: string
        attributeSets?: string[]
      }[]
      questions?: RopaReportQuestion[]
    }
    safeguards: {
      name?: string
      safeguards?: string[]
      questions?: RopaReportQuestion[]
      safeguardsLabel?: string
    }
    transfers: {
      name?: string
      crossBorder?: {
        safeguards: string[]
        safeguardsProperties: FieldProperty[]
        organisation: string
        organisationProperties: FieldProperty[]
        email: string
        emailProperties: FieldProperty[]
        country: string
        countryProperties: FieldProperty[]
      }[]
      thirdParty?: {
        safeguards: string[]
        safeguardsProperties: FieldProperty[]
        name: string
        nameProperties: FieldProperty[]
        email: string
        emailProperties: FieldProperty[]
        address: string
        addressProperties: FieldProperty[]
      }[]
    }
    dataFlow?: RopaDataFlowDataSource[]
    customTopics?: { name: string; questions: RopaReportQuestion[] }[]
  }
  processors: RopaReportProcessorInfo[][]
  controllers: RopaReportProcessorInfo[][]
}
export interface RopaFieldCustomInfo {
  label?: string
  options: NameIdSummary[]
  optional?: boolean
}

export interface SectionCommonProps {
  description?: string
  setDescription?: (text: string) => void
}

interface TopicIds {
  attributesTopicId: string
  entityTypesTopicId: string
  classificationsTopicId: string
  retentionPeriodTopicId: string
  dataLinkTopicId: string
  purposeTopicId: string
  datatransferDetailsTopicId: string
  safetyMeasureTopicId: string
}

export enum RopaRequestStatuses {
  draft = 'draft',
  complete = 'complete',
  sent = 'sent',
  resent = 'resent',
  revoked = 'revoked',
  rejected = 'rejected',
  awaiting = 'awaiting',
  incomplete = 'incomplete'
}
export enum ResponseSubmittedBy {
  dso = 'DSO',
  dpo = 'DPO'
}
export interface RopaRequestInfo extends TopicIds {
  id?: string
  attributesCount?: number
  reportId: string
  isDraft: boolean
  attributesFoundCount?: number
  classificationsCount?: number
  classificationsFoundCount?: number
  entityTypesCount?: number
  entityTypesFoundCount?: number
  datasourcesCount?: number
  datasourcesFoundCount?: number
  processOwnerName?: string
  processOwnerEmail?: string
  reportStatus?: string
  submittedBy?: ResponseSubmittedBy
  requestReceivedDate?: string
  attributes?: NameIdSummary[]
  droppedAttributes?: NameIdSummary[]
  addedAttributes?: NameIdSummary[]
  classifications?: NameIdSummary[]
  droppedClassifications?: NameIdSummary[]
  addedClassifications?: NameIdSummary[]
  entityTypes?: NameIdSummary[]
  droppedEntityTypes?: NameIdSummary[]

  dataSourceId: string
  dataSourceName: string
  dataSourceType: string
  dataSourceOwner: string

  questionnaire?: RopaQuestionnaire

  name?: string
  process?: string
  status: RopaRequestStatuses
  taskId: string
  requestDueDate: string
  requestReceivedAt: string
  definitionId: string
  definitionName: string
  definitionOwner: string

  purpose: string
  purposeLabel: string
  purposeOptions: string[]
  safetyMeasure: string
  safetyMeasureLabel: string
  safetyMeasureOptions: string[]
  retentionPeriod: string
  retentionPeriodOptions: string[]
  retentionPeriodLabel: string
  retentionPeriodDescription: string
  dataTransferDetails: IDataTranferInfo[]
  purposeDescription: string | null
  safetyMeasureDescription: string | null
}

export interface RopaResponseInfo extends TopicIds {
  attributeIds: string[]
  classificationIds: string[]
  entityIds: string[]
  retentionPeriod: string
  purpose: string
  safetyMeasure: string
  datasourceResponseId?: string
  purposeDescription: string | null
  safetyMeasuresDescription: string | null
  retentionPeriodDescription: string | null
  dataTransferInfo: IDataTranferInfo[]
}

export interface RequestSummary {
  id: string
  name: string
  category: string
  dpo: string
  receivedAt: string
  dueDate: string
  status: string
}

export const ACTION_FETCH_REQUEST_CONVERSATION = 'ropa/request/conversation'
export const fetchRopaRequestConversation = createAsyncThunk(
  ACTION_FETCH_REQUEST_CONVERSATION,
  async (id: string) => {
    const raw = await graphqlService.execute(getRopaRequestConversation(id))
    return mapGetRopaRequestConversation(raw)
  }
)

export type RopaProcessesParams = {
  [PAGE]?: number
  id?: string
  isSystemDefined?: boolean
}

export const ACTION_FETCH_COMPANY_ROPA = 'ropa/fetch/company-ropa'
export const fetchListCompanyRopa = createAsyncThunk(ACTION_FETCH_COMPANY_ROPA, async () => {
  return { list: mockedCompaniesList, total: mockedCompaniesList.length }
})

export const ACTION_FETCH_DS_RESPONSE = 'ropa/fetch/dataSourceResponse'
export const fetchRopaDataSourceResponse = createAsyncThunk(
  ACTION_FETCH_DS_RESPONSE,
  async (requestId: string) => {
    const raw = await graphqlService.execute(queryRopaDataSourceResponse(requestId))
    return mapQueryRopaDataSourceResponse(raw)
  }
)

export const ACTION_FETCH_ROPA_NAME = 'ropa/fetch/ropa-name'
export const fetchRopaNameById = createAsyncThunk(
  ACTION_FETCH_ROPA_NAME,
  async (reportId: string) => {
    const raw = await graphqlService.execute(getRopaNameById(reportId))
    return mapGetRopaNameById(raw)
  }
)

export const ACTION_FETCH_REPORT_INFO = 'ropa/fetch/report-info'
export const fetchRopaReportById = createAsyncThunk(
  ACTION_FETCH_REPORT_INFO,
  async (id: string) => {
    const raw = await graphqlService.execute(getReportById(id))
    return mapGetReportById(raw)
  }
)

export type RopaFetchReportsParams = {
  [PAGE]?: number
  [PAGE_SIZE]?: number
  processId: string
}
export const ACTION_FETCH_REPORTS = 'ropa/fetch/reports'
export const fetchRopaProcessReports = createAsyncThunk(
  ACTION_FETCH_REPORTS,
  async (params: RopaFetchReportsParams, { getState }) => {
    const queryParams = {
      ...params,
      ...getGlobalParams(getState() as RootState)
    }
    const raw = await graphqlService.execute(queryRopaProcessReports(queryParams))
    return mapQueryRopaProcessReports(raw)
  }
)

export const ACTION_ROPA_DELETE_REPORT = 'ropa/deleteReport'
export const deleteRopaReport = createAsyncThunk(
  ACTION_ROPA_DELETE_REPORT,
  async ({ id }: { id: string }): Promise<{ id: string }> => {
    await graphqlService.execute(queryDeleteRopaReport(id))
    return { id }
  }
)

export const ACTION_ROPA_GENERATE_REPORT = 'ropa/generateReport'
export const generateRopaReport = createAsyncThunk(
  ACTION_ROPA_GENERATE_REPORT,
  async (processId: string): Promise<RopaReportPrint> => {
    const raw = await graphqlService.execute(queryGenerateRopaReport(processId))
    return mapQueryGenerateRopaReport(raw)
  }
)

export const ACTION_ROPA_REVOKE_ACCESS = 'ropa/revoke-access'
export const revokeAccess = createAsyncThunk(
  ACTION_ROPA_REVOKE_ACCESS,
  async (requestId: string): Promise<{ requestId: string }> => {
    await graphqlService.execute(queryRevokeAccess(requestId))
    return { requestId }
  }
)

export const ACTION_ROPA_DELETE_TOPIC = 'ropa/deleteTopic'
export const deleteCustomTopic = createAsyncThunk(
  ACTION_ROPA_DELETE_TOPIC,
  async (id: string): Promise<{ id: string }> => {
    await graphqlService.execute(queryDeleteCustomTopic(id))
    return { id }
  }
)

export interface FetchRequestsParams {
  page: number
  dsoEmail?: string
  filters?: FilterParams
  complete?: boolean
}

export const ACTION_FETCH_LIST_ROPA_REQUESTS = 'ropa/fetch/list-ropa-requests'
export const fetchListRopaRequests = createAsyncThunk(
  ACTION_FETCH_LIST_ROPA_REQUESTS,
  async (params: FetchRequestsParams) => {
    const raw = await graphqlService.execute(getListRopaRequests(params))
    return mapGetListRopaRequests(raw)
  }
)

export interface FetchDatasourceRequestsParams {
  reportId: string
  dsoEmail?: string
}
export const ACTION_FETCH_LIST_DATASOURCE_REQUESTS = 'ropa/fetch/datasource-requests'
export const fetchRopaDatasourceRequests = createAsyncThunk(
  ACTION_FETCH_LIST_DATASOURCE_REQUESTS,
  async (params: FetchDatasourceRequestsParams) => {
    const raw = await graphqlService.execute(getRopaDatasourceRequests(params))
    return mapGetRopaDatasourceRequests(raw)
  }
)

export interface RejectRequestParams {
  reportId: string
  dsoEmail: string
  reason?: string
}
export const ACTION_REJECT_REQUEST = 'ropa/reject/request'
export const rejectRopaRequest = createAsyncThunk(
  ACTION_REJECT_REQUEST,
  async (params: RejectRequestParams) => {
    await graphqlService.execute(queryRejectRequest(params))
  }
)

export interface RemoveRequestParams {
  requestId: string
  reason?: string
}
export const ACTION_REMOVE_REQUEST = 'ropa/remove/request'
export const removeRopaRequest = createAsyncThunk(
  ACTION_REMOVE_REQUEST,
  async (params: RemoveRequestParams) => {
    await graphqlService.execute(queryRemoveRequest(params))
  }
)

export const ACTION_FETCH_ROPA_REPORTS = 'ropa/fetch/ropa-reports'
export const fetchRopaReports = createAsyncThunk(
  ACTION_FETCH_ROPA_REPORTS,
  async (params: FetchRequestsParams) => {
    const raw = await graphqlService.execute(getRopaReports(params))
    return mapGetRopaReports(raw)
  }
)

export type SendRopaRequestDataSourceInput = {
  dsoEmail: string
  datasourceId?: string
}
export interface ISendRopaRequestParams {
  [REPORT_ID]: string
  datasources: SendRopaRequestDataSourceInput[]
  description?: string
  dueDate?: string
  emailBody?: string
  role: string
}
export const ACTION_SEND_ROPA_REQUEST = 'ropa/send-request'
export const sendRopaRequest = createAsyncThunk(
  ACTION_SEND_ROPA_REQUEST,
  async (params: ISendRopaRequestParams, { rejectWithValue }) => {
    try {
      const result = await graphqlService.execute(sendRopaDatasourceRequest(params))
      return result
    } catch (error) {
      return rejectWithValue({ statusMessage: 'dataSources.error.SomethingWentWrong' })
    }
  }
)

export interface IAddRopaTaskParams {
  reportId: string
  datasourceOwner: string
  datasourceType: string
  datasourceName: string
  description: string
  dueDate: string
}

export enum ROPA_FIELD {
  processName = 'processName',
  processDescription = 'processDescription',
  lawfulBasis = 'lawfulBasis',
  purpose = 'purpose',
  processGroup = 'processGroup',
  isAutomatedProcessingEnabled = 'automatedProcessing',
  automatedProcessingDescription = 'automatedProcessingDescription',

  subjectCategories = 'subjectCategories',
  specialSubjectCategories = 'specialSubjectCategories',
  specialSubjectLegalBasis = 'specialSubjectLegalBasis',
  safeguards = 'safeguards',

  thirdpartyTransferName = 'thirdpartyTransferName',
  thirdpartyTransferEmail = 'thirdpartyTransferEmail',
  thirdpartyTransferAddress = 'thirdpartyTransferAddress',
  thirdpartyTransferSafeguards = 'thirdpartyTransferSafeguards'
}

/** Questionnaire constructor */
export enum QuestionnaireTopicTypes {
  attributes = 'attributes',
  classificationObjects = 'classificationObjects',
  entityTypes = 'entityTypes',
  purpose = 'purpose',
  retentionPeriod = 'retentionPeriod',
  dataLink = 'dataLink',
  dataTransferDetails = 'dataTransferDetails',
  safetyMeasures = 'safetyMeasures',
  custom = 'custom',
  role = 'role',
  processDetails = 'processDetails',
  dataSubjects = 'dataSubjects'
}

export enum RopaElementTypes {
  textarea = 'SINGLE',
  dropdown = 'MULTIPLE'
}

export type RopaQuestionnaireTopicQuestion = {
  questionId: string
  statement: string
  responseType: RopaElementTypes
  responses?: string[]
  isOptional?: boolean
  value?: string | boolean | number
  _isDeleted?: boolean
}

export type RopaQuestionnaireTopic = {
  id?: string
  topicId: string
  description?: string
  descriptionDeleted?: boolean
  questions?: RopaQuestionnaireTopicQuestion[]
  optional?: boolean
  name?: string
  _type?: QuestionnaireTopicTypes
  _isComplete?: boolean
  _isUpdated?: boolean
  _isCreated?: boolean
  _isDeleted?: boolean
  customValue?: string
  dropdownField?: string
  hasDefaultValues?: boolean
}

export interface RopaQuestionnaireTopicForBuilder extends RopaQuestionnaireTopic {
  _type?: QuestionnaireTopicTypes
  name?: string
}

export type RopaAttribute = {
  id: string
  name: string
  instanceCount: number
  sensitivityLabel?: string
  totalDataSources?: number
  dataSources?: Array<{ id: string; name: string }>
}
export interface RopaAttributes extends RopaQuestionnaireTopic {
  attributeIds: string[]
  attributes: RopaAttribute[]
  allowModification?: boolean
}
export type RopaClassification = {
  id: string
  name: string
}
export interface RopaClassifications extends RopaQuestionnaireTopic {
  classificationIds: string[]
  classifications: RopaClassification[]
  allowModification?: boolean
}
export type RopaEntityType = {
  id: string
  name: string
}
export interface RopaEntityTypes extends RopaQuestionnaireTopic {
  entityIds: string[]
  entityTypes: RopaEntityType[]
  allowModification?: boolean
}
export interface RopaPurpose extends RopaQuestionnaireTopic {
  type: string
  options?: string[]
  label: string
  selection?: string
}

export interface RopaProcessorRole extends RopaQuestionnaireTopic {
  type: string
  options?: string[]
  label: string
  selection?: string
}

export interface RopaLawfulBasis extends RopaQuestionnaireTopic {
  type: string
  options?: string[]
  label: string
  selection?: string
}

export interface RopaProcessGroup extends RopaQuestionnaireTopic {
  type: string
  options?: string[]
  label: string
  selection?: string
}
export interface RopaRetentionPeriod extends RopaQuestionnaireTopic {
  retentionPolicy: string
  retentionPolicyOptions: string[]
  label: string
}

export interface RopaDataSourceDetails {
  processingStage?: DataSourceProcessingStages
  dataSourceId: string
  dataSourceType: string
  dataSourceName: string
  dataSourceOwner?: string
  dataSourceLastSynced?: string
  dataSourceLastUpdated?: string
  datasourceLocation?: DataSourceLocations
  description?: string
  descriptionDeleted?: boolean
}
export interface RopaDataLink extends RopaQuestionnaireTopic {
  processingStage: DataSourceProcessingStages
  sourceDataSource: RopaDataSourceDetails[]
  targetDataSource: RopaDataSourceDetails[]
}
export interface RopaDataTransferDetails {
  name?: string
  email?: string
  address?: string
  contact?: string
  org?: string
  country?: string

  safeguards?: string[]
  safeguardIds?: string[]
}
export interface RopaDataTransfer extends RopaQuestionnaireTopic {
  topicId: string
  details: RopaDataTransferDetails[]
}
export interface RopaSafetyMeasures extends RopaQuestionnaireTopic {
  safetyMeasures: string
  safetyMeasuresOptions: string[]
  label: string
}
export type RopaCustomTopic = RopaQuestionnaireTopic
export interface RopaProcessDetailsTopic extends RopaQuestionnaireTopic {
  processGroup: { label: string; value?: string; options?: string[] }
  processName: { label: string; value?: string }
  processDescription: { label: string; value?: string }
  purpose: { label: string; value?: string[]; options?: string[] }
  lawfulBasis: { label: string; value?: string[]; options?: string[] }
  isAutomatedProcessingEnabled: { label: string; value?: boolean }
  automatedProcessingDescription: { label: string; value?: string }
}
export interface RopaDataSubjectDetailsTopic extends RopaQuestionnaireTopic {
  dataSubjectIds?: string[]
  dataSubjectProperties?: FieldProperty[]

  specialDataSubjectIds?: string[]
  specialDataSubjectsProperties?: FieldProperty[]

  legalBasisIds?: string[]
  legalBasisProperties?: FieldProperty[]
}
export interface RopaSafetyDetailsTopic extends RopaQuestionnaireTopic {
  safeguardProperties?: FieldProperty[]
  safeguardIds?: string[]
}
export interface RopaTransferDetailsTopic extends RopaQuestionnaireTopic {
  thirdParty: {
    isThirdParty?: boolean
    thirdPartyProperties?: FieldProperty[]
    details: RopaDataTransferDetails[]
  }
  crossBorder: {
    isCrossBorder?: boolean
    CrossBorderProperties?: FieldProperty[]
    details: RopaDataTransferDetails[]
  }
}

export interface RopaQuestionnaire {
  name?: string
  description?: string
  attributes?: RopaAttributes
  classificationObjects?: RopaClassifications
  entityTypes?: RopaEntityTypes
  purpose?: RopaPurpose
  processGroup?: RopaProcessGroup
  lawfulBasis?: RopaLawfulBasis
  retentionPeriod?: RopaRetentionPeriod
  dataLink?: RopaDataLink
  dataTransferDetails?: RopaDataTransfer
  safetyMeasures?: RopaSafetyMeasures
  topics?: RopaCustomTopic[]
  processDetails?: RopaProcessDetailsTopic
}

export type RopaAttributesParams = {
  dataSourceIds: string[]
  print?: boolean
  forDataElements?: boolean
}
export const ACTION_ROPA_ATTRIBUTE_SETS = 'ropa/attributeSets'
export const fetchRopaAttributes = createAsyncThunk(
  ACTION_ROPA_ATTRIBUTE_SETS,
  async (params: RopaAttributesParams) => {
    const resultRaw = await graphqlService.execute(queryRopaAttributesSets(params))
    return mapQueryRopaAttributesSets(resultRaw, params)
  }
)

export type RopaClassificationsParams = {
  dataSourceIds: string[]
}
export const ACTION_ROPA_CLASSIFICATIONS = 'ropa/classifications'
export const fetchRopaClassifications = createAsyncThunk(
  ACTION_ROPA_CLASSIFICATIONS,
  async (params: RopaClassificationsParams) => {
    const raw = await graphqlService.execute(queryRopaClassifications(params))
    const classesWithDetectedInstances = mapQueryRopaClassifications(raw)
    const classesWithIds = await apiService.getClassificationsList()

    return classesWithIds.map((cl) => {
      const matched = classesWithDetectedInstances.find((c) => c.name === cl.class)

      return {
        id: cl?.id || '',
        class: cl.class,
        subclasses: cl.subclasses || [],
        systemDefined: true,
        instancesCount: params.dataSourceIds.length > 0 ? matched?.count || 0 : 0
      }
    })
  }
)

export const ACTION_ROPA_REPORT_CLASSIFICATIONS = 'ropa/report-classifications'
export const fetchRopaReportClassifications = createAsyncThunk(
  ACTION_ROPA_REPORT_CLASSIFICATIONS,
  async (datasourceIds: string[]) => {
    const classifications = await Promise.all(
      datasourceIds.map((id) =>
        graphqlService.execute(queryRopaClassifications({ dataSourceIds: [id] }))
      )
    )
    const classificationsMapped: ReportClassification[] = classifications.map(
      (classification, index) => ({
        datasourceId: datasourceIds[index],
        classifications: mapQueryRopaClassifications(classification)
      })
    )

    return classificationsMapped
  }
)

export type RopaTicketsParams = {
  page: number
  pageSize?: number
  emailId?: string
  statuses?: string[]
  filters?: FilterParams
}
export const ACTION_ROPA_TICKETS = 'ropa/tickets'
export const fetchRopaCollaborations = createAsyncThunk(
  ACTION_ROPA_TICKETS,
  async (params: RopaTicketsParams, { getState }) => {
    const queryParams = { ...params, ...getGlobalParams(getState() as RootState) }
    const raw = await graphqlService.execute(queryRopaTickets(queryParams))
    return mapQueryRopaTickets(raw)
  }
)

export const ROPA_COLLABORATION_DELETE = 'ropa/delete-collaboration'
export const deleteRopaCollaboration = createAsyncThunk(
  ROPA_COLLABORATION_DELETE,
  async (id: string) => {
    await graphqlService.execute(mutationDeleteCollaboration(id))
  }
)

export type RopaEntityTypesParams = {
  dataSourceIds: string[]
}
export const ACTION_ROPA_ENTITIES = 'ropa/entityTypes'
export const fetchRopaEntityTypes = createAsyncThunk(
  ACTION_ROPA_ENTITIES,
  async (params?: RopaEntityTypesParams) => {
    const raw = await graphqlService.execute(queryRopaEntityTypes(params))
    return mapQueryRopaEntityTypes(raw)
  }
)
export type RopaPurposesParams = {
  dataSourceIds: string[]
}
export type RopaPurposeOption = {
  id: string
  type: string
}
export const ACTION_ROPA_PURPOSES = 'ropa/purposes'
export const fetchPurposes = createAsyncThunk(ACTION_ROPA_PURPOSES, async () => {
  const raw = await graphqlService.execute(queryRopaListValues('PURPOSE'))
  return mapQueryRopaListValues(raw)
})

export const ACTION_ROPA_LAWFUL_BASIS = 'ropa/lawful-basis'
export const fetchLawfulBasis = createAsyncThunk(ACTION_ROPA_LAWFUL_BASIS, async () => {
  const raw = await graphqlService.execute(queryRopaListValues('LAWFUL_BASIS'))
  return mapQueryRopaListValues(raw)
})

export const ACTION_ROPA_SUBJECT_CATEGORIES = 'ropa/subject-categories'
export const fetchSubjectCategories = createAsyncThunk(ACTION_ROPA_SUBJECT_CATEGORIES, async () => {
  const raw = await graphqlService.execute(queryRopaListValues('DATA_SUBJECT'))
  return mapQueryRopaListValues(raw)
})

export const ACTION_ROPA_SPECIAL_SUBJECT_CATEGORIES = 'ropa/subject-subject-categories'
export const fetchSpecialSubjectCategories = createAsyncThunk(
  ACTION_ROPA_SPECIAL_SUBJECT_CATEGORIES,
  async () => {
    const raw = await graphqlService.execute(queryRopaListValues('SPECIAL_DATA_SUBJECT'))
    return mapQueryRopaListValues(raw)
  }
)

export const ACTION_ROPA_SAFETY_MEASURES = 'ropa/safetyMeasures'
export const fetchRopaSafetyMeasures = createAsyncThunk(ACTION_ROPA_SAFETY_MEASURES, async () => {
  const raw = await graphqlService.execute(queryRopaListValues('SAFEGUARDS'))
  return mapQueryRopaListValues(raw)
})

export type RopaRetentionPolicyOption = {
  id: string
  name: string
}
export type RopaRetentionPeriodParams = {
  dataSourceIds: string[]
}
export const ACTION_ROPA_RETENTION_POLICIES = 'ropa/retentionPolicies'
export const fetchRetentionPolicies = createAsyncThunk(
  ACTION_ROPA_RETENTION_POLICIES,
  async (params?: RopaRetentionPeriodParams) => {
    params
    return {
      retentionPolicies: [
        { id: 'Not Applicable', name: 'Not Applicable' },
        { id: 'Forever', name: 'Forever' }
      ]
    }
  }
)

export type RopaDataSourceProcessingStageOption = {
  id: string
  name: DataSourceProcessingStages
}
export const ACTION_ROPA_PROCESSING_STAGES = 'ropa/processingStages'
export const fetchProcessingStages = createAsyncThunk(ACTION_ROPA_PROCESSING_STAGES, async () => {
  return [
    { id: '1', name: DataSourceProcessingStages.collection },
    { id: '2', name: DataSourceProcessingStages.processing },
    { id: '3', name: DataSourceProcessingStages.storage },
    { id: '4', name: DataSourceProcessingStages.exchange },
    { id: '5', name: DataSourceProcessingStages.archival }
  ]
})

export type RopaRecipientCategoryOption = {
  id: string
  name: string
}
export type RopaRetentionTransferPeriodOption = {
  id: string
  name: string
}
export type RopaDataTransfersParams = {
  dataSourceIds: string[]
}

export type SourcesForRopaReport = {
  id: string
  type: 'process' | 'group' | 'company'
}

/** Processes */
export enum ProcessingActivityManagerTypes {
  controller = 'CONTROLLER',
  jointController = 'JOINT_CONTROLLER',
  processor = 'PROCESSOR',
  jointProcessor = 'JOINT_PROCESSOR'
}
export enum ProcessingActivityManagerFilters {
  controller = 'controller',
  jointController = 'jointController',
  processor = 'processor',
  jointProcessor = 'subProcessor'
}
export enum ProcessingActivityRoleTypes {
  organization = 'ORGANISATION',
  dpo = 'DPO',
  representative = 'REPRESENTATIVE'
}
export type ProcessingActivityManagerDetails = {
  name: string
  email: string
  contact: string
  address: string
  roleType: ProcessingActivityRoleTypes
}
export type RopaProcessingActivityDetails = {
  _id?: string
  _isOpened?: boolean
  order?: number
  originalIndex?: number
  managerType: ProcessingActivityManagerTypes
  details: ProcessingActivityManagerDetails[]
}

export enum RopaTransferDetailsTypeEnum {
  CROSS_BORDER = 'cross-border',
  THIRD_PARTY = 'third-party'
}

export type RopaTransferDetailsType =
  | RopaTransferDetailsTypeEnum.CROSS_BORDER
  | RopaTransferDetailsTypeEnum.THIRD_PARTY

export type RopaTransferDetails = {
  _id?: string
  _isOpened?: boolean
  details?: {
    name?: string
    email?: string
    safeguards?: string[]
    address?: string
  }
}

export type RopaProcessDetails = {
  isController: boolean
  processingActivityDetails: RopaProcessingActivityDetails[]
}

export type RopaDataSourceInfo = {
  dsoEmail: string
  id?: string
  datasource?: {
    id: string
    name: string
    type: DATA_SOURCE_TYPES
    location?: string
    createdBy?: string
    lastSyncedTime?: string
    status?: DATASOURCE_STATUS
    ropaDatasourceType?: DATASOURCE_STATUS
  }
}

export type RopaDataElements = {
  attributes?: string[]
  specialAttributes?: string[]
  datasourceIds?: string[]
  purposeForSpecialAttributes?: string[]
}
export type RopaProcessTemplate = {
  id?: string
  name?: string
}
export interface RopaRetentionPolicy {
  id: string
  duration: string
  durationType: string
  triggerEvent: string
  scope: string[]
}

export type RopaTransferInfo = {
  thirdParty?: boolean
  thirdPartyList?: RopaTransferDetails[]
  crossBorder?: boolean
  crossBorderList?: RopaTransferDetails[]
}
export type FieldProperty = {
  key: string
  value: string[]
}
export type RopaProcess = {
  id: string
  owner?: string
  dpoEmail?: string
  templateId?: string

  name?: string
  nameProperties?: FieldProperty[]

  description?: string
  descriptionProperties?: FieldProperty[]

  purpose?: string[]
  purposeIds?: string[]
  purposeProperties?: FieldProperty[]

  processGroups?: string[]
  processGroupIds?: string[]
  processGroupsProperties?: FieldProperty[]

  lawfulBasis?: string[]
  lawfulBasisIds?: string[]
  lawfulBasisProperties?: FieldProperty[]

  automatedProcessing?: boolean
  automatedProcessingProperties?: FieldProperty[]
  automatedProcessingDescription?: string
  automatedProcessingDescriptionProperties?: FieldProperty[]

  subjectCategories?: string[]
  specialSubjectCategories?: string[]
  specialSubjectLegalBasis?: string[]
  customPurpose?: string
  customLawfulBasis?: string
  createdAt?: string
  updatedAt?: string
  systemDefined?: boolean
  reportsCount?: number
  processes?: RopaProcess[]
  _isNew?: boolean
  departments?: string[]
  processDetails?: RopaProcessDetails
  datasourcesInfo?: RopaDataSourceInfo[]
  dataElements?: RopaDataElements
  dataRetentionPolicies?: RopaRetentionPolicy[]
  safeguards?: string[]
  transfers?: RopaTransferInfo
  safetyMeasures?: RopaSafetyMeasures
  isDraft?: boolean
  questionnaire?: RopaQuestionnaire
  reportGeneratedOn?: number
}

export type RopaResponseNotification = { text: string; severity: SEVERITY_LEVEL }
export type RopaDsoResponse = {
  dataSourceResponseId?: string
  responseReceiveDate?: string
  requestSentDate?: string
  lastSyncedTime: string
  taskId: string
  dueDate: string
  status: RopaRequestStatuses
  attributesCount: number
  classificationsCount: number
  entityTypesCount: number
  questionnaire: RopaQuestionnaire
  datasourceInfo: RopaDataSourceInfo
  notifications: RopaResponseNotification[]
  reportSubmittedByRole?: ResponseSubmittedBy
}
export type StepCollaborateInfo = {
  attributesCount: number
  isRopaUpdated: boolean
  classificationsCount: number
  entityTypesCount: number
  datasourcesCount: number
  datasourcesRespondedCount: number
  dueDate: string
  ropaName: string
  datasourceResponseId: string
  reportId: string
  status: 'complete' | 'incomplete' | 'draft'
  processSummary: RopaProcess
  alerts: { message: string; severity: SEVERITY_LEVEL }[]
  datasourcesResponse: RopaDsoResponse[]
  datasourceInfoCombined: RopaDataSourceInfo[]
}

export interface RopaRequestCommonParams {
  id: string
  dueDate: string
  receivedAt: string
}
export interface RopaRequest extends RopaRequestCommonParams {
  name: string
  process: string
  totalDatasourcesCompleted: number
  totalDatasources: number
}
export interface RopaDatasourceRequest extends RopaRequestCommonParams {
  dsoEmail: string
  datasourceId: string
  datasourceName: string
  datasourceType: string
  status: string
}
export interface RopaDatasourceRequestInfo {
  ropaName: string
  processName: string
}
export enum RopaConfigAction {
  createProcess = 'createProcess',
  updateProcess = 'updateProcess',
  createGroup = 'createGroup',
  updateGroup = 'updateGroup',
  createCompany = 'createCompany',
  updateCompany = 'updateCompany',
  createTemplate = 'createTemplate',
  updateTemplate = 'updateTemplate',
  cloneTemplate = 'cloneTemplate'
}

export enum RopaDefineProcessSteps {
  processDetails = 1,
  dataElements,
  dataSubjects,
  dataRetention,
  safeguards,
  dataTransfer
}

export enum RopaReportDefineProcessSteps {
  info = 0,
  attributes,
  classifications,
  entityTypes,
  purpose,
  retentionPeriod,
  dataLink,
  dataTransfer,
  safetyMeasures,
  custom
}

export type RopaSelectedProcessParams = {
  id: string
  isCreate?: boolean
}
/** CRUD processes */
export type SaveRopaProcessParams = RopaProcess
export const ACTION_ROPA_CREATE_PROCESS = 'ropa/createProcess'
export const createRopaProcess = createAsyncThunk(
  ACTION_ROPA_CREATE_PROCESS,
  async (process: SaveRopaProcessParams): Promise<RopaProcess> => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    return process
  }
)

export type SaveRopaTemplateParams = RopaProcess
export const ACTION_ROPA_CREATE_TEMPLATE = 'ropa/createTemplate'
export const createRopaTemplate = createAsyncThunk(
  ACTION_ROPA_CREATE_TEMPLATE,
  async (template: SaveRopaTemplateParams): Promise<SaveRopaTemplateParams> => {
    const newTemplate = {
      ...template,
      id: `123a2e645be426c629af16a${Math.floor(Math.random() * 90) + 10}`,
      ropaTopics: template.questionnaire
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    return newTemplate
  }
)

export const ACTION_ROPA_UPDATE_PROCESS = 'ropa/updateProcess'
export const updateRopaProcess = createAsyncThunk(
  ACTION_ROPA_UPDATE_PROCESS,
  async (process: SaveRopaProcessParams): Promise<RopaProcess> => {
    const updateTopicQueryArr: string[] = [queryUpdateRopaProcess(process)]
    let deleteQuestionQueryArr: string[] = []

    const getQuestionsToDeleteQueries = (topic: RopaQuestionnaireTopic) => {
      return (
        topic?.questions
          ?.filter((q) => q._isDeleted)
          .map(({ questionId }) => queryDeleteTopicQuestion(questionId)) || []
      )
    }

    if (process.questionnaire?.attributes?._isUpdated) {
      updateTopicQueryArr.push(queryUpdateQuestionnaireAttributeSets(process))
      const queries = getQuestionsToDeleteQueries(process.questionnaire.attributes)
      deleteQuestionQueryArr = [...deleteQuestionQueryArr, ...queries]
    }
    if (process.questionnaire?.classificationObjects?._isUpdated) {
      updateTopicQueryArr.push(queryUpdateQuestionnaireClassifications(process))
      const queries = getQuestionsToDeleteQueries(process.questionnaire.classificationObjects)
      deleteQuestionQueryArr = [...deleteQuestionQueryArr, ...queries]
    }
    if (process.questionnaire?.entityTypes?._isUpdated) {
      updateTopicQueryArr.push(queryUpdateQuestionnaireEntityTypes(process))
      const queries = getQuestionsToDeleteQueries(process.questionnaire?.entityTypes)
      deleteQuestionQueryArr = [...deleteQuestionQueryArr, ...queries]
    }
    if (process.questionnaire?.purpose?._isUpdated) {
      updateTopicQueryArr.push(queryUpdateQuestionnairePurpose(process))
      const queries = getQuestionsToDeleteQueries(process.questionnaire.purpose)
      deleteQuestionQueryArr = [...deleteQuestionQueryArr, ...queries]
    }
    if (process.questionnaire?.retentionPeriod?._isUpdated) {
      updateTopicQueryArr.push(queryUpdateQuestionnaireRetentionPeriod(process))
      const queries = getQuestionsToDeleteQueries(process.questionnaire.retentionPeriod)
      deleteQuestionQueryArr = [...deleteQuestionQueryArr, ...queries]
    }
    if (process.questionnaire?.dataLink?._isUpdated) {
      updateTopicQueryArr.push(queryUpdateQuestionnaireDataLink(process))
      const queries = getQuestionsToDeleteQueries(process.questionnaire.dataLink)
      deleteQuestionQueryArr = [...deleteQuestionQueryArr, ...queries]
    }
    if (process.questionnaire?.dataTransferDetails?._isUpdated) {
      updateTopicQueryArr.push(queryUpdateQuestionnaireDataTransferDetails(process))
      const queries = getQuestionsToDeleteQueries(process.questionnaire.dataTransferDetails)
      deleteQuestionQueryArr = [...deleteQuestionQueryArr, ...queries]
    }
    if (process.questionnaire?.safetyMeasures?._isUpdated) {
      updateTopicQueryArr.push(queryUpdateQuestionnaireSafetyMeasures(process))
      const queries = getQuestionsToDeleteQueries(process.questionnaire.safetyMeasures)
      deleteQuestionQueryArr = [...deleteQuestionQueryArr, ...queries]
    }

    process.questionnaire?.topics
      ?.filter(({ _isUpdated, _isCreated }) => _isUpdated && !_isCreated)
      .forEach((t) => {
        updateTopicQueryArr.push(queryUpdateQuestionnaireCustomTopic(process.id, t))
        const queries = getQuestionsToDeleteQueries(t)
        deleteQuestionQueryArr = [...deleteQuestionQueryArr, ...queries]
      })

    const customTopicsToDelete =
      process.questionnaire?.topics?.filter(
        ({ _isDeleted, _isCreated }) => _isDeleted && !_isCreated
      ) || []
    const customTopicsToAdd =
      process.questionnaire?.topics?.filter(({ _isCreated }) => _isCreated) || []
    // update topics
    await Promise.all(updateTopicQueryArr.map((query) => graphqlService.execute(query)))

    // remove questions
    await Promise.all(deleteQuestionQueryArr.map((query) => graphqlService.execute(query)))

    //remove topics
    customTopicsToDelete.length > 0 &&
      (await Promise.all(
        customTopicsToDelete.map(({ topicId }) =>
          graphqlService.execute(queryDeleteCustomTopic(topicId))
        )
      ))

    // Add new topics
    customTopicsToAdd.length > 0 &&
      (await Promise.all(
        customTopicsToAdd.map((topic) =>
          graphqlService.execute(querySaveCustomTopic(topic, process.id))
        )
      ))
    return process
  }
)

export const ACTION_ROPA_DELETE_PROCESS = 'ropa/deleteProcess'
export const deleteRopaProcess = createAsyncThunk(
  ACTION_ROPA_DELETE_PROCESS,
  async ({ id }: { id: string }): Promise<{ id: string }> => {
    await graphqlService.execute(queryDeleteRopaProcess(id))
    return { id }
  }
)

export const ACTION_ROPA_FETCH_COLLABORATE_INFO = 'ropa/collaborateInfo/fetch'
export const fetchRopaCollaborateInfo = createAsyncThunk(
  ACTION_ROPA_FETCH_COLLABORATE_INFO,
  async (id: string) => {
    const raw = await graphqlService.execute(getRopaCollaborateInfo(id))
    return mapGetRopaCollaborateInfo(raw)
  }
)

export const ACTION_ROPA_MARK_COMPLETE = 'ropa/complete/mark'
export const completeRopa = createAsyncThunk(ACTION_ROPA_MARK_COMPLETE, async (id: string) => {
  await graphqlService.execute(markRopaAsComplete(id))
})

export interface SendRopaMessageParams {
  topicId: string
  comment: string
  createdBy: string
}
export const ACTION_ROPA_SEND_MESSAGE = 'ropa/message/send'
export const sendRopaMessage = createAsyncThunk(
  ACTION_ROPA_SEND_MESSAGE,
  async (params: SendRopaMessageParams) => {
    await graphqlService.execute(createRopaMessage(params))
  }
)

export interface RopaProcessDataSourcesParams {
  id: string
}
export const ACTION_PROCESS_DATA_SOURCES = 'ropa/processDataSourcess'
export const fetchProcessDataSources = createAsyncThunk(
  ACTION_PROCESS_DATA_SOURCES,
  async (params: RopaProcessDataSourcesParams) => {
    const raw = await graphqlService.execute(queryProcessDataSources(params))
    return mapQueryProcessDataSources(raw)
  }
)

export interface RopaProcessOverviewParams {
  processId: string
}
export const ACTION_PROCESS_OVERVIEW = 'ropa/process-overview'
export const fetchProcessOverview = createAsyncThunk(
  ACTION_PROCESS_OVERVIEW,
  async (params: RopaProcessOverviewParams) => {
    const raw = await graphqlService.execute(queryProcessOverview(params))
    return mapQueryProcessOverview(raw)
  }
)

export const ACTION_COLLABORATION_SCOPE_FETCH = 'ropa/collaboration-scope'
export const fetchCollaborationDetails = createAsyncThunk(
  ACTION_COLLABORATION_SCOPE_FETCH,
  async (params: { id: string; isProcess?: boolean }) => {
    const queryFn = params.isProcess ? queryProcessDetails : queryCollaborationDetails
    const raw = await graphqlService.execute(queryFn(params.id))
    return mapQueryCollaborationDetails(raw)
  }
)

export type RopaReportCustomization = {
  emailBody: string
  emailRecipients: string
  companyName: string
  companyLogo: {
    fileName: string
    file: File | null
  }
}
export interface ReportClassification {
  datasourceId: string
  classifications: ClassificationGroupedItem[]
}
export interface RopaProcessOverview {
  processUpdatedAt: string
  totalDataElements: number
  totalSpecialDataElements: number
  totalDataCategories: number
  totalDataSubjects: number
  isSpecialDataSubjectsEnabled?: boolean
  totalSpecialDataSubjects: number
  oldestReportDate: string
  newestReportDate: string
  totalReports: number
  completionPercentage: number
  collaborators: RopaCollaborator[]
  attributes: {
    name: string
    internalName: string
    sensitivity: string
    attributeInstanceCount: number
  }[]
  dataSources: { name: string; totalAttributes: number; datasourceType: DATA_SOURCE_TYPES }[]
}

export interface RopaProcessCollaboration {
  emailId?: string
  questionnaireInput: RopaQuestionnaireOptionalTopics
}
export interface ProcessCollaborationScope {
  id: string
  isMandatory: boolean
  question: string
}
export interface ProcessCollaborationScopeItem {
  title: string
  questions: ProcessCollaborationScope[]
}
export interface RopaProcessCollaborationScope {
  totalTopics?: number
  topics?: RopaQuestionnaireOptionalTopics
}
export interface RopaTicket {
  id: string
  ticketId: string
  processName: string
  processGroups: string[]
  processOwner: string
  updatedAt?: string
  assignee?: string
  createdAt?: string
  dueDate: string
  status: string
}
export interface RopaCollaboration {
  ropaProcessId?: string
  emailBody?: string
  dueDate?: string
  parentCollaboratorId?: string
  collaboratorQuestionnaire?: RopaProcessCollaboration[]
}
type RopaState = {
  requestConversation?: RopaRequestConversation[]
  overview?: RopaProcessOverview
  requestInfo?: RopaRequestInfo
  requests?: RopaRequest[]
  datasourceRequests?: RopaDatasourceRequest[]
  datasourceRequestInfo?: RopaDatasourceRequestInfo
  totalRequests?: number
  totalDatasourceRequests?: number
  activeDefinition?: DefinitionInfo
  ropaName?: string
  isAccessRevoked?: boolean
  isRequestRejected?: boolean
  isRequestRemoved?: boolean
  lawfulBasis?: NameIdSummary[]
  processTemplates: { sort: SortParams; list?: RopaProcess[]; total?: number }
  processesGallery: {
    sort: SortParams
    list?: RopaProcess[]
    total?: number
  }
  processActivities: {
    sort: SortParams
    list?: RopaProcess[]
    total?: number
  }
  processGroups: {
    sort: SortParams
    list?: RopaProcess[]
    total?: number
  }
  processCompanies: {
    sort: SortParams
    list?: RopaProcess[]
    total?: number
  }
  selectedProcess?: RopaProcess
  questionnaire: {
    name?: string
    description?: string
    topics: RopaQuestionnaireTopic[]
    selectedTopic?: RopaQuestionnaireTopic
    selectedTopicQuestion?: RopaQuestionnaireTopicQuestion
    optionLists: {
      attributeSets?: AttributeSet[]
      attributes?: AttributeSetAttribute[]
      classifications?: Classification[]
      reportClassifications?: ReportClassification[]
      entityTypes?: EntityType[]
      dataSources?: DataSource[]

      purposes?: NameIdSummary[]
      lawfulBasis?: NameIdSummary[]
      subjectCategories?: NameIdSummary[]
      subjectSpecialCategories?: NameIdSummary[]
      safetyMeasures?: NameIdSummary[]

      processingStages?: RopaDataSourceProcessingStageOption[]
      retentionPeriods?: RopaRetentionTransferPeriodOption[]
      retentionPolicies?: RopaRetentionTransferPeriodOption[]
    }
  }
  reports?: RopaReport[]
  totalReports?: number
  totalActiveReports?: number
  selectedSourcesForReport?: SourcesForRopaReport
  showErrors?: boolean
  collobarateInfo?: StepCollaborateInfo
  collaboration?: RopaCollaboration
  collaborationDetails?: RopaProcessCollaborationScope
  tickets: {
    sort: SortParams
    list?: RopaTicket[]
    total?: number
  }
  fieldToCustomise?: ROPA_FIELD
  refreshCollaboteInfo: boolean
  messageSent?: boolean
  report?: RopaReportPrint
  reportsList: {
    sort: SortParams
    list?: RopaReport[]
    total?: number
  }
  generatedReportId?: string
  reportCustomization: RopaReportCustomization
  processDataSources?: RopaDataSourceDetails[]
}

const initialList = {
  sort: defaultSortParams
}

export const initialState: RopaState = {
  processesGallery: initialList,
  processActivities: {
    sort: defaultSortParams
  },
  processGroups: {
    sort: defaultSortParams,
    list: mockedGroupsList,
    total: mockedGroupsList.length
  },
  processCompanies: {
    sort: defaultSortParams,
    list: mockedCompaniesList,
    total: mockedCompaniesList.length
  },
  processTemplates: {
    sort: defaultSortParams
  },
  tickets: {
    sort: defaultSortParams
  },
  questionnaire: {
    topics: [],
    optionLists: {}
  },
  refreshCollaboteInfo: false,
  reportCustomization: {
    companyName: '',
    companyLogo: {
      fileName: '',
      file: null
    },
    emailBody: '',
    emailRecipients: ''
  },
  reportsList: { sort: defaultSortParams }
}

const ropaSlice = createSlice({
  name: 'ropa',
  initialState,
  reducers: {
    resetRopaRequestConversation: (state) => {
      state.requestConversation = initialState.requestConversation
    },
    resetRopaName: (state) => {
      state.ropaName = initialState.ropaName
    },
    resetProcessOverview: (state) => {
      state.overview = initialState.overview
    },
    resetRopaCollaborations: (state) => {
      state.tickets = initialState.tickets
    },
    resetRopaReports: (state) => {
      state.reportsList = initialState.reportsList
    },
    resetRopaReport: (state) => {
      state.report = initialState.report
    },
    resetRopaFieldOptions: (state) => {
      state.questionnaire.optionLists.purposes = initialState.questionnaire.optionLists.purposes
      state.questionnaire.optionLists.retentionPeriods =
        initialState.questionnaire.optionLists.retentionPeriods
      state.questionnaire.optionLists.retentionPolicies =
        initialState.questionnaire.optionLists.retentionPolicies
      state.questionnaire.optionLists.safetyMeasures =
        initialState.questionnaire.optionLists.safetyMeasures
    },
    setSort: (state, { payload }) => {
      state[payload.list].sort = getSortDirection(state[payload.list].sort, payload.column)
    },
    setSelectedTopic: (state, { payload }) => {
      state.questionnaire.selectedTopic = payload
    },
    deleteSelectedTopic: (state, { payload }) => {
      const { topicId } = payload
      state.questionnaire.topics = state.questionnaire.topics?.map((topic) =>
        topic.topicId == topicId ? { ...topic, _isDeleted: true } : topic
      )
    },
    setSelectedTopicQuestion: (state, { payload }) => {
      state.questionnaire.selectedTopicQuestion = payload
    },
    resetSelectedTopicQuestion: (state) => {
      state.questionnaire.selectedTopicQuestion = initialState.questionnaire.selectedTopicQuestion
    },
    addTopicQuestion: (state, { payload }) => {
      const { topicId, question } = payload
      state.questionnaire.topics = state.questionnaire.topics?.map((topic) => {
        const isUpdated = topic.topicId === topicId
        if (isUpdated) {
          topic.questions ? topic.questions?.push(question) : (topic.questions = [question])
        }
        return isUpdated ? { ...topic, _isUpdated: true } : topic
      })
    },
    updateTopicQuestion: (state, { payload }) => {
      const { topicId, question: updatedQuestion } = payload
      state.questionnaire.topics = state.questionnaire.topics?.map((topic) => {
        const isUpdated = topic.topicId === topicId
        if (isUpdated) {
          topic.questions = topic.questions?.map((question) => {
            return question.questionId === updatedQuestion.questionId ? updatedQuestion : question
          })
        }

        return isUpdated ? { ...topic, _isUpdated: true } : topic
      })
    },
    deleteTopicQuestion: (state, { payload }) => {
      const { topicId, questionId } = payload
      state.questionnaire.topics = state.questionnaire.topics?.map((topic) => {
        const isUpdated = topic.topicId === topicId

        if (isUpdated) {
          topic.questions = topic.questions?.map((question) => {
            return question.questionId === questionId ? { ...question, _isDeleted: true } : question
          })
        }

        return isUpdated ? { ...topic, _isUpdated: true } : topic
      })
    },
    setTopicResponse: (state, { payload }) => {
      const { topicId, questionId, value } = payload

      state.questionnaire.topics = state.questionnaire.topics?.map((topic) => {
        const isUpdated = topic.topicId === topicId

        if (isUpdated) {
          topic.questions?.map((item) => {
            if (item.questionId == questionId) {
              item.value = value
            }
            return value
          })
        }

        return isUpdated ? { ...topic, _isUpdated: true } : topic
      })
    },
    updateTopic: (state, { payload }) => {
      state.questionnaire.topics = state.questionnaire.topics.map((topic) => {
        return topic.topicId === payload.topicId ? { ...payload, _isUpdated: true } : topic
      })
    },
    updateTopics: (state, { payload }) => {
      state.questionnaire.topics = payload
    },
    setSelectedProcess: (state, { payload }) => {
      state.selectedProcess = payload
    },
    resetSelectedProcess: (state) => {
      state.selectedProcess = initialState.selectedProcess
      state.questionnaire = initialState.questionnaire
    },
    resetListProcesses: (state) => {
      state.processesGallery = initialState.processesGallery
    },
    resetListTemplates: (state) => {
      state.processTemplates = initialState.processTemplates
    },
    setSelectedSourcesForReport: (state, { payload }) => {
      state.selectedSourcesForReport = payload
    },
    resetSelectedSourcesForReport: (state) => {
      state.selectedSourcesForReport = initialState.selectedSourcesForReport
    },
    setShowErrors: (state, { payload }) => {
      state.showErrors = payload
    },
    resetSendMessage: (state) => {
      state.messageSent = initialState.messageSent
    },
    resetRevokeAccess: (state) => {
      state.isAccessRevoked = initialState.isAccessRevoked
    },
    resetRequestRejected: (state) => {
      state.isRequestRejected = initialState.isRequestRejected
    },
    resetRequestRemoved: (state) => {
      state.isRequestRemoved = initialState.isRequestRemoved
    },
    setRopaFieldToCustomise: (state, { payload }) => {
      state.fieldToCustomise = payload
    },
    setRopaCollaboration: (state, { payload }) => {
      state.collaboration = payload
    },
    resetRopaCollaboration: (state) => {
      state.collaboration = initialState.collaboration
    },
    resetRopaCollaborationDetails: (state) => {
      state.collaborationDetails = initialState.collaborationDetails
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRopaRequestConversation.fulfilled, (state, { payload }) => {
      state.requestConversation = payload
    })
    builder.addCase(fetchRopaNameById.fulfilled, (state, { payload }) => {
      state.ropaName = payload
    })
    builder.addCase(revokeAccess.fulfilled, (state) => {
      state.isAccessRevoked = true
    })
    builder.addCase(rejectRopaRequest.fulfilled, (state) => {
      state.isRequestRejected = true
    })
    builder.addCase(removeRopaRequest.fulfilled, (state) => {
      state.isRequestRemoved = true
      state.refreshCollaboteInfo = true
    })
    builder.addCase(fetchListCompanyRopa.fulfilled, (state, { payload }) => {
      state.processCompanies.list = payload.list
      state.processCompanies.total = payload.total
    })
    builder.addCase(fetchRopaDataSourceResponse.fulfilled, (state, { payload }) => {
      state.requestInfo = payload
      state.questionnaire.topics = mapRopaQuestionnaireTopicsToUI(payload.questionnaire, true)
    })
    builder.addCase(fetchListRopaRequests.fulfilled, (state, { payload }) => {
      state.requests = payload.list
      state.totalRequests = payload.total
    })
    builder.addCase(generateRopaReport.fulfilled, (state, { payload }) => {
      state.report = payload
    })
    builder.addCase(fetchRopaDatasourceRequests.fulfilled, (state, { payload }) => {
      state.datasourceRequests = payload.list
      state.totalDatasourceRequests = payload.total
      state.datasourceRequestInfo = { ropaName: payload.ropa, processName: payload.process }
    })
    builder.addCase(fetchRopaCollaborations.fulfilled, (state, { payload }) => {
      state.tickets.list = payload.list
      state.tickets.total = payload.total
    })
    builder.addCase(fetchRopaAttributes.fulfilled, (state, { payload }) => {
      state.questionnaire.optionLists.attributeSets = payload.attributeSets
      state.questionnaire.optionLists.attributes = payload.attributes
    })
    builder.addCase(fetchRopaClassifications.fulfilled, (state, { payload }) => {
      state.questionnaire.optionLists.classifications = payload
    })
    builder.addCase(fetchRopaReportClassifications.fulfilled, (state, { payload }) => {
      state.questionnaire.optionLists.reportClassifications = payload
    })
    builder.addCase(fetchRopaEntityTypes.fulfilled, (state, { payload }) => {
      state.questionnaire.optionLists.entityTypes = payload
    })
    builder.addCase(fetchPurposes.fulfilled, (state, { payload }) => {
      state.questionnaire.optionLists.purposes = payload
    })
    builder.addCase(fetchLawfulBasis.fulfilled, (state, { payload }) => {
      state.questionnaire.optionLists.lawfulBasis = payload
    })
    builder.addCase(fetchSubjectCategories.fulfilled, (state, { payload }) => {
      state.questionnaire.optionLists.subjectCategories = payload
    })
    builder.addCase(fetchSpecialSubjectCategories.fulfilled, (state, { payload }) => {
      state.questionnaire.optionLists.subjectSpecialCategories = payload
    })
    builder.addCase(fetchRetentionPolicies.fulfilled, (state, { payload }) => {
      state.questionnaire.optionLists.retentionPolicies = payload.retentionPolicies
    })
    builder.addCase(fetchRopaSafetyMeasures.fulfilled, (state, { payload }) => {
      state.questionnaire.optionLists.safetyMeasures = payload
    })
    builder.addCase(fetchProcessingStages.fulfilled, (state, { payload }) => {
      state.questionnaire.optionLists.processingStages = payload
    })
    builder.addCase(createRopaProcess.fulfilled, (state, { payload }) => {
      state.processActivities.list = [...(state.processActivities.list || []), payload]
    })
    builder.addCase(createRopaTemplate.fulfilled, (state, { payload }) => {
      state.processesGallery.list = [...(state.processesGallery.list || []), payload]
    })
    builder.addCase(fetchRopaReports.fulfilled, (state, { payload }) => {
      state.reports = payload.list
      state.totalReports = payload.totalReports
      state.totalActiveReports = payload.totalActiveReports
    })
    builder.addCase(fetchRopaCollaborateInfo.fulfilled, (state, { payload }) => {
      state.collobarateInfo = payload
    })
    builder.addCase(sendRopaRequest.fulfilled, (state) => {
      state.refreshCollaboteInfo = true
    })
    builder.addCase(sendRopaMessage.fulfilled, (state) => {
      state.messageSent = true
    })
    builder.addCase(fetchRopaReportById.fulfilled, (state, { payload }) => {
      state.report = payload
    })
    builder.addCase(fetchRopaProcessReports.fulfilled, (state, { payload }) => {
      state.reportsList.list = payload.list
      state.reportsList.total = payload.total
    })
    builder.addCase(fetchProcessDataSources.fulfilled, (state, { payload }) => {
      state.processDataSources = payload
    })
    builder.addCase(fetchProcessOverview.fulfilled, (state, { payload }) => {
      state.overview = payload
    })
    builder.addCase(fetchCollaborationDetails.fulfilled, (state, { payload }) => {
      state.collaborationDetails = payload
    })
  }
})

export const {
  resetRopaRequestConversation,
  resetProcessOverview,
  setSort,
  setSelectedTopic,
  deleteSelectedTopic,
  setSelectedTopicQuestion,
  setTopicResponse,
  setRopaFieldToCustomise,
  setRopaCollaboration,
  resetSelectedTopicQuestion,
  resetRopaCollaborations,
  resetRopaCollaboration,
  addTopicQuestion,
  updateTopicQuestion,
  deleteTopicQuestion,
  resetRopaCollaborationDetails,
  updateTopic,
  updateTopics,
  setSelectedProcess,
  resetSelectedProcess,
  setSelectedSourcesForReport,
  resetSelectedSourcesForReport,
  setShowErrors,
  resetSendMessage,
  resetRopaReport,
  resetListProcesses,
  resetListTemplates,
  resetRopaFieldOptions,
  resetRopaName,
  resetRopaReports,
  resetRevokeAccess,
  resetRequestRejected,
  resetRequestRemoved
} = ropaSlice.actions

export default ropaSlice.reducer
