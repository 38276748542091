import requestReducer from './reducers/requestReducer'
import contextReducer from './reducers/contextReducer'
import globalReducer from './reducers/globalReducer'
import breadcrumbsReducer from './features/breadcrumbs/breadcrumbsSlice'
import modalsReducer from './features/modals/modalsSlice'
import filtersReducer from './features/filters/filtersSlice'
import loginReducer from './features/login/loginSlice'
import documentReducer from './features/annotator/documentSlice'
import documentTimeTravelReducer from './features/annotator/documentTimeTravelSlice'
import dataSourcesReducer from './features/dataSources/dataSourcesSlice'
import dashboardReducer from './features/dashboards/mainDashboard/mainDashboardSlice'
import globalSearchReducerReducer from './features/globalSearch/globalSearchSlice'
import entitiesReducer from './features/entities/entitiesSlice'
import notificationReducer from './features/notification/notificationSlice'
import notificationsReducer from './features/notifications/notificationsSlice'
import googleDriveDashboardReducer from './features/dashboards/googleDrive/googleDriveDashboardSlice'
import residenciesReducer from './features/residencies/residenciesSlice'
import locationsReducer from './features/locations/locationsSlice'
import authProviderReducer from './features/authProvider/authProviderSlice'
import classificationsReducer from './features/classifications/classificationsSlice'
import hybridWidgetSensitiveInfoReducer from './features/hybridWidgetSensitiveInfo/hybridWidgetSensitiveInfoSlice'
import connectedAppsReducer from './features/connectedApps/connectedAppsSlice'
import GDriveAlertsReducer from './features/alerts/alertsSlice'
import GDriveAnomaliesReducer from './features/anomalies/anomaliesSlice'
import GDrivePoliciesReducer from './features/policies/policiesSlice'
import PermitListReducer from './features/permitList/permitListSlice'
import GDriveSentimentAnalysisReducer from './features/sentimentAnalysis/sentimentAnalysisSlice'
import GDriveRulesReducer from './features/rules/rulesSlice'
import SummaryReducer from './features/summary/summarySlice'
import GDriveDrivesReducer from './features/drives/drivesSlice'
import GDriveUsersReducer from './features/users/usersSlice'
import GDriveGroupsReducer from './features/groups/groupsSlice'
import SlackWorkspaceReducer from './features/dashboards/slack/components/workspace/workspaceSlice'
import SlackMembersReducer from './features/dashboards/slack/components/membersLeakingData/membersSlice'
import DashboardSummaryCountsReducer from './features/dashboardSummary/dashboardSummarySlice'
import StatusReducer from './features/dataSources/components/StatusWidget/statusSlice'
import SlackMessagesReducer from './features/dashboards/slack/components/messagesLeakingData/messagesSlice'
import ViolationsReducer from './features/violations/violationsSlice'
import templatesReducer from './features/templates/templatesSlice'
import EmailReducer from './features/dashboards/gmail/components/EmailsList/emailsListSlice'
import MailsReducer from './features/dashboards/template2/components/EmailsList/emailsListSlice'
import SyncInfoReducer from './features/syncInfo/syncInfoSlice'
import ElasticSearchSettingsReducer from './features/dataSources/components/UnstructuredForm/ElasticSeach/elasticSearchSettingsSlice'
import DatabasesReducer from './features/databases/databasesSlice'
import SchemasReducer from './features/schemas/schemasSlice'
import TablesReducer from './features/tables/tablesSlice'
import ColumnsReducer from './features/columns/columnsSlice'
import DataSourceMixedReducer from './features/dataSourcesMixed/slice'
import TableRelationshipsReducer from './features/tableRelationships/tableRelationshipsSlice'
import AttributesReducer from './features/attributes/attributesSlice'
import DSRRequestListReducer from './features/dsr/requestList/dsrRequestSlice'
import DSRRequestDetailsReducer from './features/dsr/requestDetails/requestDetailsSlice'
import DSRDataDiscoveryDataSourcesReducer from './features/dsr/requestDetails/components/dataDiscovery/dataSources/dataSourcesSlice'
import DSRDataDiscoveryAttributeListReducer from './features/dsr/requestDetails/components/attributeList/attributeListSlice'
import DSRSettingsReducer from './features/dsr/dsrSettings/dsrSettingsSlice'
import BucketsReducer from './features/buckets/bucketsSlice'
import FoldersReducer from './features/folders/foldersSlice'
import ProjectsReducer from './features/projects/projectsSlice'
import ApiRequestsReducer from './features/apiRequests/apiRequestsSlice'
import ScannedDataReducer from './features/scannedData/scannedDataSlice'
import MembersSlice from './features/members/membersSlice'
import PartnersSlice from './features/privacyPartners/partnersSlice'
import TicketsSlice from './features/tickets/ticketsSlice'
import ReportersSlice from './features/reporters/reportersSlice'
import DatabasesAndPoliciesReducer from './features/databasesAndPolicies/databasesAndPoliciesSlice'
import ObjectTypeAndPoliciesReducer from './features/ObjectTypeAndPolicies/objectTypeAndPoliciesSlice'
import ScanStatusReducer from './features/scanStatus/scanStatusSlice'
import ClustersReducer from './features/clusters/clustersSlice'
import WhatsNewReducer from './features/whatsNew/whatsNewSlice'
import SmtpConfigReducer from './features/ConfigureSmtp/smtpSlice'
import ReportConfigReducer from './features/ConfigureReport/reportSlice'
import NoScanListReducer from './features/noScanList/noScanSlice'
import DsrFormsReducer from './features/dsr/dsrForms/dsrFormsSlice'
import BlobListReducer from './features/blobs/blobSlice'
import RopaReducer from './features/ropa/ropaSlice'
import RopaV2Reducer from './features/ropa/ropaSliceV2'
import PiaReducer from './features/pia/piaSlice'
import timeMachineReducer from './features/timeMachine/timeMachineSlice'
import DsrTicketsReducer from './features/dsr/tickets/dsrTicketsSlice'
import FileSharesReducer from './features/fileShares/fileSharesSlice'
import PrintReducer from './print/printSlice'
import languageReducer from './features/manageAccount/languageSlice'
import DsrEmailTemplatesReducer from './features/dsr/dsrEmailTemplates/dsrEmailTemplatesSlice'
import CookieConsentReduer from './features/cookieConsent/cookieConsentSlice'
import BannersReducer from './features/cookieConsent/banners/bannersSlice'
import DomainsReducer from './features/cookieConsent/domains/domainsSlice'
import SettingsReducer from './features/cookieConsent/settings/settingsSlice'
import PreferenceCenterReducer from './features/consentManagement/preference-center/preferenceCenterSlice'
import DocumentLabelsReducer from './features/documentLabels/documentLabelsSlice'
import PrivacyOpsWidgetsReducer from './features/dashboards/mainDashboardv2/widgets/privacyOpsWidgets/privacyOpsWidgetsSlice'
import ConsentManagementReducer from './features/consentManagement/consentManagementSlice'
import PrivacyOpsReportsReducer from './features/privacyOpsReports/privacyOpsReportsSlice'

import FeatureFlagReducer from './configs/featureFlagSlice'
import DocAnalyticsReducer from './features/docAnalytics/docAnalyticsSlice'
import AboutReducer from './features/about/aboutSlice'
import AccessControlReducer from './features/accessControl/accessControlSlice'
import CloudPlatformReducer from './features/cloudPlatforms/cloudPlatformSlice'
import DsrReportReducer from './features/dsr/dsrReports/dsrReportsSlice'
import { combineReducers } from '@reduxjs/toolkit'
import undoable from 'redux-undo'

export const rootReducer = combineReducers({
  request: requestReducer,
  context: contextReducer,
  breadcrumbs: breadcrumbsReducer,
  modals: modalsReducer,
  filters: filtersReducer,
  user: loginReducer,
  document: documentReducer,
  documentTimeTravel: undoable(documentTimeTravelReducer),
  dataSources: dataSourcesReducer,
  dashboard: dashboardReducer,
  templates: templatesReducer,
  globalSearch: globalSearchReducerReducer,
  global: globalReducer,
  entities: entitiesReducer,
  notification: notificationReducer,
  notifications: notificationsReducer,
  googleDriveDashboard: googleDriveDashboardReducer,
  authProvider: authProviderReducer,
  residencies: residenciesReducer,
  locations: locationsReducer,
  classifications: classificationsReducer,
  widgetSensitiveInfo: hybridWidgetSensitiveInfoReducer,
  connectedApps: connectedAppsReducer,
  summary: SummaryReducer,
  alerts: GDriveAlertsReducer,
  anomalies: GDriveAnomaliesReducer,
  violations: ViolationsReducer,
  policies: GDrivePoliciesReducer,
  permitList: PermitListReducer,
  rules: GDriveRulesReducer,
  sentimentAnalysis: GDriveSentimentAnalysisReducer,
  drives: GDriveDrivesReducer,
  users: GDriveUsersReducer,
  groups: GDriveGroupsReducer,
  SlackWorkspace: SlackWorkspaceReducer,
  slackMembers: SlackMembersReducer,
  dashboardSummaryCounts: DashboardSummaryCountsReducer,
  datasourceStatus: StatusReducer,
  slackMessages: SlackMessagesReducer,
  emails: EmailReducer,
  mails: MailsReducer,
  syncInfo: SyncInfoReducer,
  elasticSearchSettings: ElasticSearchSettingsReducer,
  databases: DatabasesReducer,
  schemas: SchemasReducer,
  tables: TablesReducer,
  columns: ColumnsReducer,
  dataSourceStructured: DataSourceMixedReducer,
  tableRelationships: TableRelationshipsReducer,
  attributes: AttributesReducer,
  dsrRequestList: DSRRequestListReducer,
  dsrRequestDetails: DSRRequestDetailsReducer,
  dsrDataDiscoveryAttributeList: DSRDataDiscoveryAttributeListReducer,
  dsrSettings: DSRSettingsReducer,
  dsrDataDiscoveryDataSources: DSRDataDiscoveryDataSourcesReducer,
  projects: ProjectsReducer,
  apiRequests: ApiRequestsReducer,
  scannedData: ScannedDataReducer,
  members: MembersSlice,
  databasesAndPolicies: DatabasesAndPoliciesReducer,
  objectTypeAndPolicies: ObjectTypeAndPoliciesReducer,
  scanStatus: ScanStatusReducer,
  clusters: ClustersReducer,
  partners: PartnersSlice,
  tickets: TicketsSlice,
  smtpConfiguration: SmtpConfigReducer,
  reportConfiguration: ReportConfigReducer,
  reporters: ReportersSlice,
  whatsNew: WhatsNewReducer,
  buckets: BucketsReducer,
  folders: FoldersReducer,
  noScanList: NoScanListReducer,
  blobs: BlobListReducer,
  dsrForms: DsrFormsReducer,
  ropa: RopaReducer,
  ropav2: RopaV2Reducer,
  pia: PiaReducer,
  timeMachine: timeMachineReducer,
  dsrTickets: DsrTicketsReducer,
  dsrEmailTemplates: DsrEmailTemplatesReducer,
  fileShares: FileSharesReducer,
  cookieConsent: CookieConsentReduer,
  banners: BannersReducer,
  domains: DomainsReducer,
  cookieConsentSettings: SettingsReducer,
  documentLabels: DocumentLabelsReducer,
  privacyOpsWidgets: PrivacyOpsWidgetsReducer,
  consentManagement: ConsentManagementReducer,
  privacyOpsReports: PrivacyOpsReportsReducer,
  print: PrintReducer,
  lang: languageReducer,
  featureFlags: FeatureFlagReducer,
  about: AboutReducer,
  docAnalytics: DocAnalyticsReducer,
  accessControl: AccessControlReducer,
  cloudPlatform: CloudPlatformReducer,
  preferenceCenter: PreferenceCenterReducer,
  dsrReports: DsrReportReducer
})

export type RootState = ReturnType<typeof rootReducer>
