import { randHex } from './mathUtils'
import { transformDateStringToTimestamp } from '../utils/timeUtil'
import {
  DOC_TYPE_IMAGE,
  DOC_TYPE_PDF,
  DOC_TYPE_UNDEFINED,
  DOC_TYPE_XLS,
  DOC_TYPE_DOCX,
  DOC_TYPE_XLSX,
  DOC_TYPE_CSV,
  DOC_TYPE_DOC,
  DOC_TYPE_LOG,
  RENTENTION_OPTIONS
} from '../constants'
import { BoundingBoxTag, Document } from '../services/api/apiTypes'
import {
  BoundingBoxUI,
  IBoundingBoxCoordinates,
  IScaleCoefficients,
  UnlinkedAttributeUI
} from '../interfaces'
import { INotificationParams } from '../features/notification/notificationSlice'
import * as XLSX from 'xlsx'
import Papa from 'papaparse'
import dayjs from 'dayjs'

export const mapBoundingBoxesForApi = (boundingBoxes: BoundingBoxUI[]): BoundingBoxTag[] =>
  boundingBoxes.map((box) => ({
    context: box.context,
    confidence: box.confidence,
    identifierId: box.identifierId,
    identifierName: box.identifierName,
    value: box.value,
    ...box.coordinates
  }))

export const mapBoundingBoxesForUI = (document: Document): BoundingBoxUI[] => {
  return (document?.boundingBoxTags || [])?.map((tag) => {
    const {
      leftUpperX1 = 0,
      leftUpperY1 = 0,
      rightLowerX2 = 0,
      rightLowerY2 = 0,
      pageNumber = 0,
      confidence = 1,
      identifierId,
      identifierName = '',
      attributeInstanceId = '',
      value,
      context = ''
    } = tag
    return {
      documentId: document.id,
      localId: randHex(5),
      identifierId: identifierId.toString(),
      identifierName,
      attributeInstanceId,
      coordinates: {
        leftUpperX1,
        leftUpperY1,
        rightLowerX2,
        rightLowerY2,
        pageNumber
      },
      value,
      confidence,
      isMasked: true,
      context
    }
  })
}

export const mapUnlinkedAttributesForUI = (
  document: Document
): UnlinkedAttributeUI[] | undefined => {
  return document.unlinkedAttributes?.map((unlinkedAttribute) => {
    return {
      ...unlinkedAttribute,
      documentId: document.id,
      localId: randHex(5),
      isMasked: true,
      coordinates:
        typeof unlinkedAttribute.leftUpperX1 === 'number'
          ? {
              pageNumber: unlinkedAttribute.pageNumber as number,
              leftUpperX1: unlinkedAttribute.leftUpperX1 as number,
              leftUpperY1: unlinkedAttribute.leftUpperY1 as number,
              rightLowerX2: unlinkedAttribute.rightLowerX2 as number,
              rightLowerY2: unlinkedAttribute.rightLowerY2 as number
            }
          : undefined
    }
  })
}

export const getDocumentType = (ext: string | undefined): string => {
  if (ext === 'jpg' || ext === 'jpeg' || ext === 'png') {
    return DOC_TYPE_IMAGE
  }

  if (ext === 'pdf') return DOC_TYPE_PDF
  if (ext === 'csv') return DOC_TYPE_XLS
  if (ext === 'xls') return DOC_TYPE_XLS
  if (ext === 'xlsx') return DOC_TYPE_XLSX
  if (ext === 'doc') return DOC_TYPE_DOC
  if (ext === 'docx') return DOC_TYPE_DOCX
  if (ext === 'log') return DOC_TYPE_LOG

  return DOC_TYPE_UNDEFINED
}

export const getDocumentTypeFromMime = (mime = ''): string => {
  const image = ['image/png', 'image/jpg', 'image/jpeg']
  const pdf = 'application/pdf'
  const csv = 'text/csv'
  const xls = 'application/vnd.ms-excel'
  const xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  const doc = 'application/msword'
  const docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'

  if (image.includes(mime)) return DOC_TYPE_IMAGE
  if (mime === pdf) return DOC_TYPE_PDF
  if (mime === csv) return DOC_TYPE_CSV
  if (mime === xls) return DOC_TYPE_XLS
  if (mime === xlsx) return DOC_TYPE_XLSX
  if (mime === doc) return DOC_TYPE_DOC
  if (mime === docx) return DOC_TYPE_DOCX

  return DOC_TYPE_UNDEFINED
}

export const getDocumentListWithTimestamps = (documentsList: Array<Document>): Array<Document> => {
  return documentsList.map((data) => {
    const transformedAddedAt = data.created_at
      ? transformDateStringToTimestamp(data.created_at)
      : ''

    const transformedLastModified = data.lastModifiedTime
      ? transformDateStringToTimestamp(data.lastModifiedTime)
      : ''

    return {
      ...data,
      created_at: transformedAddedAt,
      lastModifiedTime: transformedLastModified
    }
  })
}

export const findOverlappingBoundingBoxes = (
  coordinates: IBoundingBoxCoordinates,
  boundingBoxes: BoundingBoxUI[]
): BoundingBoxUI[] => {
  const a = {
    x1: coordinates.leftUpperX1,
    y1: coordinates.leftUpperY1,
    x2: coordinates.rightLowerX2,
    y2: coordinates.rightLowerY2
  }

  return boundingBoxes.filter((box) => {
    const b = {
      x1: box.coordinates?.leftUpperX1 || 0,
      y1: box.coordinates?.leftUpperY1 || 0,
      x2: box.coordinates?.rightLowerX2 || 0,
      y2: box.coordinates?.rightLowerY2 || 0
    }

    // no horizontal overlap
    if (a.x1 >= b.x2 || b.x1 >= a.x2) return false

    // no vertical overlap
    if (a.y1 >= b.y2 || b.y1 >= a.y2) return false

    return true
  })
}

export const isCoordinatesIdentical = (
  a?: IBoundingBoxCoordinates,
  b?: IBoundingBoxCoordinates
): boolean => {
  return (
    a?.leftUpperX1 === b?.leftUpperX1 &&
    a?.leftUpperY1 === b?.leftUpperY1 &&
    a?.rightLowerX2 === b?.rightLowerX2 &&
    a?.rightLowerY2 === b?.rightLowerY2
  )
}

export const getScaledCoordinates = (
  coordinates: IBoundingBoxCoordinates,
  scaleCoefficients: IScaleCoefficients
): { left: number; top: number; width: number; height: number } => {
  const initLeft = Math.min(coordinates.leftUpperX1, coordinates.rightLowerX2)
  const initWidth = Math.abs(coordinates.leftUpperX1 - coordinates.rightLowerX2)
  const initHeight = Math.abs(coordinates.leftUpperY1 - coordinates.rightLowerY2)
  const initTop = Math.min(coordinates.leftUpperY1, coordinates.rightLowerY2)

  const { width: widthScale, height: heightScale } = scaleCoefficients

  const left = Math.round(initLeft * widthScale)
  const width = Math.round(initWidth * widthScale)
  const height = Math.round(initHeight * heightScale)
  const top = Math.round(initTop * heightScale)

  return { left, top, width, height }
}

export const isFileValidForUpload = (file: File): boolean => {
  const validFileFormats = ['application/pdf', 'image/png', 'image/jpg', 'image/jpeg', 'image/webp']

  return validFileFormats.includes(file.type)
}

export const transformFileToDataUrl = (
  rawFile: File | Blob
): Promise<string | ArrayBuffer | null> => {
  return new Promise((res, rej) => {
    const reader = new FileReader()
    reader.readAsDataURL(rawFile)
    reader.onloadend = () => res(reader.result)
    reader.onerror = rej
  })
}

export const openBlobInNewTab = (blob: Blob, fileName?: string): void => {
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.style.display = 'none'
  link.target = '_blank'
  link.href = url

  if (fileName) {
    link.download = fileName
  }

  document.body.appendChild(link)
  link.click()
  window.URL.revokeObjectURL(url)
}
// remove file extension from file name
export function getFileName(filename: string): string {
  const lastDotIndex = filename.lastIndexOf('.')
  if (lastDotIndex === -1) {
    return filename
  }
  return filename.substring(0, lastDotIndex)
}

export const downloadBlobFile = (blob: Blob, fileName: string): void => {
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  window.URL.revokeObjectURL(url)
}

export const transformFileToBinary = (rawFile: File | Blob): Promise<string | ArrayBuffer> => {
  return new Promise((res) => {
    const reader = new FileReader()

    reader.onloadend = (e) => {
      if (e?.target?.result) {
        return res(e.target.result)
      }
    }

    reader.readAsBinaryString(rawFile)
  })
}

export const transformFileToArrayBuffer = (rawFile: File | Blob): Promise<string | ArrayBuffer> => {
  return new Promise((res) => {
    const reader = new FileReader()

    reader.onloadend = (e) => {
      if (e?.target?.result) {
        return res(e.target.result)
      }
    }

    reader.readAsArrayBuffer(rawFile)
  })
}

export const convertDataURIToArray = (dataURI: string): Uint8Array => {
  const BASE64_MARKER = ';base64,'
  const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length
  const base64 = dataURI.substring(base64Index)
  const raw = window.atob(base64)
  const rawLength = raw.length
  const array = new Uint8Array(new ArrayBuffer(rawLength))

  // for (let i = 0; i < rawLength; i++) {
  //   array[i] = raw.charCodeAt(i)
  // }
  return array
}

export const getTimestampRangeFromRetentionPeriod = (retainUntil: RENTENTION_OPTIONS) => {
  const currentTimestamp = dayjs.utc().unix()
  const timestampDayStart = dayjs().subtract(1, 'day').unix()
  const timestampWeekStart = dayjs().subtract(1, 'week').unix()
  const timestampMonthStart = dayjs().subtract(1, 'month').unix()

  const timestampRangeMapper = {
    [RENTENTION_OPTIONS.day]: {
      startTimestamp: timestampDayStart,
      endTimestamp: currentTimestamp
    },
    [RENTENTION_OPTIONS.weekly]: {
      startTimestamp: timestampWeekStart,
      endTimestamp: currentTimestamp
    },
    [RENTENTION_OPTIONS.monthly]: {
      startTimestamp: timestampMonthStart,
      endTimestamp: currentTimestamp
    }
  }
  return timestampRangeMapper[retainUntil]
}

export const downloadContentAsCSV = (fileContent: {
  data: string
  fileName: string
  customFileName?: string
}) => {
  const { data, fileName, customFileName = '' } = fileContent

  const fileType = fileName.split('.').pop() // get file extension
  // Parse & unparse csv data to cleanup any special characters & prevent csv parsing errors
  const parsed = Papa.parse(data)
  const csvContent = Papa.unparse(parsed.data)

  const content = new Blob([csvContent], { type: `text/${fileType};charset=utf-8` })
  const encodedUri =
    fileType === 'csv'
      ? window.URL.createObjectURL(content)
      : 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(data))
  const link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', customFileName || fileName)
  document.body.appendChild(link) // Required for FF
  link.click()
  link.parentNode?.removeChild(link)
}

export const isCreateDocumentTemplateSupported = (type?: string): boolean => {
  const typeConverted = getDocumentType(type)
  if (
    typeConverted === DOC_TYPE_IMAGE ||
    typeConverted === DOC_TYPE_PDF ||
    typeConverted === DOC_TYPE_DOC ||
    typeConverted === DOC_TYPE_DOCX
  ) {
    return true
  }

  return false
}

export const convertFileToText = async (file: File): Promise<string> => {
  const fileType = getDocumentTypeFromMime(file.type)

  return new Promise((res, rej) => {
    const reader = new FileReader()

    if (fileType === DOC_TYPE_XLSX) {
      reader.readAsBinaryString(file)
    } else {
      reader.readAsText(file)
    }

    reader.onload = (event) => {
      if (fileType === DOC_TYPE_XLSX) {
        const workbook = XLSX.read(event?.target?.result, {
          type: 'binary',
          sheetRows: 10000 // max sheets to prevent page crash
        })
        const wsname = workbook.SheetNames[0]
        const ws = workbook.Sheets[wsname]
        return res(XLSX.utils.sheet_to_csv(ws))
      } else {
        const result = reader.result as string
        return res(result)
      }
    }
    reader.onerror = (error) => rej(error)
  })
}

export type UploadFileTypes =
  | 'image'
  | 'pdf'
  | 'text'
  | 'csv'
  | 'xls'
  | 'xlsx'
  | 'doc'
  | 'docx'
  | 'log'
  | 'undefined'

export interface UploadFileProps {
  files: FileList | null
  allowedTypes: UploadFileTypes[]
  isMultipleAllowed?: boolean
  maxFileSizeMb?: number // in mb
  addNotification: (notification: INotificationParams) => void
}

export const isUploadedFilesValid = (props: UploadFileProps): boolean => {
  const {
    files,
    allowedTypes = [],
    isMultipleAllowed = false,
    maxFileSizeMb = 5,
    addNotification
  } = props

  // validate multiple upload allowed
  if (!files || (!isMultipleAllowed && files.length > 1)) {
    addNotification({ error: 'uploader.error.multipleFiles', success: '' })
    return false
  }

  for (let i = 0; i < files.length; i++) {
    // validate uploaded files have allowed file extensions
    if (!allowedTypes.includes(getDocumentTypeFromMime(files[i]?.type) as UploadFileTypes)) {
      addNotification({ error: 'uploader.error.fileNotValid', success: '' })
      return false
    }

    // validate uploaded files are not bigger then max allowed file sizes
    const fileMb = files[i].size / 1024 ** 2
    if (fileMb >= maxFileSizeMb) {
      addNotification({ error: 'uploader.error.tooBig', success: '' })
      return false
    }
  }

  return true
}
